import React, { useEffect, useRef, useState } from "react";
import * as Fa from "react-icons/fa";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dropdown } from "primereact/dropdown";
import { EndpointCreditMReports, createAPIEndpointCreditMReports } from "../../../Services/ReportsAll/CreditManagementReports";
import storage from "../../../Services/Storage";
import { format, subDays } from "date-fns";
import XLSX from "xlsx";
import { toast } from "react-toastify";
import { PDFExport } from "@progress/kendo-react-pdf";

const CustomerCreditNote = () => {
    const currentDate = format(new Date(), "yyyy-MM-dd");
    const date = subDays(new Date(), 7);
    const sevenDays = format(date, "yyyy-MM-dd");
    const [fromDate, setFromDate] = useState(sevenDays);
    const [toDate, setToDate] = useState(currentDate);
    const options = ["All","Select Customers"];
    const [custType,setCustType] = useState("All");
    const [custShow,setCustShow] = useState(false);
    const [loadingExcel,setLoadingExcel] = useState(false);
    const [isDataFetched, setIsDataFetched] = useState(false);
    const [loadingPdf,setLoadingPdf] = useState(false);
    const [customers,setCustomers] = useState([]);
    const [selectedCustomer,setSelectedCustomer] = useState([]);
    const [printData,setPrintData] = useState([]);

    const auth = storage.getLocal("user");

    const printRef = useRef(null);
    const pdfExportComponent = useRef(null);

    const handleCustType = (e)=>{
        setCustType(e.target.value);
        if(e.target.value=="Select Customers"){
            setCustShow(true);
        }
        else{
            setCustShow(false);
        }
    };

    const handleExcelDownload=(fromDate,toDate)=>{
        setLoadingExcel(true);
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");
        const maskPartyIds = selectedCustomer.map(customer => customer.MASK_PARTY_ID);
        const resultString = maskPartyIds.join(",");
        let date = new Date();
        let day = date.getDate();
        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        let exactmonth = months[date.getMonth()];
        let exactDay = (day).toString().padStart(2, "0");
        let year = date.getFullYear();
        let uniqueNo = `Customer_Credit_Note${exactDay}${exactmonth}${year}`;

        createAPIEndpointCreditMReports()
            .get(`${EndpointCreditMReports.GetCustExcelData}?sFromDate=${fromDateRange}&sToDate=${toDateRange}&strSuppCode=${custShow ? resultString : "All"}&CompId=${auth.CompId}`)
            .then((val)=>{
                if(val.data.length>0){
                    let wb = XLSX.utils.book_new();
                    let MapData = val.data.map((val) => {
                        function transformDate(dateString) {
                            if (!dateString) return "";
                            let updatedData = dateString.replace(/(\d+[/])(\d+[/])/, "$2$1");
                            return new Date(updatedData);
                        }
                        let scheme_Start_Date = transformDate(val["Scheme Start Date"]);
                        let scheme_End_Date = transformDate(val["Scheme End Date"]);
                        let credit_Note_Date = transformDate(val["Credit Note Date"]);
                        return { ...val, "Scheme Start Date":scheme_Start_Date, "Credit Note Date":credit_Note_Date, "Scheme End Date":scheme_End_Date };
                    });
                    let ws = XLSX.utils.json_to_sheet(MapData);
                    XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
                    XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
                }
                else{
                    toast.warning(
                        "No Data Found between these dates",
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                }
                setLoadingExcel(false);
                setSelectedCustomer([]);
            })
            .catch((error) => {
                setSelectedCustomer([]);
                setLoadingExcel(false);
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,    
                });

                console.log(error);
            });
    };

    const fetchDataAndDownloadPdf = async (fromDate,toDate) =>{
        // debugger
        setLoadingPdf(true);
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");
        const maskPartyIds = selectedCustomer.map(customer => customer.MASK_PARTY_ID);
        const resultString = maskPartyIds.join(",");
        await createAPIEndpointCreditMReports()
            .get(`${EndpointCreditMReports.GetCustExcelData}?sFromDate=${fromDateRange}&sToDate=${toDateRange}&strFinYearStart=${fromDateRange}&strFinYearEnd=${toDateRange}&strSuppCode=${custShow ? resultString : "All"}&CompId=${auth.CompId}`)
            .then((val)=>{
                if(val.data.length>0){
                    setPrintData(val?.data);
                    setIsDataFetched(true);
                    setLoadingPdf(false);
                }
                else{
                    toast.warning(
                        "No Data Found between these dates",
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                }
                setLoadingPdf(false);
                setSelectedCustomer([]);
            })
            .catch((error) => {
                setSelectedCustomer([]);
                setLoadingPdf(false);
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,    
                });

                console.log(error);
            });
            
    };
    // console.log("hjilo",loadingPdf);
    // console.log("hjilo",printData);

    useEffect(()=>{
        
        createAPIEndpointCreditMReports()
            .get(`${EndpointCreditMReports.GetParty}?CompId=${auth.CompId}`)
            .then((res)=>{
                setCustomers(res?.data);
            });
    },[]);

    useEffect(() => {
        if (isDataFetched && pdfExportComponent.current) {
            pdfExportComponent.current.save();
            setIsDataFetched(false);  
        }
    }, [isDataFetched]);

    return (
        <div className="col-12 pt-3">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3 className="py-2">Customer Credit Note</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="float-end mb-3">
                        <button type="button" className="btn btn-outline-primary me-3" onClick={()=>handleExcelDownload(fromDate,toDate)}>
                            {loadingExcel ? "Loading..." : "Download Excel"}{" "}
                            {loadingExcel ? (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <Fa.FaDownload className="ms-2" />
                            )}
                        </button>
                        <button type="button" className="btn btn-primary" ref={printRef}
                            onClick={() => {
                                fetchDataAndDownloadPdf(fromDate,toDate);
                            // printRef.current.save();
                            }}>
                            {loadingPdf ? "Loading..." : "Download Pdf"}{" "}
                            {loadingPdf ? (
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                            ) : (
                                <Fa.FaDownload className="ms-2" />
                            )}
                        </button>
                    </div>
                </div>
            </div>
            <section className="sales-box">
                <form>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                            <label className="form-label">From Date</label>
                            <input type="date" className="form-control"
                                defaultValue={sevenDays}
                                max={toDate}
                                min="2010-01-01"
                                name="date-field"
                                onChange={(e) => {
                                    setFromDate(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                            <label className="form-label">To Date</label>
                            <input type="date" className="form-control"
                                defaultValue={currentDate}
                                max={currentDate}
                                min={fromDate}
                                name="date-field"
                                onChange={(e) => setToDate(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                            <label className="form-label">Customer Type</label>
                            <Dropdown 
                                value={custType}
                                onChange={(e)=>{handleCustType(e);}}
                                options={options}
                            />
                        </div>
                    </div>
                </form>
            </section>

            <section>
                <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 w-75 m-auto">
                        {custShow&&<div className="sales-box">
                            <h3 className="pb-2">Customers List</h3>
                            <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="WriteOff_CustList">
                                    <DataTable
                                        value={customers}
                                        // tableStyle={{ minWidth: "60rem" }}
                                        rows={10}
                                        filterDisplay="row"
                                        showGridlines
                                        stripedRows
                                        selectionMode="checkbox"
                                        scrollHeight="80vh"
                                        scrollable
                                        selection={selectedCustomer}
                                        showSelectAll={false}
                                        onSelectionChange={(e) => {
                                            // console.log("first",e.value);
                                            setSelectedCustomer(e.value);
                                        }}
                                    >
                                        <Column selectionMode="multiple" headerStyle={{ width: "3rem" }}></Column>
                                        <Column field="PARTY_NAME" header="Customers Name" filter filterMatchMode="contains" filterPlaceholder="Search Here..."></Column>
                                    </DataTable>
                                </div>
                            </div>
                        </div>}
                    </div>
                </div>
            </section>
            
            <section>
           
                <div style={{ position: "relative" , zIndex: "-999" }}>
                    <PDFExport
                        fileName="CustomerCreditNote"
                        paperSize="A4"
                        forcePageBreak=".page-break"
                        ref={pdfExportComponent}
                    >
                        <div className="table-responsive Basic_Table" style={{ margin: "10px" }}>
                            <h6 className="" style={{ fontSize: "10px", textAlign: "center" , margin: "10px 0px" }}>Customer Credit Note</h6>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">Credit Note No</th>
                                        <th scope="col">Credit Note Date</th>
                                        <th scope="col">Account</th>
                                        <th scope="col">Currency</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Customer Name</th>
                                        <th scope="col">Customer Code</th>
                                        <th scope="col">Parent ID</th>
                                        <th scope="col">Scheme Code</th>
                                        <th scope="col">Scheme Start Date</th>
                                        <th scope="col">Scheme End Date</th>
                                        {/* <th scope="col">Freeze Date</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {printData.length > 0 &&

                                    printData.map((data,i)=>(
                                        
                                        <tr key={i}>    
                                            <td>{data["Credit Note No"]}</td>
                                            <td>{data["Credit Note Date"]}</td>
                                            <td>{data?.Account}</td>
                                            <td>{data?.Currency}</td>
                                            <td>{data?.Amount}</td>
                                            <td>{data["Customer Name"]}</td>
                                            <td>{data["Customer Code"]}</td>
                                            <td>{data["Parent ID"]}</td>
                                            <td>{data["Scheme Code"]}</td>
                                            <td>{data?.schm_start_date}</td>
                                            <td>{data?.schm_end_date}</td>
                                            {/* <td>{data?.freeze_date}</td> */}
                                        </tr>))
                                    }
                                </tbody>
                            </table>
                        </div>
                    </PDFExport>
                </div>
            </section>
        </div>
    );
};

export default CustomerCreditNote;