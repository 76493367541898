import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    createAPIDocumentEndpoint,
    Endpoint,
} from "../../Services/DocumentAdjustment/DocumnetAdjusService";
import Loader from "../../Components/Loader";
import storage from "../../Services/Storage";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import * as Fa from "react-icons/fa";

const AllRecords = () => {
    const [loading, setLoading] = useState(false);
    const [allTableData, setAllTableData] = useState([]);
    const navlink = useNavigate();
    const auth = storage.getLocal("user");

    const addNewPage = () => {
        navlink("/creditmanagement/documentadjustment/details");
    };

    useEffect(() => {
        setLoading(true);
        createAPIDocumentEndpoint()
            .get(`${Endpoint.GetDocumentAdjustment}?CompId=${auth.CompId}`)
            .then((response) => {
                // console.log("responseData", response.data.Data);
                setAllTableData(response.data.Data);
                setLoading(false);
            })
            .catch(() => {
                setLoading(false);
            });
    }, []);

    const renderAction = (rowdata) => {
         
        return (
            <>
                <div className="action-icon">
                    <div
                        className="d-inline"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="View Receipt Details"
                    >
                        <button
                            className="grid-icon"
                            onClick={() => {
                                navlink(
                                    `/creditmanagement/documentadjustment/details?MaskAdjId=${encodeURIComponent(
                                        rowdata.MASK_ADJ_ID
                                    )}`
                                );
                            }}
                        >
                            <Fa.FaEye />
                        </button>
                    </div>
                </div>
            </>
        );
    };

    // const downloadData = ()=>{
    //     createAPIDocumentEndpoint()
    //         .get(`${Endpoint.GetDocumentAdjustment}?CompId=${auth.CompId}`)
    //         .then((response) => {
    //             console.log("responseData", response.data.Data);
    //             setAllTableData(response.data.Data);
    //             setLoading(false);
    //         })
    //         .catch(() => {
    //             setLoading(false);
    //         });
    // }

    if (loading) {
        return (
            <>
                <Loader />
            </>
        );
    } else {
        return (
            <main>
                <div className="col-12 pt-3 pb-5">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h3 className="py-2">Document Adjustment</h3>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end">
                                <button type="button" className="btn btn-primary" onClick={addNewPage}>Add New</button>
                            </div>
                        </div>
                    </div>
                    {/* <form>
                        <section className="sales-box">
                            <div className="row">
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">From Date</label>
                                        <input type="date" className="form-control" />
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="mb-3">
                                        <label className="form-label">To Date</label>
                                        <input type="date" className="form-control" />
                                    </div>
                                </div>

                                <div className="col-lg-4 col-md-4 col-sm-12 pt-2">
                                    <div className="float-end mt-4">
                                        <button
                                            type="button"
                                            title="Download"
                                            className="btn btn-outline-primary me-3"
                                        >
                      Download
                                        </button>
                                        <button
                                            type="submit"
                                            title="Get Invoices"
                                            className="btn btn-primary"
                                        >
                      Get Data
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </form> */}
                    <section className="">
                        <div className="DataTable">
                            <DataTable
                                value={allTableData}
                                paginator
                                rows={10}
                                filterDisplay="row"
                                showGridlines
                                stripedRows
                                rowsPerPageOptions={[10, 25, 50]}
                                scrollHeight="80vh"
                                emptyMessage="No Records found to display"
                                tableStyle={{ minWidth: "60rem" }}
                            >
                                <Column
                                    field="PARTY"
                                    header="Customer"
                                    style={{ minWidth: "8rem" }}
                                    filter
                                    filterMatchMode="contains"
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field="PAYABLE"
                                    header="Payable"
                                    style={{ minWidth: "12rem" }}
                                    filter
                                    filterMatchMode="contains"
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field="RECEIVABLE"
                                    header="Receivable"
                                    filter
                                    filterMatchMode="contains"
                                    filterPlaceholder="Search Here..."
                                    style={{ minWidth: "12rem" }}
                                ></Column>
                                <Column
                                    field="MASK_FIRST_ENTERED_ON"
                                    header="Created On"
                                    filter
                                    filterMatchMode="contains"
                                    filterPlaceholder="Search Here..."
                                    style={{ minWidth: "12rem" }}
                                ></Column>
                                <Column
                                    field="COMMENTS"
                                    header="Comments"
                                    style={{ minWidth: "12rem" }}
                                    filter
                                    filterMatchMode="contains"
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field="STATUS"
                                    header="Status"
                                    style={{ minWidth: "12rem" }}
                                    filter
                                    filterMatchMode="contains"
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column field={renderAction} header="Action" />
                            </DataTable>
                        </div>
                    </section>
                </div>
            </main>
        );
    }
};

export default AllRecords;
