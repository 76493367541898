import { InputText } from "primereact/inputtext";
import React, { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import * as Fa from "react-icons/fa";
import {
    EndpointWO,
    createAPIEndpointWO,
} from "../../Services/WriteOff/WriteOffService";
import storage from "../../Services/Storage";
import { Checkbox } from "primereact/checkbox";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Loader from "../../Components/Loader";
// import { toast } from "react-toastify";
import { useWriteOffContext } from "../../context/WriteOffContext";
import { useEffect } from "react";
import { useFormik } from "formik";

const WriteOffDetails = () => {
    const [searchParams] = useSearchParams();
    let docNo = searchParams.get("id");
    let docDate = searchParams.get("date");
    let docType = searchParams.get("docType");
    const auth = storage.getLocal("user");
    const [allDataOutStanding, setAllDataOutStanding] = useState([]);
    const [viewObject, setViewObject] = useState([]);
    const [viewWriteOff, setViewWriteOff] = useState(false);
    const { handleSave, writeOff, setWriteOff, isLoading, setIsLoading } =
    useWriteOffContext();

    //formik
    const initialValues = {
        maxAmtWO: "",
        narration: "",
        advWriteOff: false,
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (data) => {
            handleSave(data);
        },
        validate: (data) => {
            let errors = {};
            if (!data.maxAmtWO) {
                errors.maxAmtWO = "Enter amount.";
            }
            // else if(data.amount>999999.99){
            //     errors.amount="value should be less than 999999.99";
            // }
            if (!data.narration) {
                errors.narration = "Narration is required.";
            }
            return errors;
        },
    });

    if (viewWriteOff) {
        formik.initialValues.maxAmtWO = viewObject.MASK_TOT_ADJ_AMT;
        formik.initialValues.narration = viewObject.MASK_REMARKS;
    } else {
        formik.initialValues.maxAmtWO = "";
        formik.initialValues.narration = "";
    }

    //regex
    const numRegex = /^\d*\.?\d{0,2}$/;

    //API calling
    const handleAllWO = () => {
        setIsLoading(true);
        createAPIEndpointWO()
            .get(
                `${EndpointWO.GetAdvanceOutstandingData}?CompId=${auth.CompId}&MaxAmount=${formik.values.maxAmtWO}&chkparty=${formik.values.advWriteOff}`
            )
            .then((res) => {
                setAllDataOutStanding(res?.data?.Data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
    };

    const handleAdvWO = (e) => {
        setIsLoading(true);
        createAPIEndpointWO()
            .get(
                `${EndpointWO.GetAdvanceOutstandingData}?CompId=${auth.CompId}&MaxAmount=${formik.values.maxAmtWO}&chkparty=${e.checked}`
            )
            .then((res) => {
                setAllDataOutStanding(res?.data?.Data);
                setIsLoading(false);
            })
            .catch((error) => {
                setIsLoading(false);
                console.log(error);
            });
        formik.setFieldValue("advWriteOff", e.checked);
    };

    //useEffects
    useEffect(() => {
        if (docNo != null) {
            setIsLoading(true);
            createAPIEndpointWO()
                .get(`${EndpointWO.PopulateWriteOffData}?CompId=${auth.CompId}`)
                .then((res) => {
                    let filterdata = res?.data?.Data.find((item) => {
                        return item.DRCR_NO === docNo;
                    });
                    setViewObject(filterdata);
                    setIsLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setIsLoading(false);
                });
            setViewWriteOff(true);
            createAPIEndpointWO()
                .get(
                    `${EndpointWO.PopulateWODetails}?CompId=${auth.CompId}&strDocNo=${docNo}&strDocDate=${docDate}&strDocType=${docType}`
                )
                .then((res) => {
                    setAllDataOutStanding(res?.data?.Data);
                })
                .catch((error) => {
                    console.log(error);
                });
        } else {
            setViewWriteOff(false);
        }
    }, []);

    //other functions
    const handleMaxMo = (e) => {
        if (numRegex.test(e.target.value)) {
            formik.setFieldValue("maxAmtWO", e.target.value.trim());
        } else {
            return;
        }
    };

    return (
        <div className="col-12 pt-3">
            <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <nav aria-label="breadcrumb">
                        <ol className="breadcrumb mx-1">
                            <li className="breadcrumb-item">
                                <Link to="/creditmanagement/writeoff">Write Off</Link>
                            </li>
                            <li className="breadcrumb-item active" aria-current="page">
                Details
                            </li>
                        </ol>
                    </nav>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="float-end">
                        <Link to="/creditmanagement/writeoff">
                            <button type="submit" className="btn btn-primary">
                                <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All
                Records
                            </button>
                        </Link>
                    </div>
                </div>
            </div>

            <section className="sales-box">
                <form onSubmit={formik.handleSubmit}>
                    <div className="row">
                        {!viewWriteOff && (
                            <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
                                <label className="form-label">
                  Maximum Amount to Write Off{" "}
                                    <span className="mandatory">*</span>
                                </label>
                                <InputText
                                    className="form-control"
                                    value={formik.values.maxAmtWO}
                                    onChange={(e) => handleMaxMo(e)}
                                    onBlur={() => {
                                        formik.handleBlur;
                                        handleAllWO();
                                    }}
                                    disabled={viewWriteOff}
                                />
                                {formik.touched.maxAmtWO && formik.errors.maxAmtWO && (
                                    <small className="p-error">{formik.errors.maxAmtWO}</small>
                                )}
                            </div>
                        )}
                        <div className="col-lg-3 col-md-6 col-sm-12 mb-2">
                            <label className="form-label">
                Narration <span className="mandatory">*</span>
                            </label>
                            <InputText
                                className="form-control"
                                value={formik.values.narration}
                                onChange={(e) =>
                                    formik.setFieldValue("narration", e.target.value)
                                }
                                onBlur={formik.handleBlur}
                                disabled={viewWriteOff}
                            />
                            {formik.touched.narration && formik.errors.narration && (
                                <small className="p-error">{formik.errors.narration}</small>
                            )}
                        </div>
                        {!viewWriteOff && (
                            <div className="col-lg-4 col-md-6 col-sm-12 mt-4">
                                <label className="form-label mb-1">
                  Customer Advance Write Off
                                </label>
                                <label className="chekbox-container d-inline">
                                    <Checkbox
                                        checked={formik.values.advWriteOff}
                                        onChange={(e) => handleAdvWO(e)}
                                    />
                                </label>
                            </div>
                        )}
                        <div className="col-lg-2 col-md-6 col-sm-12 mt-4">
                            <div className="float-end">
                                {!viewWriteOff && (
                                    <button type="submit" className="btn btn-primary">
                    Save
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </form>
            </section>
            <section className="">
                <h3 className="py-1">Records</h3>
                <div className="">
                    <div className="DataTable">
                        {isLoading ? (
                            <Loader />
                        ) : (
                            <DataTable
                                value={allDataOutStanding}
                                dataKey="DOCUMENT_NO"
                                paginator
                                rows={10}
                                filterDisplay="row"
                                scrollable
                                rowsPerPageOptions={[10, 25, 50]}
                                scrollHeight="80vh"
                                showGridlines
                                showSelectAll={false}
                                selection={writeOff}
                                onSelectionChange={(e) => setWriteOff(e.value)}
                                stripedRows
                                globalFilterFields={["PROD_CODE", "DESCRIPTION"]}
                                emptyMessage="No results found."
                                tableStyle={{ minWidth: "60rem" }}
                            >
                                {!viewWriteOff && (
                                    <Column selectionMode="multiple" header="Write Off" />
                                )}
                                {!viewWriteOff && (
                                    <Column
                                        field="CUST_CODE"
                                        header="Customer Code"
                                        filter
                                        filterMatchMode="contains"
                                        filterPlaceholder="Search Here..."
                                        style={{ minWidth: "12rem" }}
                                    />
                                )}
                                {!viewWriteOff && (
                                    <Column
                                        field="CUSTOMER_NAME"
                                        header="Customer Name"
                                        style={{ minWidth: "12rem" }}
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    />
                                )}
                                <Column field="DOCUMENT_NO" header="Document Number" />
                                {!viewWriteOff ? (
                                    <Column field="CURRENCY_NAME" header="Currency" />
                                ) : (
                                    <Column field="DOCUMENT_DATE" header="Document Date" />
                                )}
                                <Column field="OUTSTANDING" header="Outstanding" />
                                {/* <Column field={renderAction} header="Action" /> */}
                            </DataTable>
                        )}
                    </div>
                </div>
            </section>
        </div>
    );
};

export default WriteOffDetails;
