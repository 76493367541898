import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format, subDays } from "date-fns";
import storage from "../../Services/Storage";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import {
    createAPIAdvanceAdjEndpoint,
    Endpoint,
} from "../../Services/CustomerAdvAdj/CustomerAdvAdjService";
import Loader from "../../Components/Loader";
import * as Fa from "react-icons/fa";
import { Dropdown } from "primereact/dropdown";
const AdvanceAdjustmentAllRecords = () => {
    const navlink = useNavigate();
    const addNewPage = () => {
        navlink("/creditmanagement/customeradvanceadjustment/details");
    };
    const currentDate = format(new Date(), "yyyy-MM-dd");
    const date = subDays(new Date(), 7);
    const sevenDays = format(date, "yyyy-MM-dd");
    const [fromDate, setFromDate] = useState(sevenDays);
    const [toDate, setToDate] = useState(currentDate);
    const [viewCustAdvAdj, setViewCustAdvAdj] = useState([]);
    const [dtTableLoading, setdtTableLoading] = useState(false);
    const auth = storage.getLocal("user");
    useEffect(() => {
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");
        getAllTabledata(fromDateRange, toDateRange);
    }, []);

    const getAllTabledata = (StartDate, EndDate) => {
        setdtTableLoading(true);
        createAPIAdvanceAdjEndpoint()
            .get(
                `${Endpoint.GetAdvanceAdjustment}?StartDate=${StartDate}&EndDate=${EndDate}&CompId=${auth.CompId}`
            )
            .then((response) => {
                // console.log("responseData", response.data.Data);
                if (response?.data?.Data == null) {
                    setViewCustAdvAdj([]);
                    setdtTableLoading(false);
                } else {
                    setViewCustAdvAdj(response?.data?.Data);
                    setdtTableLoading(false);
                }
            })
            .catch(() => {
                // setReceiveLoading(false);
                setdtTableLoading(false);
            });
    };

    const findDateReceipts = (e, fromDate, toDate) => {
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");

        getAllTabledata(fromDateRange, toDateRange);
        e.preventDefault();
    };

    const renderAction = (rowdata) => {
        return (
            <>
                <div className="action-icon">
                    <div
                        className="d-inline"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="View Advance Details"
                    >
                        <button
                            className="grid-icon"
                            onClick={() => {
                                navlink(
                                    `/creditmanagement/customeradvanceadjustment/details?partyid=${encodeURIComponent(
                                        rowdata.MASK_PARTY_ID
                                    )}&dt=${encodeURIComponent(rowdata.MASK_FIRST_ENTERED_ON)}&adjno=${encodeURIComponent(rowdata.ADJ_NO)}`
                                );
                            }}
                        >
                            <Fa.FaEye />
                        </button>
                    </div>
                </div>
            </>
        );
    };

    const [statuses] = useState(["Open","Cancel"]);
    const statusRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Select One" className="p-column-filter" showClear style={{ maxWidth: "12rem" }} />
        );
    };

    return (
        <main>
            <div className="col-12 pt-3 pb-5">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <h3 className="py-2">Customer Advance Adjustment</h3>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="float-end">
                            <button type="button" className="btn btn-primary" onClick={addNewPage}>Add New</button>
                        </div>
                    </div>
                </div>
                {/* <section className="sales-box">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="mb-3">
                                    <label className="form-label">From Date</label>
                                    <input type="date" className="form-control" />
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-12">
                                <div className="mb-3">
                                    <label className="form-label">To Date</label>
                                    <input type="date" className="form-control" />
                                </div>
                            </div>

                            <div className="col-lg-4 col-md-4 col-sm-12 pt-2">
                                <div className="float-end mt-4">
                                    <button type="submit" title="Get Invoices" className="btn btn-primary">Get Data</button>
                                </div>
                            </div>
                        </div>
                    </section> */}
                <section className="sales-box">
                    <form>
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                <label className="form-label">From Date</label>
                                <input
                                    type="date"
                                    className="form-control"
                                    defaultValue={sevenDays}
                                    max={currentDate}
                                    min="2010-01-01"
                                    name="date-field"
                                    onChange={(e) => {
                                        setFromDate(e.target.value);
                                    }}
                                />
                            </div>
                            <div className="col-lg-4 col-md-6 col-sm-12">
                                <label className="form-label">To Date</label>
                                <input
                                    type="date"
                                    defaultValue={currentDate}
                                    max={currentDate}
                                    className="form-control"
                                    name="date-field"
                                    min="2010-01-01"
                                    onChange={(e) => setToDate(e.target.value)}
                                />
                            </div>
                            <div className="col-lg-4 col-md-12 col-sm-12">
                                <div className="float-end mt-4">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={(e) => {
                                            findDateReceipts(e, fromDate, toDate);
                                        }}
                                    >
                    Get Data
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </section>
                <section className="">
                    <h3>All Records</h3>
                    <div className="DataTable">
                        {dtTableLoading ? (
                            <>
                                <Loader />
                            </>
                        ) : (
                            <DataTable
                                value={viewCustAdvAdj}
                                paginator
                                rows={10}
                                showGridlines
                                stripedRows
                                filterDisplay="row"
                                emptyMessage="No Records found to display"
                                tableStyle={{ minWidth: "60rem" }}
                            >
                                <Column
                                    field="ADJ_NO"
                                    header="Adjustment Number"
                                    style={{ minWidth: "12rem" }}
                                    filter
                                    filterMatchMode="contains"
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field="ADJ_DATE"
                                    header="Adjustment Date"
                                    style={{ minWidth: "12rem" }}
                                    filter
                                    filterMatchMode="contains"
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                <Column
                                    field="PARTY_NAME"
                                    header="Customer"
                                    style={{ minWidth: "12rem" }}
                                    filter
                                    filterMatchMode="contains"
                                    filterPlaceholder="Search Here..."
                                ></Column>
                                {/* <Column
                                    field="STATUS"
                                    header="Status"
                                    style={{ minWidth: "12rem" }}
                                    filter
                                    filterMatchMode="contains"
                                    filterPlaceholder="Search Here..."
                                ></Column> */}
                                <Column
                                    field="STATUS"
                                    header="Status"
                                    // body={modeStatus}
                                    showFilterMenu={false} filter filterElement={statusRowFilterTemplate}  style={{ minWidth: "12rem" }}
                                ></Column>
                                <Column field={renderAction} header="Action" />
                            </DataTable>
                        )}
                    </div>
                </section>
            </div>
        </main>
    );
};

export default AdvanceAdjustmentAllRecords;
