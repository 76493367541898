import { AutoComplete } from "primereact/autocomplete";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
// import { useSearchParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
// import DataTable from "react-data-table-component";
import { useSalesReturnContext } from "../../context/SalesReturnContext";
import { useFormik } from "formik";
import GetInvoiceModal from "./GetInvoiceModal";
import storage from "../../Services/Storage";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { format } from "date-fns";
import { toast } from "react-toastify";
import * as Fa from "react-icons/fa";
import { EndpointSalesReturn, createAPIEndpointSalesReturn } from "../../Services/SalesReturnAdd/SalesReturnServices";
import Loader from "../../Components/Loader";
import { Dropdown } from "primereact/dropdown";
import InformationModal from "./InformationModal";
import { Link, useSearchParams } from "react-router-dom";

const AllSalesReturnTable = () => {
    // const nav = useNavigate();
    // const auth = storage.getLocal("user");
    const [searchParams] = useSearchParams();
    // let maskproid = searchParams.get("mask_pro_id");

    // const [credDetails,setCredDetails] =useState([]);
    const [viewObject, setViewObject] = useState([]);
    const auth = storage.getLocal("user");
    const currentDate = format(new Date(), "dd/MM/yyyy");
    const cntNo = searchParams.get("mask_salret_id");
    const cntDate = searchParams.get("INVH");


    const {
        handleWareData,
        // searchState1,
        itemTemplateware,
        itemTemplatecust,
        // resondessugg,
        handleReasonData,
        // searchState2,
        itemTemplatereson,

        customernamedisable,
        prodcutsugg,
        // handleReasonData,
        itemTemplateprod,
        // handleProductCode,   
        popupgenerate,
        onPageLoadAddSalesReturn,
        custNameSuggest,
        CustomerNamefilter,
        reasonDescSuggest,
        ReasonDescFilter,
        WarehouseFilter,
        warehouseSuggest,
        handleCustomerSelect,
        selectedData,
        TotalInvoice,
        totalInvQty,
        addToCart,
        invSearchData,
        populateDataTable,
        setPopulateDataTable,
        setWarehouse,
        newSaveSalesReturn,
        setDisableProduct,
        disableProduct,
        setTotalInvQty,
        setSelectedData,
        customerInfo,
        isLoading,
        setIsLoading,
        invoiceDataLoad,
        viewSalesReturn,
        setViewSalesReturn,
        setinvoiceDataLoad
    } = useSalesReturnContext();


     

    const setCustCode = (e) => {
        formik.values.customer_code = e.value.CODE;
         
    };

    const submitsalesreturn = () => {
        newSaveSalesReturn(formik.values);
    };



    const handleSubmit = async (data) => {
        // debugger
        let prdDtl = [{
            ciCode: auth.CICode,
            custId: data.customer_name?.MASK_CUST_ID,
            productId: data.product_code?.MASK_PROD_ID,
            productCode: data.product_code?.CODE,
            totalReturnQty: data.total_returned_quantity,
            totalOrderQty: data.total_invoiced_quantity,
            invoiceDetailId: data.product_code.MASK_INVD_ID,
            hdnExRate: invSearchData.MASK_INVH_EXCH_RATE,
            masK_TAX_CRITERIA: invSearchData.MASK_TAX_FORM_ID,


        }];
        // if(!formik.values.full_invoice){
        addToCart(prdDtl);
        formik.values.product_code = "";
        formik.values.product_description = "";
        setTotalInvQty("");
        formik.values.total_returned_quantity = "";
        // }
        // newSaveSalesReturn(data);
    };
     

    const initialValues = {
        credit_note_no: "",
        credit_note_date: "",
        customer_code: "",
        customer_name: "",
        matching_doc_no: "",
        get_invoice: "",
        matching_doc_date: "",
        warehouse: "",
        reason_description: "",
        base_currency_amount: "0.00",
        print_message: "",
        total_amount: "0.00",
        full_invoice: false,
        product_code: "",
        total_invoiced_quantity: "",
        product_description: "",
        total_returned_quantity: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
        onSubmit: (data) => {
             
            handleSubmit(data);
        },
        validate: (data) => {
            const errors = {};
            //only character no number or special character
            if (!data.customer_name) {
                errors.customer_name = "Customer name is required";
            }
            if (!data.matching_doc_no) {
                errors.matching_doc_no = "Matching doucument is required";
            }
            if (!data.warehouse) {
                errors.warehouse = "Warehouse is required";
            }
            if (!data.reason_description) {
                errors.reason_description = "Reason Description is required";
            }
            if (!data.product_code) {
                errors.product_code = "Product Code is required";
            }
            if (!data.total_returned_quantity) {
                errors.total_returned_quantity = "Invoice Return quantity is required";
            }
            else if (data.total_returned_quantity) {
                if (Number(data.total_returned_quantity) > Number(data.total_invoiced_quantity)) {
                    errors.total_returned_quantity = "Returned quantity cannot be greater.";
                }
                else if(Number(data.total_returned_quantity)<0){
                    errors.total_returned_quantity = "Returned quantity cannot be Negetive.";
                }
            }

             
            return errors;
        },
    });


    const productDistribution = (e) => {
        formik.setFieldValue("product_description", e.target.value.DESCRIPTION);
    };

    const handleDeletecart = (rowData) => {
        formik.setFieldValue("full_invoice",false);
        const updatedData = populateDataTable.filter(item => item.PRODUCT_CODE !== rowData.PRODUCT_CODE);
        setPopulateDataTable(updatedData);
    };



    const renderActions = (rowData) => {

        return (
            <div>
                <button
                    className="grid-icon"
                    onClick={() => handleDeletecart(rowData)}
                >
                    <Fa.FaTimes className="cancel" />
                </button>
            </div>
        );
    };
     

    useEffect(() => {
        try {
            createAPIEndpointSalesReturn()
                .getDefaultValue(`${EndpointSalesReturn.WhDefaultVal}?CompId=${auth.CompId}&UserId=${auth.UserId}`)
                .then((response) => {
                    createAPIEndpointSalesReturn()
                        .get(`${EndpointSalesReturn.GetWareHouse}?userId=${auth.UserId}&CompId=${auth.CompId}`)
                        .then((res) => {
                            setWarehouse(res.data.Data);
                            initialValues.warehouse = res.data.Data.find((val) => {
                                return val.MASK_WH_ID === response.data.Id;
                            });
                        });
                });
        } catch (error) {
            console.log(error);
        }
        const fetchWareHouse = async () => {
            try {
                createAPIEndpointSalesReturn()
                    .getDefaultValue(`${EndpointSalesReturn.WhDefaultVal}?CompId=${auth.CompId}&UserId=${auth.UserId}`)
                    .then((ress) => {
                        createAPIEndpointSalesReturn()
                            .get(`${EndpointSalesReturn.GetWareHouse}?UserId=${auth.UserId}&CompId=${auth.CompId}`)
                            .then((res) => {
                                initialValues.warehouse = res.data.Data.find((val) => {
                                    return val.MASK_WH_ID === ress.data.Id;
                                });
                                let values = res.data.Data.map((val) => {
                                    return val;
                                });
                                setWarehouse(values);
                            });
                    });

            } catch (error) {
                console.log(error);
            }
        };
        fetchWareHouse();
        onPageLoadAddSalesReturn();
    }, []);


    useEffect(() => {
        if (cntNo != null) {
            setinvoiceDataLoad(true);
            createAPIEndpointSalesReturn()
                .get(`${EndpointSalesReturn.GetSalesReturnRecord}?CompId=${auth.CompId}&UserId=${auth.UserId}&FinYearStart=${cntDate}&FinYearEnd=${cntDate}`)
                .then((res) => {
                     
                    // setCredDetails(res.data.Data);

                    let filterdata = res.data.Data.find((item) => {
                        return item.MASK_INVH_ID === cntNo;
                    });
                    setViewObject(filterdata);
                    setinvoiceDataLoad(false);
                })
                .catch((error) => {
        
                    console.log(error);
                    setinvoiceDataLoad(false);
                });


            setIsLoading(true);
            setViewSalesReturn(true);
            createAPIEndpointSalesReturn()
                .get(`${EndpointSalesReturn.GetSalRetDetails}?CICode=${auth.CICode}&strInvHdrId=${cntNo}`)
                .then((res) => {
                    setPopulateDataTable(res.data.Data);
                    // setIsLoading(false);
                    setIsLoading(false);
                }).catch((error) => {
                    
                    setIsLoading(false);
        
                    console.log(error);
                });
        }


    }, []);
     
    useEffect(() => {

        if (formik.values.full_invoice == true) {
            formik.touched.total_returned_quantity = false;
            setDisableProduct(true);
            setIsLoading(true);
            formik.setFieldValue("product_code", "");
            formik.values.product_code = "";
            formik.values.product_description = "";
            formik.setFieldValue("product_description", "");
            formik.values.total_returned_quantity = "";
            formik.setFieldValue("total_returned_quantity", "");
            setTotalInvQty("");
            createAPIEndpointSalesReturn()
                .get(`${EndpointSalesReturn.GetSalRetDetails}?CICode=${auth.CICode}&strInvHdrId=${selectedData.MASK_INVH_ID}`)
                .then((res) => {
                    setPopulateDataTable(res.data.Data);
                    setIsLoading(false);
                });
        }
        else {
            setDisableProduct(false);
            setPopulateDataTable([]);
        }
    }, [formik.values.full_invoice]);

    const whid = formik.values.warehouse?.MASK_WH_ID;
    useEffect(() => {
        customerInfo(whid);
    }, [formik.values.warehouse]);
    // const viewObject = credDetails.find((item)=> {
    //      
    //     console.log("first,",cntNo)
    // item.INVH_NO === cntNo }
    // );
     
    if (viewSalesReturn) {

        formik.initialValues.customer_code = viewObject.MASK_CUST_CODE;
        formik.initialValues.credit_note_no = viewObject.INVH_NO;
        formik.initialValues.credit_note_date = viewObject.INVH_DATE;
        formik.initialValues.customer_name = viewObject.CUST_NAME;
        formik.initialValues.matching_doc_no = viewObject.MASK_INVH_NO;
        formik.initialValues.matching_doc_date = viewObject.MASK_INVH_DATE;
        formik.initialValues.warehouse = viewObject.MASK_WH_DESC;
        formik.initialValues.reason_description = viewObject.MASK_SALES_RET_REAS_DESC;
        formik.initialValues.base_currency_amount = viewObject.MASK_INVH_TOT_BASE_CRNC_AMT;
        formik.initialValues.print_message = viewObject.MASK_INVH_PRINT_MESSAGE;
        formik.initialValues.total_amount = viewObject.MASK_INVH_TOT_AMT;
    }
    else {
        formik.initialValues.credit_note_no = "";
        formik.initialValues.credit_note_date = "";
        formik.initialValues.customer_name = "";
        formik.initialValues.matching_doc_no = "";
        formik.initialValues.matching_doc_date = "";
        // formik.initialValues.warehouse="";
        formik.initialValues.reason_description = "";
        formik.initialValues.base_currency_amount = "";
        formik.initialValues.print_message = "";
        formik.initialValues.total_amount = "";

    }
     
     
    useEffect(() => {
        setSelectedData([]);
    }, []);
    if (invoiceDataLoad) {
        return (
            <Loader />
        );
    }
    else {
        return (
            <div className="col-12 pt-3">
                <div className="row">
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mx-1">
                                <li className="breadcrumb-item">
                                    <Link to="/salesmanagement/salesreturn">Sales Return</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">Details</li>
                            </ol>
                        </nav>

                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="float-end mb-2">
                            <Link to="/salesmanagement/salesreturn"><button type="submit" className="btn btn-primary">
                                <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All Records
                            </button>
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="">
                    <form onSubmit={formik.handleSubmit}>
                        <section className="sales-box">
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">
                                        Credit Note Number <span className="mandatory">*</span>
                                    </label>
                                    <InputText
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={formik.values.credit_note_no}
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">
                                        Credit Note Date <span className="mandatory">*</span>
                                    </label>
                                    <InputText
                                        className="form-control"
                                        placeholder=""
                                        value={formik.values.credit_note_date = currentDate}
                                        // defaultValue={notnullval ? responsedtaofdate:currentDate}
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">
                                        Customer Code <span className="mandatory">*</span>
                                    </label>
                                    <InputText
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={formik.values.customer_code}
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">
                                        Customer Name <span className="mandatory">*</span>
                                    </label>
                                    <div className="d-block">
                                        <AutoComplete
                                            field="NAME"
                                            name="customer_name"
                                            placeholder="Select Customer Name"
                                            value={formik.values.customer_name}
                                            suggestions={custNameSuggest}
                                            onChange={(e) => {
                                                // customerInfo(formik.values.warehouse.MASK_WH_ID);
                                                formik.setFieldValue("full_invoice", false);
                                                formik.handleChange;
                                                formik.setFieldValue("customer_name", e.value);
                                                handleCustomerSelect(e.value);
                                                setCustCode(e);
                                                setSelectedData([]);
                                                setPopulateDataTable([]);
                                                formik.setFieldValue("customer_code", e.value.CODE);
                                                 
                                            }}
                                            onBlur={formik.handleBlur}
                                            completeMethod={CustomerNamefilter}
                                            itemTemplate={itemTemplatecust}
                                            dropdown
                                            disabled={viewSalesReturn}
                                            emptyMessage="Customer name not found."
                                            showEmptyMessage
                                        />
                                        {formik.touched.customer_name && formik.errors.customer_name && (
                                            <small className="p-error">{formik.errors.customer_name}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">
                                        Matching Doc Number <span className="mandatory">*</span>
                                    </label>
                                    {/* <InputText className="form-control" type="text" placeholder="" /> */}
                                    <div className="input-group">
                                        <InputText
                                            className="form-control"
                                            type="text"
                                            value={selectedData.length !== 0 ? formik.values.matching_doc_no = selectedData?.INVH_NO : formik.values.matching_doc_no}
                                            placeholder=""
                                            disabled
                                            onBlur={formik.handleBlur}
                                            onChange={formik.handleChange}
                                        />
                                        <div className="input-group-prepend">
                                            <button
                                                className={!customernamedisable ? "GetInvoiced ms-2" : "input-group-text ms-2"}
                                                type="button"
                                                disabled={!customernamedisable}
                                                onClick={() => {
                                                    popupgenerate();
                                                    formik.setFieldValue("full_invoice", false);
                                                    setPopulateDataTable([]);
                                                }}
                                            >
                                                Get Invoice
                                            </button>
                                        </div>
                                    </div>
                                    {formik.touched.matching_doc_no && formik.errors.matching_doc_no && (
                                        <small className="p-error">{formik.errors.matching_doc_no}</small>
                                    )}
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">
                                        Matching Doc Date <span className="mandatory">*</span>
                                    </label>
                                    <InputText
                                        className="form-control"
                                        placeholder=""
                                        value={selectedData.length !== 0 ? formik.values.matching_doc_date = selectedData.INVH_DATE : formik.values.matching_doc_date}
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">
                                        Warehouse <span className="mandatory">*</span>
                                    </label>
                                    <div className="d-block">
                                        <AutoComplete
                                            field="DESCRIPTION"
                                            name="warehouse"
                                            value={formik.values.warehouse}
                                            suggestions={warehouseSuggest}
                                            onChange={(e) => {
                                                formik.handleChange;
                                                handleWareData(e);
                                                formik.setFieldValue("warehouse", e.value);
                                            }}
                                            onBlur={formik.handleBlur}
                                            completeMethod={WarehouseFilter}
                                            itemTemplate={itemTemplateware}
                                            dropdown
                                            disabled={viewSalesReturn}
                                        />
                                        {formik.touched.warehouse && formik.errors.warehouse && (
                                            <small className="p-error">{formik.errors.warehouse}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">
                                        Reason Description <span className="mandatory">*</span>
                                    </label>
                                    <div className="d-block">
                                        <AutoComplete
                                            field="DESCRIPTION"
                                            name="reason_description"
                                            placeholder="Select Reason Description"
                                            value={formik.values.reason_description}
                                            suggestions={reasonDescSuggest}
                                            onChange={(e) => {
                                                formik.handleChange;
                                                handleReasonData(e);
                                                formik.setFieldValue(
                                                    "reason_description",
                                                    e.target.value
                                                );
                                            }}
                                            onBlur={formik.handleBlur}
                                            completeMethod={ReasonDescFilter}
                                            itemTemplate={itemTemplatereson}
                                            dropdown
                                            disabled={viewSalesReturn}
                                        />
                                        {formik.touched.reason_description && formik.errors.reason_description && (
                                            <small className="p-error">{formik.errors.reason_description}</small>
                                        )}
                                    </div>
                                </div>
                                {/* <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">Base Currency Amount</label>
                                    <InputText
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={formik.values.base_currency_amount =  populateDataTable.length>0?((
                                            Math.round(
                                                populateDataTable.map((a)=>Number(a.PROD_BASE_PRICE))
                                                    .reduce((a,b)=> a + b) -
                                                populateDataTable.map((a)=> Number(a.CDR_DISC))
                                                    .reduce((a,b)=>a + b) -
                                                populateDataTable.map((a)=>Number(a.EUS_DISC))
                                                    .reduce((a,b)=>a + b) -
                                                populateDataTable.map((a)=> Number(a.MASK_INVD_MOP_VAL))
                                                    .reduce((a,b)=> a + b) -
                                                populateDataTable.map((a)=>Number(a.MASK_TRADE_DISC))
                                                    .reduce((a,b)=> a + b) +
                                                populateDataTable.map((a)=>Number(a.MASK_TOT_TAX))
                                                    .reduce((a,b)=> a + b) +
                                                populateDataTable.map((a)=>Number(a.MASK_VAT_TAX))
                                                    .reduce((a,b)=> a + b)
                                            ) * invSearchData.MASK_INVH_EXCH_RATE
                                        )).toLocaleString("en-IN", { minimumFractionDigits: 2 }) :"0.00" }
                                        disabled
                                    />
                                </div> */}
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">Total Amount</label>
                                    <InputText
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={
                                            formik.values.total_amount =
                                            populateDataTable.length > 0 ? ((
                                                Math.round(
                                                    populateDataTable.map((a) => Number(a.PROD_BASE_PRICE))
                                                        .reduce((a, b) => a + b) -
                                                    populateDataTable.map((a) => Number(a.CDR_DISC))
                                                        .reduce((a, b) => a + b) -
                                                    populateDataTable.map((a) => Number(a.EUS_DISC))
                                                        .reduce((a, b) => a + b) -
                                                    populateDataTable.map((a) => Number(a.MASK_INVD_MOP_VAL))
                                                        .reduce((a, b) => a + b) -
                                                    populateDataTable.map((a) => Number(a.MASK_TRADE_DISC))
                                                        .reduce((a, b) => a + b) +
                                                    populateDataTable.map((a) => Number(a.MASK_TOT_TAX))
                                                        .reduce((a, b) => a + b) +
                                                    populateDataTable.map((a) => Number(a.MASK_VAT_TAX))
                                                        .reduce((a, b) => a + b)
                                                )
                                            )).toLocaleString("en-IN", { minimumFractionDigits: 2 }) : "0.00"
                                        }
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">Print Message</label>
                                    <InputText
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={formik.values.print_message}
                                        onChange={(e) => {
                                            formik.setFieldValue("print_message", e.target.value);
                                        }}
                                        disabled={viewSalesReturn}
                                    />
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-12 mb-3 mt-3">
                                    <label className="form-label">
                                        Full Invoice <span className="mandatory">*</span>
                                    </label>
                                    <label className="d-inline ms-2">
                                        <InputSwitch
                                            className="switch-pos"
                                            id="full_invoice"
                                            name="full_invoice"
                                            checked={formik.values.full_invoice}
                                            onChange={(e) => {
                                                if (formik.values.reason_description == "") {
                                                    toast.error(
                                                        "Please select a Reason.",
                                                        {
                                                            position: toast.POSITION.TOP_CENTER,
                                                            pauseOnFocusLoss: false,
                                                            closeButton: false,
                                                            autoClose: 2000,
                                                        }

                                                    );
                                                }
                                                else {
                                                    formik.setFieldValue("full_invoice", e.value);
                                                }
                                            }}
                                            disabled={formik.values.customer_name == "" || viewSalesReturn ? true : false}
                                        />
                                    </label>
                                </div>
                            </div>
                        </section>

                        <section className="sales-box">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">Product Code</label>
                                    <div className="GreyField">
                                        <Dropdown
                                            name="DESCRIPTION"
                                            options={prodcutsugg}
                                            optionLabel="CODE"
                                            value={formik.values.product_code}
                                            onChange={(e) => {
                                                formik.handleChange;
                                                TotalInvoice(e.value);
                                                productDistribution(e);
                                                formik.setFieldValue("product_code", e.target.value);
                                            }}
                                            dropdown={true}
                                            itemTemplate={itemTemplateprod}
                                            emptyMessage='No results found.'
                                            showEmptyMessage
                                            disabled={disableProduct || viewSalesReturn}
                                        />
                                        {formik.touched.product_code && formik.errors.product_code && (
                                            <small className="p-error">{formik.errors.product_code}</small>
                                        )}
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">Total Invoiced Quantity</label>
                                    <InputText
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={formik.values.total_invoiced_quantity = Number(totalInvQty).toFixed(2)}
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">Product Description</label>
                                    <InputText
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={formik.values.product_description}
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">Total Returned Quantity</label>
                                    <InputText
                                        className="form-control"
                                        type="text"
                                        placeholder=""
                                        value={formik.values.total_returned_quantity}
                                        disabled={disableProduct || viewSalesReturn}
                                        onBlur={formik.handleBlur}
                                        onChange={(e) => {
                                            formik.handleChange;
                                            formik.setFieldValue(
                                                "total_returned_quantity",
                                                e.target.value
                                            );
                                        }}

                                    />
                                    {formik.touched.total_returned_quantity && formik.errors.total_returned_quantity && (
                                        <small className="p-error">{formik.errors.total_returned_quantity}</small>
                                    )}
                                </div>
                                <div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="float-end mt-2">
                                        <button
                                            type="submit"
                                            className="btn btn-outline-primary me-3"
                                            disabled={disableProduct || viewSalesReturn}
                                        >
                                            Add To Cart
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={submitsalesreturn}
                                            disabled={viewSalesReturn || populateDataTable.length==0}
                                        >
                                            Save
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </form>
                </div>
                <div>
                    {isLoading ? <Loader /> : <section className="mt-3">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                            <h3>All Records</h3>
                            <div className="DataTable py-2">
                                <DataTable
                                    value={populateDataTable}
                                    dataKey='productCode'
                                    tableStyle={{ minWidth: "60rem" }}
                                    rowsPerPageOptions={[10, 25, 50]}
                                    scrollHeight="80vh"
                                    scrollable
                                    showGridlines
                                    stripedRows>
                                    <Column field="PRODUCT_CODE" header="Product Code"></Column>
                                    <Column field="PROD_SMALL_DESC" header="Product Description"></Column>
                                    <Column field="PROD_BATCH_NO" header="Product Lot Number"></Column>
                                    <Column field="TOT_INV_QTY" header="Total Quantity"></Column>
                                    <Column field="PROD_PRICE" header="Product Price"></Column>
                                    <Column field="EXTENDED_PRICE" header="Extended Price"></Column>
                                    {!viewSalesReturn && <Column body={renderActions} header="Action "></Column>}
                                </DataTable>
                            </div>
                        </div>
                    </section>}
                </div>
                <GetInvoiceModal />
                <InformationModal />
            </div>
        );
    }
};

export default AllSalesReturnTable;
