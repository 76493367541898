import React, { useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import * as Fa from "react-icons/fa";
import { Column } from "primereact/column";
import { EndpointSalesReports, createAPIEndpointSalesReports } from "../../../Services/ReportsAll/SalesReportService";
import storage from "../../../Services/Storage";
import { toast } from "react-toastify";
import XLSX from "xlsx";
import Loader from "../../../Components/Loader";


const OpenSalesOrder = () => {
    const auth = storage.getLocal("user");
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [loadingExcelOnLoad, setLoadingExcelOnLoad] = useState(false);
    const [allOpenSalesData, setAllOpenSalesData] = useState([]);

    useEffect(() => {
        setLoadingExcelOnLoad(true);
        createAPIEndpointSalesReports()
            .get(`${EndpointSalesReports.OpenSalesOrder}?CompId=${auth.CompId}`)
            .then((val) => {
                setAllOpenSalesData(val?.data);
                setLoadingExcelOnLoad(false);
            })
            .catch((err) => {
                toast.error(
                    `${err.response.data}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    }

                );
                setLoadingExcelOnLoad(false);
            });
    }, [1]);

    const handleExportExcel = () => {
        setLoadingExcel(true);
        let uniqueNo = "OpenSalesOrderReport";
        createAPIEndpointSalesReports()
            .get(`${EndpointSalesReports.OpenSalesOrder}?CompId=${auth.CompId}`)
            .then((val) => {
                if (val.data.length > 0) {
                    let wb = XLSX.utils.book_new();
                    
                    let MapData = val.data.map((val) => {
                        let dateString = val["SO Date"];
                        let newData = dateString.replace(/(\d+[/])(\d+[/])/, "$2$1");
                        let data = new Date(newData);
                        return { ...val, "SO Date": data };
                    });
                    let ws = XLSX.utils.json_to_sheet(MapData);
                    XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
                    XLSX.writeFile(wb, `${uniqueNo}.xlsx`);

                }
                else {
                    toast.warning(
                        "No Data Found between these dates",
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                }
                setLoadingExcel(false);

            }).catch((err) => {

                toast.error(
                    `${err.response.data}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    }

                );
                setLoadingExcel(false);
            });


    };
    if (loadingExcelOnLoad) {
        return (
            <Loader />
        );
    }
    else {
        return (
            <>
                <main>
                    <div className="col-12 pt-3 pb-4">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h3 className="py-2">Open Sales Order</h3>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <div className="float-end mb-2">
                                    <button
                                        type="button"
                                        className="btn btn-primary"
                                        onClick={() => { handleExportExcel(); }}
                                        disabled={loadingExcel}
                                    >
                                        {loadingExcel ? "Loading..." : "Export All"}{" "}
                                        {loadingExcel ? (
                                            <span
                                                className="spinner-border spinner-border-sm"
                                                role="status"
                                                aria-hidden="true"
                                            ></span>
                                        ) : (
                                            <Fa.FaFileExport className="ms-2" />
                                        )}
                                    </button>                            </div>
                            </div>
                        </div>
                        <section className="">
                            <div className="DataTable">
                                <DataTable
                                    value={allOpenSalesData}
                                    dataKey='MASK_CUST_ID'
                                    paginator
                                    rows={10}
                                    filterDisplay="row"
                                    scrollable
                                    rowsPerPageOptions={[10, 25, 50]}
                                    scrollHeight="80vh"
                                    showGridlines
                                    stripedRows
                                    globalFilterFields={["Product Code", "Product Description"]} emptyMessage="No Sales Order found."
                                    tableStyle={{ minWidth: "150rem" }}>
                                    <Column field="Customer Code" header="Customer Code" filter filterMatchMode="contains" filterPlaceholder="Search Here..." />
                                    <Column field="Customer Name" header="Customer Name" filter filterMatchMode="contains" filterPlaceholder="Search Here..." />
                                    <Column field="SO No" header="SO Number" filter filterMatchMode="contains" filterPlaceholder="Search Here..." />
                                    <Column field="SO Date" header="SO Date" filter filterMatchMode="contains" filterPlaceholder="Search Here..." />
                                    <Column field="Product Code" header="Product Code" filter filterMatchMode="contains" filterPlaceholder="Search Here..." />
                                    <Column field="Product Description" header="Product Description" filter filterMatchMode="contains" filterPlaceholder="Search Here..." />
                                    <Column field="Price List No" header="Price List Number" filter filterMatchMode="contains" filterPlaceholder="Search Here..." />
                                    <Column field="Order Qty" header="Order QTY" />
                                    <Column field="Release Qty" header="Release QTY" />
                                    <Column field="Invoice Qty" header="Invoice QTY" />
                                    <Column field="Back Qty" header="Back QTY" />
                                    <Column field="Drop Qty" header="Drop QTY" />
                                </DataTable>
                            </div>
                        </section>
                    </div>
                </main>
            </>
        );
    }

};

export default OpenSalesOrder;