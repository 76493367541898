import React, { useEffect, useRef, useState } from "react";
import { PDFExport } from "@progress/kendo-react-pdf";
import Modal from "react-bootstrap/Modal";
import "../DebitCreditNote/Print.scss";
import { MdCurrencyRupee } from "react-icons/md";
import { useGlobalContext1 } from "../../context/SalesDetailsContext";
import Axios from "../../api/Axios";
import Loader from "../../Components/Loader";
import { useGlobalContext } from "../../context/SalesOrderContext";
import PrintInvoicePopUp from "../../Components/PrintInvoicePopUp";

const AnnexurePrint = () => {
    const PRINT_INVOICE = "/InvoiceAnnexurePrint/PrintCNInvoice";
    const [printInvoiceData, setPrintInvoiceData] = useState([]);
    // console.log("printInvoiceData", printInvoiceData);
    const [printLoding, setPrintLoding] = useState(true);
    const pdfExportComponent = useRef(null);
    const printRef = useRef(null);

    const {
        invoicePopup,
        setInvoicePopup,
        printInvoiceModalData,
        // setInvoice,
        setInvoiceBtn,
    // setOrderStatus,
    // setPrintInvoiceModalData,
    } = useGlobalContext1();
    const {orientation} = useGlobalContext();
    // console.log("printInvoiceModalData123", printInvoiceModalData);
    const printInvoiceCall = async () => {
        setPrintLoding(true);
        try {
            const printResponse = await Axios.post(
                PRINT_INVOICE,
                JSON.stringify(printInvoiceModalData),
                {
                    headers: { "Content-Type": "application/json" },
                }
            );
            // toast.success("Invoice Created Successfully", {
            //     position: toast.POSITION.TOP_CENTER,
            //     pauseOnFocusLoss: false,
            //     closeButton: false,
            //     autoClose: 2000,
            // });
            setPrintInvoiceData(printResponse.data.objHdrDetl);
            //   if (printResponse && printResponse.data.length > 0) {
            //     printResponse.data.forEach((responseData) => {
            //       setInvoiceNo(responseData.clsLabelInvPrint.INV_NO1);
            //       GrandTotalAmount.push(
            //         ConvertNumberToWords(
            //           responseData.clsModelInvoicePrintDtl.TOTAL &&
            //             responseData.clsModelInvoicePrintDtl.Total2 &&
            //             responseData.clsModelInvoicePrintDtl.Total3 &&
            //             responseData.clsModelInvoicePrintDtl.Total4 &&
            //             responseData.clsModelInvoicePrintDtl.Total5 &&
            //             responseData.clsModelInvoicePrintDtl.Total6
            //         )
            //       );
            //       setPrintLoding(false);
            //       setInvoice(true);
            //       setPrintInvoiceModalData([]);
            //       mod = Math.ceil(responseData.dtProductList.length / 8);
            //       // responseData.dtProductList = responseData.dtProductList.slice(8);
            //     });
            //     {
            //       !printonly &&
            //         toast.success('Invoice Created Successfully', {
            //           position: toast.POSITION.TOP_CENTER,
            //           pauseOnFocusLoss: false,
            //           closeButton: false,
            //           autoClose: 2000,
            //         });
            //     }
            //   }
        } catch (error) {
            // toast.error(error.response.data.Message, {
            //     position: toast.POSITION.TOP_CENTER,
            //     pauseOnFocusLoss: false,
            //     closeButton: false,
            //     autoClose: 2000,
            // });
        }
        setPrintLoding(false);
    };

    const handleClose = async () => {
    // debugger;
    // const SOStatusURL = `${SOStatus}CICode=${auth.CICode}&SOHD_ID=${soHdrId}`;
    // const statusRes = await Axios.get(SOStatusURL);
    // setOrderStatus(statusRes.data.Status);
    // const url = `${SALES_ORDER_URL}CICode=${auth.CICode}&strSalesOrderId=${soHdrId}&LangId=1`;
    // const response = await Axios.get(url);
    // setFilteredSalesDetails(response.data);
        setInvoicePopup(false);
        setInvoiceBtn(false);
    };
    useEffect(() => {
        printInvoiceCall();
    }, []);
    if(orientation!=="portrait-primary"){
        if (printLoding) {
            return <Loader />;
        } else {
            return (
                <Modal
                    className='print-popup'
                    size='lg'
                    centered
                    show={invoicePopup}
                    backdrop='static'
                    keyboard={false}
                    onHide={() => setInvoicePopup(false)}
                >
                    <Modal.Body>
                        <div id='printablediv'>
                            <PDFExport
                                fileName='InvoiceAnnexure'
                                paperSize='A4'
                                forcePageBreak='.page-break'
                                ref={pdfExportComponent}
                            >
                                {printInvoiceData?.length > 0 &&
                printInvoiceData.map((item, i) => {
                    return (
                        <div key={i}>
                            <div className='my-5 py-5'>
                                <div className=''>
                                    <div
                                        className='title'
                                        style={{
                                            textAlign: "center",
                                            padding: "4px 0px",
                                            margin: "18px",
                                        }}
                                    >
                                        <p
                                            style={{ fontSize: "10px", marginBottom: "10px" }}
                                        >
                              ANNEXURE 1 <br></br> List Of Credit Notes
                                        </p>
                                    </div>
                                    <div className='row'>
                                        <div className='col-6'>
                                            <div className='parent  parent-invoice-terms-6'>
                                                <div className='childOne  invoice-terms'>
                                                    <p>
                                                        <span
                                                            className='firstPara'
                                                            style={{ width: "35%" }}
                                                        >
                                      Ref. Invoice No.{" "}
                                                        </span>{" "}
                                    : {item.objHdr.lblinvref}
                                                    </p>
                                                    <p>
                                                        <span
                                                            className='firstPara'
                                                            style={{ width: "35%" }}
                                                        >
                                      Ref. Invoice Date{" "}
                                                        </span>{" "}
                                    : {item.objHdr.lblrefdate}
                                                    </p>
                                                    <p>
                                                        <span
                                                            className='firstPara'
                                                            style={{ width: "35%" }}
                                                        >
                                      Parent ID{" "}
                                                        </span>{" "}
                                    : {item.objHdr.lblCNCustParent}
                                                    </p>
                                                    <p>
                                                        <span
                                                            className='firstPara'
                                                            style={{ width: "35%" }}
                                                        >
                                      Customer Code{" "}
                                                        </span>{" "}
                                    : {item.objHdr.lblCNCustCode}
                                                    </p>
                                                    <p>
                                                        <span
                                                            className='firstPara'
                                                            style={{ width: "35%" }}
                                                        >
                                      Customer Name{" "}
                                                        </span>{" "}
                                    : {item.objHdr.lblCNCustName}
                                                    </p>
                                                    <p>
                                                        <span
                                                            className='firstPara'
                                                            style={{ width: "35%" }}
                                                        >
                                      Address Line 1{" "}
                                                        </span>{" "}
                                    : {item.objHdr.lblCNCustAdd1}
                                                    </p>
                                                    <p>
                                                        <span
                                                            className='firstPara'
                                                            style={{ width: "35%" }}
                                                        >
                                      Address Line 2{" "}
                                                        </span>{" "}
                                    : {item.objHdr.lblCNCustAdd2}
                                                    </p>
                                                    <p>
                                                        <span
                                                            className='firstPara'
                                                            style={{ width: "35%" }}
                                                        >
                                      Town{" "}
                                                        </span>{" "}
                                    :{item.objHdr.lblCNCustTown}
                                                    </p>
                                                    <p>
                                                        <span
                                                            className='firstPara'
                                                            style={{ width: "35%" }}
                                                        >
                                      State{" "}
                                                        </span>{" "}
                                    :{item.objHdr.lblCNCustState}
                                                    </p>
                                                    <p>
                                                        <span
                                                            className='firstPara'
                                                            style={{ width: "35%" }}
                                                        >
                                      PIN{" "}
                                                        </span>{" "}
                                    :{item.objHdr.lblCNCustPIN}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6'>
                                            <div className='parent  parent-invoice-terms-6'>
                                                <div className='childOne  invoice-terms'>
                                                    <p style={{ display: "flex" }}>
                                                        <span
                                                            className='firstPara'
                                                            style={{ width: "35%" }}
                                                        >
                                      Distributor Address{" "}
                                                        </span>{" "}
                                    :
                                                        <span
                                                            style={{
                                                                fontWeight: "normal",
                                                                width: "50%",
                                                            }}
                                                        >
                                                            {item.objHdr.lblDistAdd1}{" "}
                                                            {item.objHdr.lblDistAdd2}{" "}
                                                            {item.objHdr.lblDistAdd3}{" "}
                                                            {item.objHdr.lblDistAdd4}{" "}
                                                            {item.objHdr.lblDistName}
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='parent parent-invoice-table'>
                                        <table
                                            className='invoice-table-details'
                                            style={{ height: "auto", borderBottom: "none" }}
                                        >
                                            <thead>
                                                <tr className='table-row-border'>
                                                    <th style={{ width: "40px" }}>Sl_No.</th>
                                                    <th>CN Number</th>
                                                    <th>CN Date</th>
                                                    <th>Scheme Code</th>
                                                    <th>Scheme Description</th>
                                                    <th className='RightAlignTD'>CN Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {item.objDetl?.map((detail, i) => {
                                                    return (
                                                        <tr key={i}>
                                                            <>
                                                                <td style={{ width: "40px" }}>
                                                                    {detail?.SR_NO}
                                                                </td>
                                                                <td>{detail?.CN_NO}</td>
                                                                <td>{detail?.CN_DATE}</td>
                                                                <td>{detail?.SCHM_CODE}</td>
                                                                <td>{detail?.SCHM_DESC}</td>
                                                                <td className='RightAlignTD'>
                                                                    <p>
                                                                        <MdCurrencyRupee /> {detail?.CN_AMT}
                                                                    </p>
                                                                </td>
                                                            </>
                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                            <tfoot
                                                style={{
                                                    height: 200,
                                                }}
                                            ></tfoot>
                                        </table>
                                    </div>
                                    <div>
                                        <div className='row m-auto'>
                                            <div className='column'>
                                                <div className='rupeeBox'>
                                                    <p>
                                                        {/* eslint-disable-next-line */}
                                    <span>Distributor's Signature</span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className='column'>
                                                <div className='childOne  invoice-terms-one'>
                                                    <p style={{ textAlign: "right" }}>
                                                        <span className='me-2'>
                                      Total Credit Adjustment:
                                                        </span>
                                                        <MdCurrencyRupee />{" "}
                                                        {item.objHdr.lblTotCNAdj}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
                            </PDFExport>
                        </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <button className='btn btn-cancel-red me-3' onClick={handleClose}>
            Cancel
                        </button>
                        <button
                            className='btn btn-primary'
                            ref={printRef}
                            onClick={() => {
                                if (pdfExportComponent.current) {
                                    pdfExportComponent.current.save();
                                }
                                handleClose();
                            // printRef.current.save();
                            }}
                        >
            Download
                        </button>
                    </Modal.Footer>
                </Modal>
            );
        }}else{
        return(<PrintInvoicePopUp show={invoicePopup} hideShow = {setInvoicePopup} name = "Annexure Print Invoice"/>);
    }
};

export default AnnexurePrint;
