import React, { useState } from "react";
import { useEffect } from "react";
// import DataTable from "react-data-table-component";
// import Axios from "../../api/Axios";
import Loader from "../../Components/Loader";
import storage from "../../Services/Storage";
import { useCustMasterContext } from "../../context/CustomerMasterContext";
import { useNavigate } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
// import { Tag } from "primereact/tag";
import { Dropdown } from "primereact/dropdown";
import {
    createAPICustomerMastEndpoint,
    Endpoint,
} from "../../Services/CustomerMast/CustomerMastService";
// import { toast } from "react-toastify";


const ViewCustomerTable = () => {
    const nav = useNavigate();
    const { viewCustomerDetails, setCopyToShip,setEditView,} = useCustMasterContext();
    const auth = storage.getLocal("user");
    const [AllCustData, setAllCustData] = useState([]);
    const [custMasterLoader, setCustMasterLoader] = useState(false);
    const getAllCustomers = async () => {
        setCustMasterLoader(false);

        createAPICustomerMastEndpoint()
            .get(
                `${Endpoint.GetAllCustomer}?CICode=${auth.CICode}&CompId=${auth.CompId}`
            )
            .then((response) => {
                setAllCustData(response.data);
                setCustMasterLoader(true);
            })
            .catch(() => {
                setCustMasterLoader(true);
            });

        // const GetAllCustomer_URL = `CustomerMast/GetAllCustomer?CICode=${auth.CICode}&CompId=${auth.CompId}`;
        // try {
        //     const response = await Axios.get(GetAllCustomer_URL);
        //      
        //     setAllCustData(response.data);
        //     setCustMasterLoader(true);
        //      
        // } catch (e) {
        //      
        // }
    };
   

    useEffect(() => {
        getAllCustomers();
        setCopyToShip(false);
        setEditView(false);
    }, []);


    const [statuses] = useState(["Under Approval","Rejected","Billing Stopped","Approved","Re-submitted","Not Applicable","Re-Submitted(Smart)","Re-Submitted(CSD)","KYC Document Pending"]);

  
    const statusRowFilterTemplate = (options) => {
        return (
            <Dropdown value={options.value} options={statuses} onChange={(e) => options.filterApplyCallback(e.value)} placeholder="Select One" className="p-column-filter" showClear style={{ maxWidth: "12rem" }} />
        );
    };

    const renderAction = (rowData) => {
        return (
            <>
                <div className="action-icon">
                    <div className="d-inline" data-toggle="tooltip" data-placement="bottom" title="View/Edit Customer Details">
                        <button className="grid-icon-img" 
                            onClick={()=>{
                                viewCustomerDetails(rowData);
                                // if(rowData.MASK_KYC_STATUS=="Billing Stopped"){
                                //     toast.error(
                                //         "Billing has been stopped for this Customer.",
                                //         {
                                //             position: toast.POSITION.TOP_CENTER,
                                //             pauseOnFocusLoss: false,
                                //             closeButton: false,
                                //             autoClose: 2000,
                                //         }
                                //     );
                                // }
                                // else{
                                // console.log(rowData,"rowData");
                                nav("/masters/customermaster/details");
                                // setEditView(true);
                                // }
                            }}>
                            <img alt='InnoRise' width='20px' src={require("../../Assets/Images/ViewEdit.png")} />
                        </button>
                    </div>
                </div>
            </>
        );
    };

    if (!custMasterLoader) {
        return <Loader />;
    } else {
        return (
            <>
                <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="DataTable">
                        <DataTable
                            value={AllCustData}
                            dataKey='MASK_CUST_ID'
                            paginator
                            rows={10}
                            filterDisplay="row"
                            scrollable
                            rowsPerPageOptions={[10, 25, 50]}
                            scrollHeight="80vh"
                            showGridlines
                            stripedRows
                            globalFilterFields={["PROD_CODE", "DESCRIPTION"]} emptyMessage="No customers found."
                            tableStyle={{ minWidth: "60rem" }}>
                            <Column field="PARTY_CODE" header="Customer Code" filter filterMatchMode="contains" filterPlaceholder="Search Here..." style={{ minWidth: "12rem" }} />
                            <Column field="CUST_NAME" header="Customer Name" style={{ minWidth: "12rem" }} filterMatchMode="contains" filter filterPlaceholder="Search Here..." />
                            <Column field="MASK_CUST_UNIQUE_ID" header="Unique Code"  />
                            <Column field="MASK_GEOG_DESC" header="Geography Location" />
                            <Column field="MASK_KYC_STATUS" header="KYC Status" showFilterMenu={false} filter filterElement={statusRowFilterTemplate}  style={{ minWidth: "12rem" }} />
                            <Column field={renderAction} header="Action" />
                        </DataTable>
                    </div>
                </div>
            </>
        );
    }
};

export default ViewCustomerTable;
