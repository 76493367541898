import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import Axios from "../../api/Axios";
import { useGlobalContext1 } from "../../context/SalesDetailsContext";
import { useGlobalContext } from "../../context/SalesOrderContext";
import Modal from "react-bootstrap/Modal";
import Loader from "../../Components/Loader";
import "./Prints.scss";
import { toast } from "react-toastify";
import { PDFExport } from "@progress/kendo-react-pdf";
import storage from "../../Services/Storage";
import { MdCurrencyRupee } from "react-icons/md";
import PrintInvoicePopUp from "../../Components/PrintInvoicePopUp";
let GrandTotalAmount = [];
let mod = 0;
function ConvertNumberToWords(number1) {
    let words = new Array();
    words[0] = "";
    words[1] = "One";
    words[2] = "Two";
    words[3] = "Three";
    words[4] = "Four";
    words[5] = "Five";
    words[6] = "Six";
    words[7] = "Seven";
    words[8] = "Eight";
    words[9] = "Nine";
    words[10] = "Ten";
    words[11] = "Eleven";
    words[12] = "Twelve";
    words[13] = "Thirteen";
    words[14] = "Fourteen";
    words[15] = "Fifteen";
    words[16] = "Sixteen";
    words[17] = "Seventeen";
    words[18] = "Eighteen";
    words[19] = "Nineteen";
    words[20] = "Twenty";
    words[30] = "Thirty";
    words[40] = "Forty";
    words[50] = "Fifty";
    words[60] = "Sixty";
    words[70] = "Seventy";
    words[80] = "Eighty";
    words[90] = "Ninety";
    number1 = number1.toString();
    let atemp = number1.split(".");
    let number = atemp[0].split(",").join("");
    let n_length = number.length;
    let words_string = "";
    if (n_length <= 9) {
        let n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
        let received_n_array = new Array();
        for (let i1 = 0; i1 < n_length; i1++) {
            received_n_array[i1] = number.substr(i1, 1);
        }
        for (let i2 = 9 - n_length, j2 = 0; i2 < 9; i2++, j2++) {
            n_array[i2] = received_n_array[j2];
        }
        for (let i3 = 0, j3 = 1; i3 < 9; i3++, j3++) {
            if (i3 == 0 || i3 == 2 || i3 == 4 || i3 == 7) {
                if (n_array[i3] == 1) {
                    n_array[j3] = 10 + parseInt(n_array[j3]);
                    n_array[i3] = 0;
                }
            }
        }
        let value = "";
        for (let i4 = 0; i4 < 9; i4++) {
            if (i4 == 0 || i4 == 2 || i4 == 4 || i4 == 7) {
                value = n_array[i4] * 10;
            } else {
                value = n_array[i4];
            }
            if (value != 0) {
                words_string += words[value] + " ";
            }
            if (
                (i4 == 1 && value != 0) ||
                (i4 == 0 && value != 0 && n_array[i4 + 1] == 0)
            ) {
                words_string += "Crores ";
            }
            if (
                (i4 == 3 && value != 0) ||
                (i4 == 2 && value != 0 && n_array[i4 + 1] == 0)
            ) {
                words_string += "Lakhs ";
            }
            if (
                (i4 == 5 && value != 0) ||
                (i4 == 4 && value != 0 && n_array[i4 + 1] == 0)
            ) {
                words_string += "Thousand ";
            }
            if (
                i4 == 6 &&
                value != 0 &&
                n_array[i4 + 1] != 0 &&
                n_array[i4 + 2] != 0
            ) {
                words_string += "Hundred and ";
            } else if (i4 == 6 && value != 0) {
                words_string += "Hundred ";
            }
        }
        words_string = words_string.split("  ").join(" ");
    }
    return words_string + "Only";
}
const Print = (printonly) => {
    const auth = storage.getLocal("user");
    const SALES_ORDER_URL = "SalesOrder/GetProductDetails?";
    const SOStatus = "SalesOrder/GetSOStatus?";
    const { setInvoiceNo, soHdrId, setFilteredSalesDetails } =
        useGlobalContext1();
    const { id } = useParams();
    const PRINT_INVOICE = "Invoice/PrintInvoice";
    const [printInvoiceData, setPrintInvoiceData] = useState([]);
    const [printLoding, setPrintLoding] = useState(true);
    const {
        invoicePopup,
        setInvoicePopup,
        printInvoiceModalData,
        setInvoice,
        setInvoiceBtn,
        setOrderStatus,
        setPrintInvoiceModalData
    } = useGlobalContext1();
    const {orientation} = useGlobalContext();
    const handleClose = async () => {
        // debugger;
        const SOStatusURL = `${SOStatus}CICode=${auth.CICode}&SOHD_ID=${soHdrId}`;
        const statusRes = await Axios.get(SOStatusURL);
        setOrderStatus(statusRes.data.Status);
        const url = `${SALES_ORDER_URL}CICode=${auth.CICode}&strSalesOrderId=${soHdrId}&LangId=1`;
        const response = await Axios.get(url);
        setFilteredSalesDetails(response.data);
        setInvoicePopup(false);
        setInvoiceBtn(false);
    };
    const pageLoad = async () =>{
        const SOStatusURL = `${SOStatus}CICode=${auth.CICode}&SOHD_ID=${soHdrId}`;
        const statusRes = await Axios.get(SOStatusURL);
        setOrderStatus(statusRes.data.Status);
        const url = `${SALES_ORDER_URL}CICode=${auth.CICode}&strSalesOrderId=${soHdrId}&LangId=1`;
        const response = await Axios.get(url);
        setFilteredSalesDetails(response.data);
        setInvoiceBtn(false);
    };

    const printInvoiceCall = async () => {
        try {
            const obj = {
                listModelInvoicePrints: printInvoiceModalData
            };
            const printResponse = await Axios.post(
                PRINT_INVOICE,
                JSON.stringify(obj),
                {
                    headers: { "Content-Type": "application/json" },
                }
            );

            setPrintInvoiceData(printResponse.data);
            if (printResponse && printResponse.data.length > 0) {
                printResponse.data.forEach((responseData) => {
                    setInvoiceNo(responseData.clsLabelInvPrint.INV_NO1);
                    GrandTotalAmount.push(ConvertNumberToWords(
                        responseData.clsModelInvoicePrintDtl.TOTAL &&
                        responseData.clsModelInvoicePrintDtl.Total2 &&
                        responseData.clsModelInvoicePrintDtl.Total3 &&
                        responseData.clsModelInvoicePrintDtl.Total4 &&
                        responseData.clsModelInvoicePrintDtl.Total5 &&
                        responseData.clsModelInvoicePrintDtl.Total6
                    ));
                    setPrintLoding(false);
                    setInvoice(true);
                    setPrintInvoiceModalData([]);
                    mod = Math.ceil(responseData.dtProductList.length / 8);
                    // responseData.dtProductList = responseData.dtProductList.slice(8);

                });
                {
                    !printonly && toast.success("Invoice Created Successfully", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                }
            }
        } catch (error) {
            setPrintLoding(false);
            toast.error(error.response.data.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        }
    };

    let mod_array = Array.from(Array(mod + 1).keys()).slice(1);
    useEffect(() => {
        printInvoiceCall();
        pageLoad();
    }, [id]);

    const printRef = useRef(null);
    const pdfExportComponent = useRef(null);
    if(orientation!=="portrait-primary"){
        if (printLoding) {
            return <Loader />;
        } else {
            return (
                <Modal
                    className="print-popup"
                    show={invoicePopup}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    onHide={() => setInvoicePopup(false)}
                    backdrop="static"
                    keyboard={false}
                >
                    {/* <Modal.Header className="DashboardTodoListTitle" closeButton1>
                    <Modal.Title></Modal.Title>
                </Modal.Header> */}
                    <Modal.Body>
                        <div id="printablediv">
                            <PDFExport
                                fileName="Invoice"
                                paperSize="A4"
                                forcePageBreak=".page-break"
                                ref={pdfExportComponent}
                            >

                                <div className="MobileView">
                                    {printInvoiceData.map((newItem, index) => {
                                    // debugger
                                        let actualData = [];
                                        //eslint-disable-next-line
                                    actualData = printInvoiceData[index];
                                        return (
                                            <>
                                                {mod_array.map((val, i) => {
                                                    return (
                                                        <div key={i} className={i !== 0 && "page-break"}>
                                                            <>
                                                                <div className="MobileViewInvoice py-2 my-2">
                                                                    <div className="bgimg" id="dv1" >
                                                                        <div className="col-12 mb-4 mt-3">
                                                                            <div style={{ float: "inline-end" }}>
                                                                                <img alt="InnoRise" src={require("../../Assets/Images/Castrol_logo.png")} className="pe-3" crossOrigin="anonymous" style={{ width: "90px" }} />
                                                                            </div>
                                                                            <p className="text-center" style={{ fontSize: "9px", marginBottom: "0px" }}><b>TAX INVOICE</b></p>
                                                                        </div>
                                                                        <table cellSpacing="0" cellPadding="0" style={{ border: "none", marginBottom: "10px", width: "100%" }}>
                                                                            <tr>
                                                                                <td style={{ width: "20px" }}></td>
                                                                                <td style={{ width: "300px", height: "15px", verticalAlign: "top" }}>
                                                                                    <b>
                                                                                        <p id="Label7" style={{ marginBottom: "2px", fontSize: "9px" }}>{actualData.clsLabelInvPrint
                                                                                            .DIST_NAME}</p>
                                                                                    </b>
                                                                                    <p id="lblrilcustcode" style={{ width: "150px" }}>
                                                                                        {actualData.clsLabelInvPrint
                                                                                            .DIST_ADD1}{" "}{actualData.clsLabelInvPrint
                                                                                            .DIST_ADD2}{" "}<br />{actualData.clsLabelInvPrint
                                                                                            .DIST_ADD3}{" "}{actualData.clsLabelInvPrint
                                                                                            .DIST_ADD4}{" "} PIN- {actualData.clsLabelInvPrint
                                                                                            .DIST_PIN}<br />Phone :{actualData.clsLabelInvPrint
                                                                                            .DIST_PH_NO}
                                                                                    </p>
                                                                                    <p style={{ marginTop: "10px" }}><b>{actualData.clsLabelInvPrint
                                                                                        .MOP === "Cash Disc" ? actualData.clsLabelInvPrint
                                                                                            .Discount : ""}</b></p>
                                                                                </td>
                                                                                <td style={{ width: "350px", height: "15px", verticalAlign: "top" }}>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b><span id="Label2">Invoice No</span></b>
                                                                                        </td>
                                                                                        <td style={{ paddingLeft: "6px" }}>: <span>{actualData.clsLabelInvPrint.INV_NO1}</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b><span id="Label2">Invoice Date</span></b>
                                                                                        </td>
                                                                                        <td style={{ paddingLeft: "6px" }}>: <span>{
                                                                                            actualData.clsLabelInvPrint
                                                                                                .INV_DATE1
                                                                                        }</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b><span id="Label2">S.O / P.O No.</span></b>
                                                                                        </td>
                                                                                        <td style={{ paddingLeft: "6px" }}>: <span>{actualData.clsLabelInvPrint.PO_NO}</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b><span id="Label2">S.O / P.O Date</span></b>
                                                                                        </td>
                                                                                        <td style={{ paddingLeft: "6px" }}>: <span>{actualData.clsLabelInvPrint.PO_DATE}</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b><span id="Label2">Mode of Payment</span></b>
                                                                                        </td>
                                                                                        <td style={{ paddingLeft: "6px" }}>: <span>{actualData.clsLabelInvPrint.MOP}</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b><span id="Label2">Payment Terms</span></b>
                                                                                        </td>
                                                                                        <td style={{ paddingLeft: "6px" }}>: <span>{
                                                                                            actualData.clsLabelInvPrint
                                                                                                .PaymentTerm
                                                                                        }</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b><span id="Label2">Distributor GSTN</span></b>
                                                                                        </td>
                                                                                        <td style={{ paddingLeft: "6px" }}>: <span>{
                                                                                            actualData.clsLabelInvPrint
                                                                                                .DistGST1
                                                                                        }</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <b><span id="Label2">Distributor PAN No</span></b>
                                                                                        </td>
                                                                                        <td style={{ paddingLeft: "6px" }}>: <span>{
                                                                                            actualData.clsLabelInvPrint
                                                                                                .DistPanNo
                                                                                        }</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </td>
                                                                            </tr>
                                                                        </table>

                                                                        <table cellSpacing="0" cellPadding="0" style={{ border: "none" }}>
                                                                            <tr>
                                                                                <td style={{ width: "22px" }}></td>
                                                                                <td style={{ width: "354px", height: "15px", verticalAlign: "top" }}>
                                                                                    <b>
                                                                                        <p id="Label7" style={{ fontSize: "8px", marginBottom: "2px" }}>Customer Address :</p>
                                                                                    </b>
                                                                                    <p id="lblrilcustcode" >
                                                                                        {
                                                                                            actualData.clsLabelInvPrint
                                                                                                .CustCode
                                                                                        }{" "} &nbsp; &nbsp;
                                                                                    PAN : {
                                                                                            actualData.clsLabelInvPrint
                                                                                                .CustPan1
                                                                                        }
                                                                                        <br></br>
                                                                                        <b> {
                                                                                            actualData.clsLabelInvPrint
                                                                                                .CustName
                                                                                        }{" "}</b>
                                                                                        <br></br>
                                                                                        {
                                                                                            actualData.clsLabelInvPrint
                                                                                                .BillAdd1
                                                                                        }
                                                                                        {
                                                                                            actualData.clsLabelInvPrint
                                                                                                .BillAdd2
                                                                                        }
                                                                                        <br></br>

                                                                                        {
                                                                                            actualData.clsLabelInvPrint
                                                                                                .BillTown
                                                                                        }
                                                                                        {
                                                                                            actualData.clsLabelInvPrint
                                                                                                .BillState
                                                                                        }{" "}
                                                                                        <br></br>
                                                                                    Customer GSTN : {
                                                                                            actualData.clsLabelInvPrint
                                                                                                .CustGST
                                                                                        }
                                                                                    </p>
                                                                                </td>
                                                                                <td style={{ width: "354px", height: "15px", verticalAlign: "top" }}>
                                                                                    <b>
                                                                                        <p id="Label8" style={{ fontSize: "8px", marginBottom: "2px" }}>Delivery Address : </p>
                                                                                    </b>
                                                                                    <p id="lblShipToName1" >
                                                                                        {
                                                                                            actualData.clsLabelInvPrint
                                                                                                .ShipAdd1
                                                                                        }
                                                                                        {
                                                                                            actualData.clsLabelInvPrint
                                                                                                .ShipAdd2
                                                                                        }
                                                                                    ,&nbsp;
                                                                                        <br></br>
                                                                                        {
                                                                                            actualData.clsLabelInvPrint
                                                                                                .ShipAdd3
                                                                                        }
                                                                                    ,&nbsp;
                                                                                        {
                                                                                            actualData.clsLabelInvPrint
                                                                                                .ShipAdd4
                                                                                        }
                                                                                    ,&nbsp;
                                                                                        <br></br>
                                                                                        {
                                                                                            actualData.clsLabelInvPrint
                                                                                                .ShipPin
                                                                                        }
                                                                                    ,&nbsp;
                                                                                        <br></br>
                                                                                        {
                                                                                            actualData.clsLabelInvPrint
                                                                                                .CShipPhone
                                                                                        }
                                                                                    </p>
                                                                                </td>
                                                                            </tr>
                                                                        </table>

                                                                        <div style={{ padding: "5px", minHeight: "305px" , margin: "0px 10px"}}>
                                                                            <div style={{ minHeight: "300px", display: "inline-block", width: "100%", position: "relative", border: "1px solid #000", padding: "2px" }} >
                                                                                <div id="tbl01">
                                                                                    <table id="tblDetl" border="0" cellSpacing="0" cellPadding="0" width="100%">
                                                                                        <tbody>
                                                                                            <tr className="headerClass" style={{ borderBottom: "1px solid #000" }}>
                                                                                                <td align="left" style={{ width: "20px" }}><span style={{ textAlign: "left" }}>Sr<br></br>No</span></td>
                                                                                                <td align="left" style={{ width: "100px" }}><span style={{ textAlign: "left" }}>Product Pack Description</span></td>
                                                                                                <td align="right"><span style={{ textAlign: "right" }}>HSN</span></td>
                                                                                                <td align="right"><span style={{ textAlign: "right" }}>Rate Per<br></br>Pack</span></td>
                                                                                                <td align="right"><span style={{ textAlign: "right" }}>MRP</span></td>
                                                                                                <td align="right"><span style={{ textAlign: "right" }}>No. of<br></br>Packs</span></td>
                                                                                                <td align="right"><span style={{ textAlign: "right" }}>Quantity<br></br>(EA/Kg/Ltrs)</span></td>
                                                                                                <td align="right"><span style={{ textAlign: "right" }}>Scheme<br></br>Disc/Rebate</span></td>
                                                                                                <td align="right"><span style={{ textAlign: "right" }}>Coupon<br></br>Scheme Disc</span></td>
                                                                                                <td align="right"><span style={{ textAlign: "right" }}>Taxable<br></br>Value</span></td>
                                                                                                <td align="right"><span style={{ textAlign: "right" }}>
                                                                                                    {actualData.dtProductList[0]
                                                                                                        .CGST_AMT > 0
                                                                                                        ? "CGST Rs."
                                                                                                        : actualData.dtProductList[0]
                                                                                                            .UTGST_AMT > 0
                                                                                                            ? "UTGST Rs."
                                                                                                            : actualData.dtProductList[0]
                                                                                                                .IGST_AMT
                                                                                                                ? "IGST Rs."
                                                                                                                : ""}
                                                                                                    <br></br>Rate%
                                                                                                </span>
                                                                                                </td>
                                                                                                <td align="right"><span style={{ textAlign: "right" }}>SGST&nbsp;Rs.<br></br>Rate%</span></td>
                                                                                            </tr>
                                                                                            {i == 0 ? actualData.dtProductList.map((val, index) => (
                                                                                                <tr style={{ height: "25px" }} key={index}>
                                                                                                    {index < 8 && (
                                                                                                        <>
                                                                                                            <td><span style={{ width: "20px", textAlign: "left" }}>{val.ROWNUM || val.SR_NO}</span></td>
                                                                                                            <td><span style={{ width: "100px", textAlign: "left" }}>{val?.PRODUCT}</span></td>
                                                                                                            <td align="right"><span style={{ width: "50px", textAlign: "right" }}>{Number(val?.HSN)}</span></td>
                                                                                                            <td align="right"><span style={{ width: "42px", textAlign: "right" }}>{Number(val?.RATE1).toFixed(2)}</span></td>
                                                                                                            <td align="right"><span style={{ width: "45px", textAlign: "right" }}>{Number(val?.MRP).toFixed(2)}</span></td>
                                                                                                            <td align="right"><span style={{ width: "30px", textAlign: "right" }}>{Number(val?.PACK_QTY)}</span></td>
                                                                                                            <td align="right"><span style={{ width: "40px", textAlign: "right" }}>{Number(val?.LTR_QTY)}</span></td>
                                                                                                            <td align="right"><span style={{ width: "40px", textAlign: "right" }}>{Number(val?.EXCLD_SCHM_DISC_VAL).toFixed(2)}</span></td>
                                                                                                            <td align="right"><span style={{ width: "44px", textAlign: "right" }}>{Number(val?.COUP_DISC).toFixed(2)}</span></td>
                                                                                                            <td align="right"><span style={{ width: "62px", textAlign: "right" }}>
                                                                                                                {actualData
                                                                                                                    .clsModelInvoicePrint
                                                                                                                    .INVTYPE === "N"
                                                                                                                    ? Number(val.AMOUNT1).toFixed(2)
                                                                                                                    : Number(val.AMOUNT).toFixed(2)}
                                                                                                            </span></td>
                                                                                                            <td align="right"><span style={{ width: "50px", textAlign: "right" }}>
                                                                                                                {Number(val.CGST_AMT ||
                                                                                                                val.UTGST_AMT ||
                                                                                                                val.IGST_AMT).toFixed(2)}
                                                                                                                <p className="mb-0">
                                                                                                                    {Number(
                                                                                                                        val.CGST_VALUE ||
                                                                                                                    val.UTGST_VALUE ||
                                                                                                                    val.IGST_VALUE
                                                                                                                    ).toFixed(2)}
                                                                                                                </p>
                                                                                                            </span></td>
                                                                                                            <td align="right"><span style={{ width: "50px", textAlign: "right" }}>
                                                                                                                {Number(val.SGST_AMT).toFixed(2)}
                                                                                                                <p className="mb-0">
                                                                                                                    {Number(val.SGST_VALUE).toFixed(2)}
                                                                                                                </p>
                                                                                                            </span></td>
                                                                                                        </>
                                                                                                    )}

                                                                                                </tr>
                                                                                            )) : actualData.dtProductList
                                                                                                .slice(i * 8).map((val, index) => (
                                                                                                    <tr style={{ height: "25px" }} key={index}>
                                                                                                        {index < 8 && (
                                                                                                            <>
                                                                                                                <td><span style={{ width: "20px", textAlign: "left" }}>{val.ROWNUM || val.SR_NO}</span></td>
                                                                                                                <td><span style={{ width: "100px", textAlign: "left" }}>{val?.PRODUCT}</span></td>
                                                                                                                <td align="right"><span style={{ width: "50px", textAlign: "right" }}>{Number(val?.HSN)}</span></td>
                                                                                                                <td align="right"><span style={{ width: "42px", textAlign: "right" }}>{Number(val?.RATE1).toFixed(2)}</span></td>
                                                                                                                <td align="right"><span style={{ width: "45px", textAlign: "right" }}>{Number(val?.MRP).toFixed(2)}</span></td>
                                                                                                                <td align="right"><span style={{ width: "30px", textAlign: "right" }}>{Number(val?.PACK_QTY)}</span></td>
                                                                                                                <td align="right"><span style={{ width: "40px", textAlign: "right" }}>{Number(val?.LTR_QTY)}</span></td>
                                                                                                                <td align="right"><span style={{ width: "40px", textAlign: "right" }}>{Number(val?.EXCLD_SCHM_DISC_VAL).toFixed(2)}</span></td>
                                                                                                                <td align="right"><span style={{ width: "44px", textAlign: "right" }}>{Number(val?.COUP_DISC).toFixed(2)}</span></td>
                                                                                                                <td align="right"><span style={{ width: "62px", textAlign: "right" }}>
                                                                                                                    {actualData
                                                                                                                        .clsModelInvoicePrint
                                                                                                                        .INVTYPE === "N"
                                                                                                                        ? Number(val.AMOUNT1).toFixed(2)
                                                                                                                        : Number(val.AMOUNT).toFixed(2)}
                                                                                                                </span></td>
                                                                                                                <td align="right"><span style={{ width: "50px", textAlign: "right" }}>
                                                                                                                    {Number(val.CGST_AMT ||
                                                                                                                    val.UTGST_AMT ||
                                                                                                                    val.IGST_AMT).toFixed(2)}
                                                                                                                    <p className="mb-0">
                                                                                                                        {Number(
                                                                                                                            val.CGST_VALUE ||
                                                                                                                        val.UTGST_VALUE ||
                                                                                                                        val.IGST_VALUE
                                                                                                                        ).toFixed(2)}
                                                                                                                    </p>
                                                                                                                </span></td>
                                                                                                                <td align="right"><span style={{ width: "50px", textAlign: "right" }}>
                                                                                                                    {Number(val.SGST_AMT).toFixed(2)}
                                                                                                                    <p className="mb-0">
                                                                                                                        {Number(val.SGST_VALUE).toFixed(2)}
                                                                                                                    </p>
                                                                                                                </span></td>
                                                                                                            </>
                                                                                                        )}
                                                                                                    </tr>
                                                                                                ))}

                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div>
                                                                            <table border="0" cellSpacing="0" cellPadding="4" style={{ margin: "0px 20px" }}>
                                                                                <tbody><tr>
                                                                                    <td align="center" valign="top" style={{ width: "212px" }}>
                                                                                        <b>
                                                                                            {/* eslint-disable-next-line */}
                                                                                        <span id="Label9">Receiver's Signature Date &amp; Stamp</span></b>
                                                                                    </td>
                                                                                    <td align="right" style={{ width: "198px" }}>
                                                                                        <b>
                                                                                            <span id="Label10">Total</span></b>
                                                                                    </td>
                                                                                    <td align="right" style={{ width: "27px" }}>
                                                                                        <span id="lblSumQty">{i == 0
                                                                                            ? actualData
                                                                                                .clsModelInvoicePrintDtl.SumQty
                                                                                            : i == 1
                                                                                                ? actualData
                                                                                                    .clsModelInvoicePrintDtl.SumQtyP2
                                                                                                : i == 2
                                                                                                    ? actualData
                                                                                                        .clsModelInvoicePrintDtl.SumQtyP3
                                                                                                    : i == 3
                                                                                                        ? actualData
                                                                                                            .clsModelInvoicePrintDtl.SumQtyP4
                                                                                                        : i == 4
                                                                                                            ? actualData
                                                                                                                .clsModelInvoicePrintDtl.SumQtyP5
                                                                                                            : i == 5
                                                                                                                ? actualData
                                                                                                                    .clsModelInvoicePrintDtl.SumQtyP6
                                                                                                                : null}</span>
                                                                                    </td>
                                                                                    <td align="right" style={{ width: "70px" }}>
                                                                                        <span id="lblSumVol">{//Math.round(
                                                                                            (i == 0
                                                                                                ? actualData
                                                                                                    .clsModelInvoicePrintDtl.SumVol.toFixed(2)
                                                                                                : i == 1
                                                                                                    ? actualData
                                                                                                        .clsModelInvoicePrintDtl.SumVolP2.toFixed(2)
                                                                                                    : i == 2
                                                                                                        ? actualData
                                                                                                            .clsModelInvoicePrintDtl.SumVolP3.toFixed(2)
                                                                                                        : i == 3
                                                                                                            ? actualData
                                                                                                                .clsModelInvoicePrintDtl.SumVolP4.toFixed(2)
                                                                                                            : i == 4
                                                                                                                ? actualData
                                                                                                                    .clsModelInvoicePrintDtl.SumVolP5.toFixed(2)
                                                                                                                : i == 5
                                                                                                                    ? actualData
                                                                                                                        .clsModelInvoicePrintDtl.SumVolP6.toFixed(2)
                                                                                                                    : null) //* 100
                                                                                        //) / 100
                                                                                        }</span>
                                                                                    </td>
                                                                                    <td align="right" style={{ width: "75px" }}>
                                                                                        <span id="lblSumScheme">{//Math.round(
                                                                                            (i == 0
                                                                                                ? actualData.clsModelInvoicePrintDtl.SchmDisc.toFixed(
                                                                                                    2
                                                                                                )
                                                                                                : i == 1
                                                                                                    ? actualData.clsModelInvoicePrintDtl.SchmDiscP2.toFixed(
                                                                                                        2
                                                                                                    )
                                                                                                    : i == 2
                                                                                                        ? actualData.clsModelInvoicePrintDtl.SchmDiscP3.toFixed(
                                                                                                            2
                                                                                                        )
                                                                                                        : i == 3
                                                                                                            ? actualData.clsModelInvoicePrintDtl.SchmDiscP4.toFixed(
                                                                                                                2
                                                                                                            )
                                                                                                            : i == 4
                                                                                                                ? actualData.clsModelInvoicePrintDtl.SchmDiscP5.toFixed(
                                                                                                                    2
                                                                                                                )
                                                                                                                : i == 5
                                                                                                                    ? actualData.clsModelInvoicePrintDtl.SchmDiscP6.toFixed(
                                                                                                                        2
                                                                                                                    )
                                                                                                                    : null) //* 100
                                                                                        // ) / 100
                                                                                        }</span>
                                                                                    </td>
                                                                                    <td align="right" style={{ width: "70px" }}>
                                                                                        <span id="lblSumCoupon">{//Math.round(
                                                                                            (i == 0
                                                                                                ? actualData
                                                                                                    .clsModelInvoicePrintDtl.CouponDisc
                                                                                                : i == 1
                                                                                                    ? actualData
                                                                                                        .clsModelInvoicePrintDtl
                                                                                                        .CouponDiscP2
                                                                                                    : i == 2
                                                                                                        ? actualData
                                                                                                            .clsModelInvoicePrintDtl
                                                                                                            .CouponDiscP3
                                                                                                        : i == 3
                                                                                                            ? actualData
                                                                                                                .clsModelInvoicePrintDtl
                                                                                                                .CouponDiscP4
                                                                                                            : i == 4
                                                                                                                ? actualData
                                                                                                                    .clsModelInvoicePrintDtl
                                                                                                                    .CouponDiscP5
                                                                                                                : i == 5
                                                                                                                    ? actualData
                                                                                                                        .clsModelInvoicePrintDtl
                                                                                                                        .CouponDiscP6
                                                                                                                    : null) //* 100
                                                                                        //) / 100
                                                                                        }</span>
                                                                                    </td>
                                                                                    <td align="right" style={{ width: "70px" }}>
                                                                                        <span id="lblSumAmt">{//Math.round(
                                                                                            (i == 0
                                                                                                ? actualData.clsModelInvoicePrintDtl.SumAmt.toFixed(
                                                                                                    2
                                                                                                )
                                                                                                : i == 1
                                                                                                    ? actualData.clsModelInvoicePrintDtl.SumAmtP2.toFixed(
                                                                                                        2
                                                                                                    )
                                                                                                    : i == 2
                                                                                                        ? actualData.clsModelInvoicePrintDtl.SumAmtP3.toFixed(
                                                                                                            2
                                                                                                        )
                                                                                                        : i == 3
                                                                                                            ? actualData.clsModelInvoicePrintDtl.SumAmtP4.toFixed(
                                                                                                                2
                                                                                                            )
                                                                                                            : i == 4
                                                                                                                ? actualData.clsModelInvoicePrintDtl.SumAmtP5.toFixed(
                                                                                                                    2
                                                                                                                )
                                                                                                                : i == 5
                                                                                                                    ? actualData.clsModelInvoicePrintDtl.SumAmtP6.toFixed(
                                                                                                                        2
                                                                                                                    )
                                                                                                                    : null) //* 100
                                                                                        //) / 100
                                                                                        }</span>
                                                                                    </td>
                                                                                    <td align="right" style={{ width: "60px" }}>
                                                                                        <span id="lblSumSGST">{//Math.round(
                                                                                            (i == 0
                                                                                                ? actualData
                                                                                                    .clsModelInvoicePrintDtl.SumCGST.toFixed(2) || actualData
                                                                                                    .clsModelInvoicePrintDtl.SumIGST.toFixed(2) || actualData
                                                                                                    .clsModelInvoicePrintDtl.SumSGST.toFixed(2)
                                                                                                : i == 1
                                                                                                    ? actualData
                                                                                                        .clsModelInvoicePrintDtl.SumCGSTP2.toFixed(2) || actualData
                                                                                                        .clsModelInvoicePrintDtl.SumSGSTP2.toFixed(2) || actualData
                                                                                                        .clsModelInvoicePrintDtl.SumIGSTP2.toFixed(2)
                                                                                                    : i == 2
                                                                                                        ? actualData
                                                                                                            .clsModelInvoicePrintDtl.SumCGSTP3.toFixed(2) || actualData
                                                                                                            .clsModelInvoicePrintDtl.SumSGSTP3.toFixed(2) || actualData
                                                                                                            .clsModelInvoicePrintDtl.SumIGSTP3.toFixed(2)
                                                                                                        : i == 3
                                                                                                            ? actualData
                                                                                                                .clsModelInvoicePrintDtl.SumCGSTP4.toFixed(2) || actualData
                                                                                                                .clsModelInvoicePrintDtl.SumSGSTP4.toFixed(2) || actualData
                                                                                                                .clsModelInvoicePrintDtl.SumIGSTP4.toFixed(2)
                                                                                                            : i == 4
                                                                                                                ? actualData
                                                                                                                    .clsModelInvoicePrintDtl.SumCGSTP5.toFixed(2) || actualData
                                                                                                                    .clsModelInvoicePrintDtl.SumSGSTP5.toFixed(2) || actualData
                                                                                                                    .clsModelInvoicePrintDtl.SumIGSTP5.toFixed(2)
                                                                                                                : i == 5
                                                                                                                    ? actualData
                                                                                                                        .clsModelInvoicePrintDtl.SumCGSTP6.toFixed(2) || actualData
                                                                                                                        .clsModelInvoicePrintDtl.SumSGSTP6.toFixed(2) || actualData
                                                                                                                        .clsModelInvoicePrintDtl.SumIGSTP6.toFixed(2)
                                                                                                                    : null) //* 100
                                                                                        //) / 100
                                                                                        }</span>
                                                                                        {/* <span id="lblSumIGST" className=".grayTD"></span>
                                                                                    <span id="lblSumUTGST" className=".grayTD"></span> */}
                                                                                    </td>
                                                                                    <td align="right" style={{ width: "57px" }}>
                                                                                        <span id="lblSumCGST">{//Math.round(
                                                                                            (i == 0
                                                                                                ? actualData
                                                                                                    .clsModelInvoicePrintDtl.SumSGST.toFixed(2)
                                                                                                : i == 1
                                                                                                    ? actualData
                                                                                                        .clsModelInvoicePrintDtl.SumSGSTP2.toFixed(2)
                                                                                                    : i == 2
                                                                                                        ? actualData
                                                                                                            .clsModelInvoicePrintDtl.SumSGSTP3.toFixed(2)
                                                                                                        : i == 3
                                                                                                            ? actualData
                                                                                                                .clsModelInvoicePrintDtl.SumSGSTP4.toFixed(2)
                                                                                                            : i == 4
                                                                                                                ? actualData
                                                                                                                    .clsModelInvoicePrintDtl.SumSGSTP5.toFixed(2)
                                                                                                                : i == 5
                                                                                                                    ? actualData
                                                                                                                        .clsModelInvoicePrintDtl.SumSGSTP6.toFixed(2)
                                                                                                                    : null) //* 100
                                                                                        //) / 100
                                                                                        }</span>
                                                                                    </td>
                                                                                </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div style={{ padding: "5px 10px" , margin: "0px 20px" }}>
                                                                        {i == mod_array.length - 1 ?
                                                                            <table border="0" cellSpacing="0" cellPadding="0" style={{ lineHeight: "16px" }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ width: "421px" }}></td>
                                                                                        <td style={{ width: "190px", textAlign: "right" }}>
                                                                                            <b>
                                                                                                <span id="Label11">Gross Amount</span></b>
                                                                                        </td>
                                                                                        <td align="right" style={{ width: "56px" }}>
                                                                                            <span id="lblGrossAmt"><MdCurrencyRupee />
                                                                                                {actualData
                                                                                                    .clsModelInvoicePrintDtl.GrossAmt &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl
                                                                                                    .GrossAmtP2 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl
                                                                                                    .GrossAmtP3 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl
                                                                                                    .GrossAmtP4 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl
                                                                                                    .GrossAmtP5 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl.GrossAmtP6}
                                                                                            </span>
                                                                                        </td>

                                                                                        {actualData.clsLabelInvPrint
                                                                                            .CrdNo1P1 ? (
                                                                                                <td rowSpan="6" valign="top" align="center" className="cnTdWidth" style={{ paddingLeft: "10px" , width: "100px" }}>
                                                                                                    <p style={{ fontSize: "5px", textAlign: "center", lineHeight: "10px", margin: "0px" , border: "1px solid #000" , padding: "1px"}}>
                                                                                                        <b>CN Details</b>
                                                                                                        <p className="mb-0">
                                                                                                            <b>
                                                                                                                {actualData
                                                                                                                    .clsLabelInvPrint.CrdNo1P1 !==
                                                                                                            null && "No:"}
                                                                                                            </b>
                                                                                                            {actualData
                                                                                                                .clsLabelInvPrint.CrdNo1P1 ?? ""}
                                                                                                        </p>
                                                                                                        <p className="mb-0">
                                                                                                            <b>
                                                                                                                {actualData
                                                                                                                    .clsLabelInvPrint.CrdNo2P1 !==
                                                                                                            null && "No:"}
                                                                                                            </b>
                                                                                                            {actualData
                                                                                                                .clsLabelInvPrint.CrdNo2P1 ?? ""}
                                                                                                        </p>
                                                                                                        <p className="mb-0">
                                                                                                            <b>
                                                                                                                {actualData
                                                                                                                    .clsLabelInvPrint.CrdNo3P1 !==
                                                                                                            null && "No:"}
                                                                                                            </b>
                                                                                                            {actualData
                                                                                                                .clsLabelInvPrint.CrdNo3P1 ?? ""}
                                                                                                        </p>
                                                                                                        <p className="mb-0">
                                                                                                            <b>
                                                                                                                {actualData
                                                                                                                    .clsLabelInvPrint.CrdNo4P1 !==
                                                                                                            null && "No:"}
                                                                                                            </b>
                                                                                                            {actualData
                                                                                                                .clsLabelInvPrint.CrdNo4P1 ?? ""}
                                                                                                        </p>
                                                                                                        <p className="mb-0">
                                                                                                            <b>
                                                                                                                {actualData
                                                                                                                    .clsLabelInvPrint.CrdNo5P1 !==
                                                                                                            null && "No:"}
                                                                                                            </b>
                                                                                                            {actualData
                                                                                                                .clsLabelInvPrint.CrdNo5P1 ?? ""}
                                                                                                        </p>
                                                                                                    </p>
                                                                                                </td>

                                                                                            ) : null}
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td style={{ textAlign: "right" }}>
                                                                                            <b>
                                                                                                <span id="Label12">Other Discount</span></b>
                                                                                        </td>
                                                                                        <td align="right">
                                                                                            <span id="lblOthDisc"><MdCurrencyRupee />
                                                                                                {actualData
                                                                                                    .clsModelInvoicePrintDtl.OthDisc &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl
                                                                                                    .OthDiscP2 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl
                                                                                                    .OthDiscP3 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl
                                                                                                    .OthDiscP4 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl
                                                                                                    .OthDiscP5 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl.OthDiscP6}</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td valign="top">
                                                                                            <b>
                                                                                                <span id="Label53">Scheme Benefit (inclusive of GST) = Rs.</span></b>
                                                                                        &nbsp;
                                                                                            <span id="lblSchemeBenefit" style={{ paddingLeft: "15px" }}><MdCurrencyRupee />
                                                                                                {
                                                                                                    actualData
                                                                                                        .clsModelInvoicePrintDtl
                                                                                                        .SumSchemeBenefit.toFixed(2)
                                                                                                }</span>
                                                                                        </td>
                                                                                        <td style={{ textAlign: "right" }}>
                                                                                            <b>
                                                                                                <span id="Label13">Total Adjusted Amount</span></b>
                                                                                        </td>
                                                                                        <td align="right">
                                                                                            <span id="lblAdjAmt"><MdCurrencyRupee />
                                                                                                {actualData
                                                                                                    .clsModelInvoicePrintDtl.AdjAmt &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl.AdjAmtP2 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl.AdjAmtP3 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl.AdjAmtP4 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl.AdjAmtP5 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl.AdjAmtP6}</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td valign="top">
                                                                                            <b>
                                                                                                <span id="Label14">Amount Payable (in Words)</span></b>
                                                                                        </td>
                                                                                        <td align="right"><b>
                                                                                            <span id="Label15">GST</span></b></td>
                                                                                        <td align="right">
                                                                                            <span id="lblTotalVat"><MdCurrencyRupee />
                                                                                                {Number(
                                                                                                    (actualData
                                                                                                        .clsModelInvoicePrintDtl.TotalVat &&
                                                                                                    actualData
                                                                                                        .clsModelInvoicePrintDtl
                                                                                                        .TotalVatP2 &&
                                                                                                    actualData
                                                                                                        .clsModelInvoicePrintDtl
                                                                                                        .TotalVatP3 &&
                                                                                                    actualData
                                                                                                        .clsModelInvoicePrintDtl
                                                                                                        .TotalVatP4 &&
                                                                                                    actualData
                                                                                                        .clsModelInvoicePrintDtl
                                                                                                        .TotalVatP5 &&
                                                                                                    actualData
                                                                                                        .clsModelInvoicePrintDtl
                                                                                                        .TotalVatP6)).toFixed(2).toLocaleString("en-US")}</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td rowSpan="4" valign="top">
                                                                                            <b>
                                                                                                <span id="Label16" className="lblRs">Rupees : &nbsp;</span></b><span id="lblAmtInWord" className="amtWord">{actualData.clsModelInvoicePrintDtl.AmtInWord}</span>
                                                                                            <br></br>
                                                                                            <br></br>
                                                                                            <div style={{ border: "1px solid #333", paddingRight: "10px", paddingLeft: "4px", display: "inline-block", fontWeight: "bold", fontSize: "5px" }}>
                                                                                                <span id="Label58">Customer Service Toll Free No:1800222100/18002098100</span>
                                                                                            </div>
                                                                                        </td>

                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ textAlign: "right" }}>
                                                                                            <b>
                                                                                                <span id="Label55">Grand Total</span></b>
                                                                                        </td>
                                                                                        <td align="right">
                                                                                            <span id="lblTotal"><MdCurrencyRupee />
                                                                                                {(
                                                                                                    actualData
                                                                                                        .clsModelInvoicePrintDtl.TOTAL &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl.Total2 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl.Total3 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl.Total4 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl.Total5 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl.Total6
                                                                                                )
                                                                                                    .toFixed(2)
                                                                                                    .toLocaleString("en-US")}</span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr style={{ borderTop: "1px solid #000" }}>
                                                                                        <td className="borderTop txtRgt" style={{ textAlign: "right" }}>
                                                                                            <b>
                                                                                                <span id="LblCrdAdj1">Credit Adjustment</span></b>
                                                                                        </td>
                                                                                        <td className="borderTop crdPadd txtRgt" style={{ textAlign: "right" }}>
                                                                                            <span id="LblCrdAdjVal1"><MdCurrencyRupee />
                                                                                                {
                                                                                                    actualData
                                                                                                        .clsModelInvoicePrintDtl.CrdAdjVal1
                                                                                                }</span>
                                                                                        </td>

                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="txtRgt" style={{ textAlign: "right" }}>
                                                                                            <b>
                                                                                                <span id="Label17">Amount Payable</span></b>
                                                                                        </td>
                                                                                        {/* <td>&nbsp;</td> */}
                                                                                        <td className="crdPadd txtRgt" style={{ textAlign: "right" }}>
                                                                                            <span id="lblGrandTotal"><MdCurrencyRupee />
                                                                                                {(
                                                                                                    actualData
                                                                                                        .clsModelInvoicePrintDtl
                                                                                                        .GrandTotal &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl
                                                                                                    .GrandTotalP2 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl
                                                                                                    .GrandTotalP3 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl
                                                                                                    .GrandTotalP4 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl
                                                                                                    .GrandTotalP5 &&
                                                                                                actualData
                                                                                                    .clsModelInvoicePrintDtl
                                                                                                    .GrandTotalP6
                                                                                                )
                                                                                                    .toFixed(2)
                                                                                                    .toLocaleString("en-US")}</span>
                                                                                        </td>
                                                                                    </tr>

                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td style={{ paddingTop: "100px", textAlign: "right" , width: "200px" }}><b>Authorized Signatory</b></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                            :
                                                                            <table border="0" cellSpacing="0" cellPadding="0" style={{ lineHeight: "16px" }}>
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td style={{ width: "421px" }}></td>
                                                                                        <td style={{ width: "190px", textAlign: "right" }}>
                                                                                            <b>
                                                                                                <span id="Label11">Gross Amount</span></b>
                                                                                        </td>
                                                                                        <td rowSpan="6" valign="top" align="center" className="cnTdWidth">
                                                                                            <p style={{ fontSize: "11px", textAlign: "center", lineHeight: "20px", margin: "0px" }}>
                                                                                                <b></b>
                                                                                            </p>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td style={{ textAlign: "right" }}>
                                                                                            <b>
                                                                                                <span id="Label12">Other Discount</span></b>
                                                                                        </td>
                                                                                        <td align="right">
                                                                                            <span id="lblOthDisc"></span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td valign="top">
                                                                                            <b>
                                                                                                <span id="Label53">Scheme Benefit (inclusive of GST) = Rs.</span></b>
                                                                                        &nbsp;
                                                                                            <span id="lblSchemeBenefit" style={{ paddingLeft: "15px" }}></span>
                                                                                        </td>
                                                                                        <td style={{ textAlign: "right" }}>
                                                                                            <b>
                                                                                                <span id="Label13">Total Adjusted Amount</span></b>
                                                                                        </td>
                                                                                        <td align="right">
                                                                                            <span id="lblAdjAmt"></span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td valign="top">
                                                                                            <b>
                                                                                                <span id="Label14">Amount Payable (in Words)</span></b>
                                                                                        </td>
                                                                                        <td align="right"><b>
                                                                                            <span id="Label15">GST</span></b></td>
                                                                                        <td align="right">
                                                                                            <span id="lblTotalVat"></span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td rowSpan="4" valign="top">
                                                                                            <b>
                                                                                                <span id="Label16" className="lblRs">Rupees : &nbsp;</span></b><span id="lblAmtInWord" className="amtWord"></span>
                                                                                            <br></br>
                                                                                            <br></br>
                                                                                            <div style={{ border: "1px solid #333", paddingRight: "10px", paddingLeft: "4px", display: "inline-block", fontWeight: "bold", fontSize: "5px" }}>
                                                                                                <span id="Label58">Customer Service Toll Free No:1800222100/18002098100</span>
                                                                                            </div>
                                                                                        </td>

                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td style={{ textAlign: "right" }}>
                                                                                            <b>
                                                                                                <span id="Label55">Grand Total Rs.</span></b>
                                                                                        </td>
                                                                                        <td align="right">
                                                                                            <span id="lblTotal"></span>
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr style={{ borderTop: "1px solid #000" }}>
                                                                                        <td className="borderTop txtRgt" style={{ textAlign: "right" }}>
                                                                                            <b>
                                                                                                <span id="LblCrdAdj1">Credit Adjustment</span></b>
                                                                                        </td>
                                                                                        {/* <td className="borderTop">&nbsp;</td> */}
                                                                                        <td className="borderTop crdPadd txtRgt" style={{ textAlign: "right" }}>
                                                                                            <span id="LblCrdAdjVal1"></span>
                                                                                        </td>

                                                                                    </tr>
                                                                              
                                                                                    <tr>
                                                                                        <td></td>
                                                                                        <td style={{ paddingTop: "100px", width: "200px"}}><b>Authorized Signatory</b></td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>}
                                                                    </div>
                                                                </div>
                                                            </>
                                                        </div>
                                                    );
                                                })}
                                            </>);
                                    })}
                                </div>
                            </PDFExport>
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button className="btn btn-cancel-red me-3" onClick={handleClose}>
                        Close
                        </button>
                        <button
                            className="btn btn-primary"
                            ref={printRef}
                            onClick={() => {
                                if (pdfExportComponent.current) {
                                    pdfExportComponent.current.save();
                                }
                                handleClose();
                            // printRef.current.save();
                            }}
                        >
                        Download
                        </button>
                    </Modal.Footer>
                </Modal>
            );
        }}
    else{
        return(
            <PrintInvoicePopUp show={invoicePopup} hideShow = {setInvoicePopup} name = "Invoice"/>
        );
    }
};
export default Print;
