import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useWriteOffContext } from "../../context/WriteOffContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { EndpointWO, createAPIEndpointWO } from "../../Services/WriteOff/WriteOffService";
import storage from "../../Services/Storage";
import Loader from "../../Components/Loader";
import * as Fa from "react-icons/fa";
// import { toast } from "react-toastify";

const AllWriteOffRecords = () => {

    const [allRecords,setAllRecords] = useState([]);
    const auth = storage.getLocal("user");
    const navlink = useNavigate();
    const {isLoading,setIsLoading}=useWriteOffContext();
    const addNewPage = () => {
        navlink("/creditmanagement/writeoff/details");
    };
    const gotooutstandingadjustment = () => {
        navlink("/creditmanagement/writeoff/outstandingadjustment");
    };
    //useEffects
    useEffect(()=>{
        setIsLoading(true);
        createAPIEndpointWO()
            .get(`${EndpointWO.PopulateWriteOffData}?CompId=${auth.CompId}`)
            .then((res)=>{
                setAllRecords(res?.data?.Data);
                setIsLoading(false);
            })
            .catch((error)=>{
                setIsLoading(false);
                console.log(error);
            });
    },[]);  
    //other functions
    
    const renderAction = (rowdata) => {
         
        return (
            <>
                <div className="action-icon">
                    <div className="d-inline" data-toggle="tooltip" data-placement="bottom" title="View Details">
                        <button className="grid-icon" onClick={() => { navlink(`/creditmanagement/writeoff/details?id=${encodeURIComponent(rowdata?.DRCR_NO)}&date=${encodeURIComponent(rowdata?.DRCR_DATE)}&docType=${encodeURIComponent(rowdata?.MASK_DRCR_TAG)}`); }}>
                            <Fa.FaEye />
                        </button>
                    </div>
                </div>
            </>
        );

    };
    
    return (
        <main>
            <div className="col-12 pt-3 pb-4">
                <div className="row">
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <h3 className="py-2">Write Off</h3>
                    </div>
                    <div className="col-lg-6 col-md-12 col-sm-12">
                        <div className="float-end mb-2">
                            <button type="submit" className="btn btn-outline-primary me-3" onClick={addNewPage}>Go To Write Off</button>
                            <button type="submit" className="btn btn-primary" onClick={gotooutstandingadjustment}>Go To Outstanding Adjustment</button>
                        </div>
                    </div>
                </div>
                <section className="">
                    <div className="DataTable">
                        {isLoading? <Loader /> : <DataTable
                            value={allRecords}
                            dataKey='DRCR_NO'
                            paginator
                            rows={10}
                            filterDisplay="row"
                            scrollable
                            rowsPerPageOptions={[10, 25, 50]}
                            scrollHeight="80vh"
                            showGridlines
                            stripedRows
                            globalFilterFields={["PROD_CODE", "DESCRIPTION"]} emptyMessage="No results found."
                            tableStyle={{ minWidth: "60rem" }}>
                            <Column field="DRCR_NO" header="Document Number" style={{ minWidth: "8rem" }} filterMatchMode="contains" filter filterPlaceholder="Search Here..." />
                            <Column field="DRCR_DATE" header="Document Date" style={{ minWidth: "12rem" }} filterMatchMode="contains" filter filterPlaceholder="Search Here..." />
                            <Column field="STATUS" header="Status"  />
                            <Column field="DETAILS" header="Details"  />      
                            <Column field={renderAction} header="Action" />
                      
                        </DataTable>}
                    </div>
                </section>
            </div>
        </main>

    );
};

export default AllWriteOffRecords;