import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import {
    createAPIDefaultEndpoint,
    Endpoint,
} from "../../Services/DefaultSetting/DefaultSettingService";
import storage from "../../Services/Storage";
import { toast } from "react-toastify";
import Loader from "../../Components/Loader";

const DefaultSettings = () => {
    const [getAllDefault, SetGetAllDefault] = useState([]);
    const [nonInvSupp, setNonInvSupp] = useState("");
    const [nonInvSuppOptions, setNonInvSuppOptions] = useState(null);
    const [orderCancellationReason, setOrderCancellationReason] = useState("");
    const [orderCancellationReasonOptions, setOrderCancellationReasonOptions] =
        useState(null);
    const [supplier, setSupplier] = useState("");
    const [supplierOptions, setSupplierOptions] = useState(null);
    const [taxArea, setTaxArea] = useState("");
    const [taxAreaOptions, setTaxAreaOptions] = useState(null);
    const [warehouse, setWarehouse] = useState("");
    const [warehouseOptions, setWarehouseOptions] = useState(null);
    const [cncleInvSetting, setCncleInvSetting] = useState("");
    const [cncleInvSettingOptions, setcncleInvSettingOptions] = useState(null);
    const [drpdwnVisblNonInv, setDrpdwnVisblNonInv] = useState(true);
    const [drpdwnVisblOrdrCncl, setDrpdwnVisblOrdrCncl] = useState(true);
    const [drpdwnVisblSupp, setDrpdwnVisblSupp] = useState(true);
    const [drpdwnVisblTxAr, setDrpdwnVisblTxAr] = useState(true);
    const [drpdwnVisblWhrHus, setDrpdwnVisblWhrHus] = useState(true);
    const [drpdwnVisblcnclInvSettng, setDrpdwnVisblcnclInvSettng] = useState(true);
    const [loading, setLoading] = useState(false);
    //   const [btnDisble, setBtnDisble] = useState(true);

    const auth = storage.getLocal("user");

    //   useEffect(() => {
    //     setBtnDisble(false);
    //   }, [supplier|nonInvSupp|warehouse|taxArea|orderCancellationReason]);

    useEffect(() => {
        fetchAllData();
    }, []);

    const fetchAllData = () => {
        setLoading(true);
        createAPIDefaultEndpoint()
            .get(`${Endpoint.DefaultSettings}?CompId=${auth.CompId}`)
            .then((val) => {
                // console.log(val.data);
                SetGetAllDefault(val.data);
                val.data.map((item) => {
                    fetchApiAllData(
                        item.MASK_DEF_ID,
                        item.MASK_VALUE_COL,
                        item.MASK_DISPLAY_COL,
                        item.MASK_TABLE_NAME,
                        item.HOT_SEARCH,
                        item.MASK_DEFAULT_VALUE
                    );
                });
                SetGetAllDefault(val.data);
                setLoading(false);
            })
            .catch((error) => {
                console.log(error);
                setLoading(false);
            });
    };

    const handelSaveData = () => {
        // console.log(nonInvSupp);
        // console.log(orderCancellationReason);
        // console.log(supplier);
        // console.log(taxArea);
        // console.log(warehouse);
        // console.log(getAllDefault);
        // console.log(cncleInvSetting);
        let payLoadData = [];
        getAllDefault.forEach((item) => {
            if (item.FIELD_NAME === "Supplier") {
                if (item.MASK_DEFAULT_VALUE != supplier.MASK_ID) {
                    let suppData = {
                        struser: auth.UserId,
                        strcompid: auth.CompId,
                        strdefid: item.MASK_DEF_ID,
                        strdefvalue: supplier.MASK_ID,
                    };
                    payLoadData.push(suppData);
                }
            } else if (item.FIELD_NAME === "Warehouse") {
                if (item.MASK_DEFAULT_VALUE != warehouse.MASK_ID) {
                    let wareData = {
                        struser: auth.UserId,
                        strcompid: auth.CompId,
                        strdefid: item.MASK_DEF_ID,
                        strdefvalue: warehouse.MASK_ID,
                    };
                    payLoadData.push(wareData);
                }
            } else if (item.FIELD_NAME === "Non Inventory Supplier") {
                if (item.MASK_DEFAULT_VALUE != nonInvSupp.MASK_ID) {
                    let noninvData = {
                        struser: auth.UserId,
                        strcompid: auth.CompId,
                        strdefid: item.MASK_DEF_ID,
                        strdefvalue: nonInvSupp.MASK_ID,
                    };
                    payLoadData.push(noninvData);
                }
            } else if (item.FIELD_NAME === "Order Cancellation Reason") {
                if (item.MASK_DEFAULT_VALUE != orderCancellationReason.MASK_ID) {
                    let orderCanclData = {
                        struser: auth.UserId,
                        strcompid: auth.CompId,
                        strdefid: item.MASK_DEF_ID,
                        strdefvalue: orderCancellationReason.MASK_ID,
                    };
                    payLoadData.push(orderCanclData);
                }
            } else if (item.FIELD_NAME === "Tax Area") {
                if (item.MASK_DEFAULT_VALUE != taxArea.MASK_ID) {
                    let taxArData = {
                        struser: auth.UserId,
                        strcompid: auth.CompId,
                        strdefid: item.MASK_DEF_ID,
                        strdefvalue: taxArea.MASK_ID,
                    };
                    payLoadData.push(taxArData);
                }
            }
            else if (item.FIELD_NAME === "Cancelled Invoice Settings") {
                if (item.MASK_DEFAULT_VALUE != cncleInvSetting.MASK_ID) {
                    let cnclINVSettng = {
                        struser: auth.UserId,
                        strcompid: auth.CompId,
                        strdefid: item.MASK_DEF_ID,
                        strdefvalue: cncleInvSetting.MASK_ID,
                    };
                    payLoadData.push(cnclINVSettng);
                }
            }
            return null;
            // console.log(item.MASK_DEFAULT_VALUE);
            // console.log(item.MASK_DEF_ID);
            // console.log(item.FIELD_NAME);
        });
        if (payLoadData.length > 0) {
            let savePayload = {
                objDefaultSettings: payLoadData,
            };
            callingSaveApi(savePayload);
        } else {
            toast.warn("Change Default Settings to save data", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
        }
    };

    const callingSaveApi = (payload) => {
        // console.log(payload);
        // return;
        setLoading(true);
        createAPIDefaultEndpoint()
            .post(`${Endpoint.SaveDefaultSettings}`, JSON.stringify(payload), {
                headers: { "Content-Type": "application/json" },
            })
            .then((res) => {
                // console.log("Ab000", res.data);
                toast.success(`${res.data.Message}`, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,
                });
                setLoading(false);
                setDrpdwnVisblcnclInvSettng(true);
                setDrpdwnVisblWhrHus(true);
                setDrpdwnVisblTxAr(true);
                setDrpdwnVisblSupp(true);
                setDrpdwnVisblOrdrCncl(true);
                setDrpdwnVisblNonInv(true);
                fetchAllData();
            })
            .catch((error) => {
                console.log("error", error);
                setLoading(false);
            });
    };

    const fetchApiAllData = (
        maskDefId,
        maskValColmn,
        dispCol,
        tblName,
        hotSearch,
        defltValue
    ) => {
        createAPIDefaultEndpoint()
            .get(
                `${Endpoint.PopulateGridData}?CompId=${auth.CompId}&MASK_VALUE_COL=${maskValColmn}&MASK_DISPLAY_COL=${dispCol}&MASK_TABLE_NAME=${tblName}`
            )
            .then((val) => {
                // console.log(val.data);
                let objValueData = { MASK_ID: defltValue, DEFAULT_VALUE: hotSearch };
                if (maskDefId == "34") {
                    setNonInvSuppOptions(val.data);
                    setNonInvSupp(objValueData);
                } else if (maskDefId == "35") {
                    setOrderCancellationReasonOptions(val.data);
                    setOrderCancellationReason(objValueData);
                } else if (maskDefId == "15") {
                    setSupplierOptions(val.data);
                    setSupplier(objValueData);
                } else if (maskDefId == "5") {
                    setTaxAreaOptions(val.data);
                    setTaxArea(objValueData);
                } else if (maskDefId == "3") {
                    setWarehouseOptions(val.data);
                    setWarehouse(objValueData);
                }
                else if (maskDefId == "36") {
                    setcncleInvSettingOptions(val.data);
                    setCncleInvSetting(objValueData);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    if (loading) {
        return (
            <>
                <Loader />
            </>
        );
    } else {
        return (
            <main>
                <div className="col-12 pt-3 pb-4">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <h3 className="py-2">Default Settings</h3>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end mb-2">
                                <button type="submit"
                                    className="btn btn-primary"
                                    onClick={handelSaveData}
                                //   disabled={btnDisble}
                                >
                                    Save
                                </button>
                            </div>
                        </div>
                    </div>
                    <section className="sales-box">
                        <div className="row default-settings-panel">
                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <div className="row d-flex justify-content-end align-items-end">
                                    <div className="col-11">
                                        <label className="form-label">Non Inventory Supplier</label>
                                        <Dropdown
                                            value={nonInvSupp}
                                            onChange={(e) => {
                                                setNonInvSupp(e.value);
                                                // setDrpdwnVisblNonInv(true);
                                            }}
                                            filter
                                            options={nonInvSuppOptions}
                                            optionLabel="DEFAULT_VALUE"
                                            disabled={drpdwnVisblNonInv}
                                        />
                                    </div>
                                    <div className="col-1 ps-0">
                                        <img
                                            src={require("../../Assets/Images/editIcon.png")}
                                            style={{ width: "35px" }}
                                            className="EditImageIcon"
                                            onClick={() => setDrpdwnVisblNonInv(false)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <div className="row d-flex justify-content-end align-items-end">
                                    <div className="col-11">
                                        <label className="form-label">
                                            Order Cancellation Reason
                                        </label>
                                        <Dropdown
                                            value={orderCancellationReason}
                                            onChange={(e) => {
                                                setOrderCancellationReason(e.value);
                                                // setDrpdwnVisblOrdrCncl(true);
                                            }}
                                            filter
                                            options={orderCancellationReasonOptions}
                                            optionLabel="DEFAULT_VALUE"
                                            disabled={drpdwnVisblOrdrCncl}
                                        />
                                    </div>
                                    <div className="col-1 ps-0">
                                        <img
                                            src={require("../../Assets/Images/editIcon.png")}
                                            style={{ width: "35px" }}
                                            className="EditImageIcon"
                                            onClick={() => setDrpdwnVisblOrdrCncl(false)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <div className="row d-flex justify-content-end align-items-end">
                                    <div className="col-11">
                                        <label className="form-label">Supplier</label>
                                        <Dropdown
                                            value={supplier}
                                            onChange={(e) => {
                                                setSupplier(e.value);
                                                // setDrpdwnVisblSupp(true);
                                            }}
                                            filter
                                            options={supplierOptions}
                                            optionLabel="DEFAULT_VALUE"
                                            disabled={drpdwnVisblSupp}
                                        />
                                    </div>
                                    <div className="col-1 ps-0">
                                        <img
                                            src={require("../../Assets/Images/editIcon.png")}
                                            style={{ width: "35px" }}
                                            className="EditImageIcon"
                                            onClick={() => setDrpdwnVisblSupp(false)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <div className="row d-flex justify-content-end align-items-end">
                                    <div className="col-11">
                                        <label className="form-label">Tax Area</label>
                                        <Dropdown
                                            value={taxArea}
                                            onChange={(e) => {
                                                setTaxArea(e.value);
                                                // setDrpdwnVisblTxAr(true);
                                            }}
                                            filter
                                            options={taxAreaOptions}
                                            optionLabel={"DEFAULT_VALUE"}
                                            disabled={drpdwnVisblTxAr}
                                        />
                                    </div>
                                    <div className="col-1 ps-0">
                                        <img
                                            src={require("../../Assets/Images/editIcon.png")}
                                            style={{ width: "35px" }}
                                            className="EditImageIcon"
                                            onClick={() => setDrpdwnVisblTxAr(false)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <div className="row d-flex justify-content-end align-items-end">
                                    <div className="col-11">
                                        <label className="form-label">Warehouse</label>
                                        <Dropdown
                                            value={warehouse}
                                            onChange={(e) => {
                                                setWarehouse(e.value);
                                            }}
                                            filter
                                            options={warehouseOptions}
                                            optionLabel="DEFAULT_VALUE"
                                            disabled={drpdwnVisblWhrHus}
                                        />
                                    </div>
                                    <div className="col-1 ps-0">
                                        <img
                                            src={require("../../Assets/Images/editIcon.png")}
                                            style={{ width: "35px" }}
                                            className="EditImageIcon"
                                            onClick={() => setDrpdwnVisblWhrHus(false)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                <div className="row d-flex justify-content-end align-items-end">
                                    <div className="col-11">
                                        <label className="form-label">Cancelled Invoice Settings</label>
                                        <Dropdown
                                            value={cncleInvSetting}
                                            onChange={(e) => {
                                                setCncleInvSetting(e.value);
                                                // setDrpdwnVisblcnclInvSettng(true);
                                            }}
                                            filter
                                            options={cncleInvSettingOptions}
                                            optionLabel="DEFAULT_VALUE"
                                            disabled={drpdwnVisblcnclInvSettng}
                                        />
                                    </div>
                                    <div className="col-1 ps-0">
                                        <img
                                            src={require("../../Assets/Images/editIcon.png")}
                                            style={{ width: "35px" }}
                                            className="EditImageIcon"
                                            onClick={() => setDrpdwnVisblcnclInvSettng(false)}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                            <div className="row">
                                <div className="col-11">
                                    <label className="form-label">Cancelled Invoice Settings</label>
                                    <Dropdown placeholder='Defaoult Value'/>
                                </div>
                                <div className="col-1 ps-0">
                                    <img src={require("../../Assets/Images/editIcon.png")} style={{ width: "35px" , marginTop: "35px" }} className="EditImageIcon"/>
                                </div>
                            </div>
                        </div> */}
                        </div>
                    </section>
                </div>
            </main>
        );
    }
};

export default DefaultSettings;
