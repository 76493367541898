import React, { useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import * as Fa from "react-icons/fa";
import { AutoComplete } from "primereact/autocomplete";
import { InputText } from "primereact/inputtext";
import {
    createAPIDocumentEndpoint,
    Endpoint,
} from "../../Services/DocumentAdjustment/DocumnetAdjusService";
import { toast } from "react-toastify";
import { Dialog } from "primereact/dialog";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import storage from "../../Services/Storage";
import Loader from "../../Components/Loader";

const Details = () => {
    // const [partyData,SetPartyData]= useState();
    const [partynamesugg, setPartynamesugg] = useState([]);
    const [onchangepartyname, setOnchangepartyname] = useState([]);
    const [partyname, setPartyname] = useState([]);
    const [balanceAllocation, setBalanceAllocation] = useState(0);
    const [totalAmount, setTotalAmount] = useState(0);
    const [payableDocumentData, setPayableDocumentData] = useState([]);
    const [selectedPayableDocumentData, setSelectedPayableDocumentData] =
    useState([]);
    const [payablePopupShow, setPayablePopupShow] = useState(false);
    const [receivblePopupShow, setReceivblePopupShow] = useState(false);
    const [payableAllGridData, setPayableAllGridData] = useState([]);
    const [receivableDocumentData, setReceivableDocumentData] = useState([]);
    const [selectedreceivableDocumentData, setSelectedreceivableDocumentData] =
    useState([]);
    const [receiveableDocumentGridData, setReceiveableDocumentGridData] =
    useState([]);
    const [payableIsdata, setPayableIsdata] = useState(false);
    const [payableLoading, setPayableLoading] = useState(false);
    const [receiveLoading, setReceiveLoading] = useState(false);
    const [savebtnVisible, setSavebtnVisible] = useState(false);
    const [saveLoader, setSaveLoader] = useState(false);
    const [viewData, setViewData] = useState(false);
    const [viewDtDetailPay, setviewDtDetailPay] = useState([]);
    const [viewDtDetailRecv, setviewDtDetailRecv] = useState([]);
    const navlink = useNavigate();
    const [searchparam] = useSearchParams();
    let AdjId = searchparam.get("MaskAdjId");
    const auth = storage.getLocal("user");

    useEffect(() => {
        populatePartyData();
        if (AdjId != null) {
            populateViewData(AdjId);
            setViewData(true);
        }
    }, []);

    const populatePartyData = () => {
        createAPIDocumentEndpoint()
            .get(`${Endpoint.GetPartyName}?CompId=${auth.CompId}`)
            .then((response) => {
                // console.log("responseData", response.data);
                setPartynamesugg(response.data.Data);
            })
            .catch(() => {});
    // setPartynamesugg(party);
    };

    const populateViewData = (AdjId) => {
        setSaveLoader(true);
        createAPIDocumentEndpoint()
            .get(
                `${Endpoint.ViewDocumentAdjustment}?MASK_ADJ_ID=${AdjId}&CompId=${auth.CompId}`
            )
            .then((response) => {
                // console.log("viewresponseData", response.data);
                setviewDtDetailPay(response.data.DtDetailPay);
                setviewDtDetailRecv(response.data.DtDetailRecv);
                setOnchangepartyname(response.data.DtHeader[0].PARTY);
                setSaveLoader(false);
            })
            .catch(() => {
                setSaveLoader(false);
            });
    };

    const searchEmployee = (e) => {
        setTimeout(() => {
            let _filteredemployee;
            if (!e.query.trim().length) {
                _filteredemployee = [...partynamesugg];
            } else {
                _filteredemployee = partynamesugg.filter((employee) => {
                    return (
                        employee.PARTY_CODE.toLowerCase().includes(e.query.toLowerCase()) ||
            employee.PARTY_NAME.toLowerCase().includes(e.query.toLowerCase())
                    );
                });
            }

            setPartyname(_filteredemployee);
        }, 250);
    };

    const employeeTemplate = (employee) => {
        return (
            <>
                {employee.PARTY_CODE} - {employee.PARTY_NAME}
            </>
        );
    };

    const receivableDocumentAmt = () => {
        if (Object.keys(onchangepartyname).length === 0) {
            toast.warn("Please select Customer Name", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
        } else if (!payableIsdata) {
            toast.warn("Please select payable document(s)", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
        } else {
            receivableAmountGrid(onchangepartyname.MASK_PARTY_ID);
        }
        // console.log("setOnchangepartyname", onchangepartyname);
    };

    const payableDocumntAmt = () => {
        if (Object.keys(onchangepartyname).length === 0) {
            toast.warn("Please select Customer Name", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
        } else {
            setPayableDocumentData([]);
            paybleAmountGridData(onchangepartyname.MASK_PARTY_ID);
        }
    };

    const paybleAmountGridData = (partyId) => {
        setPayableLoading(true);
        createAPIDocumentEndpoint()
            .get(
                `${Endpoint.GetPayableDocument}?PartyId=${partyId}&CompId=${auth.CompId}`
            )
            .then((response) => {
                // console.log("responseData", response.data);
                if (response.data.Status === "Warning") {
                    setPayableDocumentData([]);
                    setPayableLoading(false);
                    // setPayablePopupShow(true);
                } else {
                    setPayableDocumentData(response.data.Data);
                    setPayableLoading(false);
                    // setPayablePopupShow(true);
                }
                // setPayableDocumentData(response.data.Data);
            })
            .catch(() => {
                setPayableLoading(false);
            });
        setPayablePopupShow(true);
    // setPayableDocumentData(dataArray);
    };

    const receivableAmountGrid = (partyId) => {
        setReceiveLoading(true);
        createAPIDocumentEndpoint()
            .get(`${Endpoint.GetReceivableDocument}?PartyId=${partyId}`)
            .then((response) => {
                // console.log("responseData", response.data);
                if (response.data.Status === "Warning") {
                    setReceivableDocumentData([]);
                    setReceiveLoading(false);
                } else {
                    setReceivableDocumentData(response.data.Data);
                    setReceiveLoading(false);
                }
            })
            .catch(() => {
                setReceiveLoading(false);
            });
        // setReceivableDocumentData(dataArray1);
        setReceivblePopupShow(true);
    };
    const handleOkPayable = () => {
        let balanceamt = selectedPayableDocumentData.reduce((prev, curr) => {
            return Number(prev) + Number(curr.PAYABLE_AMOUNT);
        }, 0);
        // console.log("balanceamt", balanceamt);
        setBalanceAllocation(balanceamt);
        setTotalAmount(balanceamt);
        setPayableAllGridData(selectedPayableDocumentData);
        // console.log("selectedPayableDocumentData", selectedPayableDocumentData);
        setPayablePopupShow(false);
        if (selectedPayableDocumentData.length > 0) {
            setPayableIsdata(true);
        } else {
            setPayableIsdata(false);
        }
    };

    const handleOkReceivable = () => {
        let updatedselectedreceivableDocumentData = [];
        updatedselectedreceivableDocumentData = selectedreceivableDocumentData.map(
            (obj) => ({
                ...obj,
                amount: 0,
            })
        );
        let remainingAmount = totalAmount;
        updatedselectedreceivableDocumentData.forEach((item) => {
            if (remainingAmount >= item.PAYABLE_AMOUNT) {
                item.amount = item.PAYABLE_AMOUNT;
                remainingAmount -= item.PAYABLE_AMOUNT;
            } else {
                item.amount = remainingAmount;
                remainingAmount = 0;
            }
        });

        let reminingBalanceAllocation =
      updatedselectedreceivableDocumentData.reduce((prev, curr) => {
          return Number(prev) + Number(curr.amount);
      }, 0);
        setBalanceAllocation(totalAmount - reminingBalanceAllocation);

        if (
            payableAllGridData.length > 0 &&
      updatedselectedreceivableDocumentData.length > 0
        ) {
            setSavebtnVisible(true);
        } else {
            setSavebtnVisible(false);
        }

        // console.log("totalAmount", totalAmount);
        setReceiveableDocumentGridData(updatedselectedreceivableDocumentData);
        // console.log(
        //     "selectedreceivableDocumentData",
        //     selectedreceivableDocumentData
        // );
        // console.log("payableAllGridData", payableAllGridData);
        // console.log("receiveableDocumentGridData", receiveableDocumentGridData);
        setReceivblePopupShow(false);
    };
    const handleClickPayableCancel = () => {
        setPayablePopupShow(false);
    };
    const handleClickReceiveCancel = () => {
        setReceivblePopupShow(false);
    };

    const onAmountChange = (val, rowData) => {
        const updatedData = receiveableDocumentGridData.map((item) => {
            if (item.DOCUMENT_NO === rowData.DOCUMENT_NO) {
                return { ...item, amount: val };
            }
            return item;
        });
        setReceiveableDocumentGridData(updatedData);
    };

    useEffect(() => {
    // console.log("hello",receiveableDocumentGridData);
        let reminingBalanceAllocation = receiveableDocumentGridData.reduce(
            (prev, curr) => {
                return Number(prev) + Number(curr.amount);
            },
            0
        );
        setBalanceAllocation(totalAmount - reminingBalanceAllocation);
    }, receiveableDocumentGridData);

    const renderAction = (rowData) => {
        
        return (
            <>
                {Number(rowData.amount)==0 &&<div className="action-icon">
                    <div
                        className="d-inline"
                        data-toggle="tooltip"
                        data-placement="bottom"
                        title="Cancel"
                    >
                        <button
                            className="grid-icon"
                            onClick={() => {
                                cancelrecv(rowData.DOCUMENT_NO);
                            }}
                        >
                            <Fa.FaTimes className="cancel" />
                        </button>
                    </div>
                </div>}
            </>
        );
    };


    const cancelrecv = (documentnumber)=>{
        // console.log("documentnumber",documentnumber);
        // console.log("documentnumber",receiveableDocumentGridData);
        let removedreceved =  receiveableDocumentGridData.filter((item)=>{
            return item.DOCUMENT_NO !== documentnumber;
        });

        setReceiveableDocumentGridData(removedreceved);
    };
  

    const saveDocumentdata = () => {
        // console.log("receiveableDocumentGridData", receiveableDocumentGridData);
        // console.log("payableAllGridData", payableAllGridData);
        // console.log("balanceAllocation", balanceAllocation);
        // console.log("totalAmount", totalAmount);

        let paydetails = payableAllGridData.map((val) => ({
            masK_DOCUMENT_ID: val.MASK_ACPR_ID,
            paY_DOC_NO: val.DOCUMENT_NO,
            paY_DOC_DATE: val.DOCUMENT_DATE,
            acpR_TOTAL_VAL: val.MASK_DOCUMENT_AMT.toString(),
            adJ_AMT: val.PAYABLE_AMOUNT,
            masK_DOCUMENT_TYPE: val.MASK_DOC_TYPE,
            masK_DOCUMENT_SR_NO: val.PAYTERM_SERIAL_NUMBAR,
            reaS_CODE: val.REAS_CODE,
            reaS_DESC: val.REAS_DESC,
        }));
        let receivedetails = receiveableDocumentGridData.map((val) => ({
            rcV_DOC_NO: val.DOCUMENT_NO,
            rcV_DOC_DATE: val.DOCUMENT_DATE,
            acpR_TOTAL_VAL: val.MASK_DOCUMENT_AMT.toString(),
            calC_AMT: val.amount.toString(), //
            adJ_AMT: val.PAYABLE_AMOUNT.toString(),
            masK_DOCUMENT_TYPE: val.MASK_DOC_TYPE,
            masK_DOCUMENT_SR_NO: val.PAYTERM_SERIAL_NUMBAR,
        }));

        let receive = receiveableDocumentGridData.reduce((prev, curr) => {
            return Number(prev) + Number(curr.amount);
        }, 0);
        let payble = payableAllGridData.reduce((prev, curr) => {
            return Number(prev) + Number(curr.MASK_DOCUMENT_AMT);
        }, 0);

        // console.log("receive",receive);
        // console.log("payble",payble);
        // console.log();

        const checkAmountVsPayable = (invoice) => {
            let amount = parseFloat(invoice.amount);
            let payableAmount = parseFloat(invoice.PAYABLE_AMOUNT);
            if (amount > payableAmount) {
                return true;
            }
            return false;
        };
        let isAmountGreaterThanPayable = false;
        let itCantBeZero = false;
        if (receiveableDocumentGridData.length > 0) {
            receiveableDocumentGridData.forEach((invoice) => {
                if (checkAmountVsPayable(invoice)) {
                    isAmountGreaterThanPayable = true;
                }
                return null;
            });
            receiveableDocumentGridData.forEach((item)=>{
                if(Number(item.amount)==0){
                    itCantBeZero=true;
                }
                return null;
            });
        }

        if (isAmountGreaterThanPayable) {
            toast.warn(
                "Adjusted Amount is greater than payable amount for Outstanding Receivables",
                {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 4000,
                }
            );
        } 
        else if(itCantBeZero){
            toast.warn(
                "Adjusted Amount can't be 0 or empty",
                {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 4000,
                }
            );
        }
        else if (balanceAllocation < 0) {
            toast.warn("Balance for Allocation can't be negative", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
        } 
        else if ( receive>payble ) {
            toast.warn("Receivable can't be greater than Payable", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 3000,
            });
        } 
        
        else {
            let payload = {
                userId: auth.UserId,
                partyId: onchangepartyname.MASK_PARTY_ID,
                listPayDetail: paydetails,
                listRevcDetail: receivedetails,
            };

            // console.log("payload", payload);
            // return;
            setSaveLoader(true);
            createAPIDocumentEndpoint()
                .post(`${Endpoint.SaveDocumentAdjustment}`, JSON.stringify(payload), {
                    headers: { "Content-Type": "application/json" },
                })
                .then((res) => {
                    // console.log("Ab000", res.data);

                    if(res.data.Status==="Success"){
                        toast.success("Document Adjusted successfully", {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 3000,
                        });
                        setSaveLoader(false);
                        navlink("/creditmanagement/documentadjustment");
                    }
                    else{
                        console.log(res.data);
                        setSaveLoader(false);
                    }
          
                })
                .catch(() => {
                    toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                    setSaveLoader(false);
                });
        }
    };

    if (saveLoader) {
        return (
            <>
                <Loader />
            </>
        );
    } else {
        return (
            <>
                <div className="col-12 pt-3">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mx-1">
                                    <li className="breadcrumb-item">
                                        <Link to="/creditmanagement/documentadjustment">
                      Document Adjustment
                                        </Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                    Details
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end">
                                <Link to="/creditmanagement/documentadjustment">
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                    >
                                        <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All
                    Records
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <section className="sales-box">
                        <form>
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">
                    Customer Name <span className="mandatory">*</span>
                                    </label>
                                    <div className="d-block">
                                        <AutoComplete
                                            field="PARTY_NAME"
                                            value={onchangepartyname}
                                            suggestions={partyname}
                                            completeMethod={searchEmployee}
                                            onChange={(e) => {
                                                setOnchangepartyname(e.target.value);
                                            }}
                                            dropdown={true}
                                            itemTemplate={employeeTemplate}
                                            emptyMessage="No Customer Name found."
                                            showEmptyMessage
                                            disabled={viewData}
                                        />
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">Balance for Allocation</label>
                                    <InputText
                                        value={balanceAllocation}
                                        className="form-control"
                                        disabled
                                    />
                                </div>
                                <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                    <label className="form-label">Total Amount</label>
                                    <InputText
                                        value={totalAmount}
                                        className="form-control"
                                        disabled
                                    />
                                </div>

                                {!viewData &&<div className="col-lg-12 col-md-12 col-sm-12">
                                    <div className="float-end mt-2">
                                        {savebtnVisible && (
                                            <button
                                                type="button"
                                                className="btn btn-primary me-3"
                                                onClick={saveDocumentdata}
                                            >
                        Save
                                            </button>
                                        )}
                                        <button
                                            type="button"
                                            className="btn btn-outline-primary me-3"
                                            onClick={receivableDocumentAmt}
                                        >
                      Receivable Document
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-primary"
                                            onClick={payableDocumntAmt}
                                        >
                      Payable Document
                                        </button>
                                    </div>
                                </div>}
                            </div>
                        </form>
                    </section>

                    {/* <section className="">
            <div className="DataTable pb-4 pt-1">
              {payableLoading ? (
                <Loader />
              ) : (
                <DataTable
                  value={payableAllGridData}
                  paginator
                  rows={10}
                  showGridlines
                  stripedRows
                  filterDisplay="row"
                  emptyMessage="No Records found to display."
                  tableStyle={{ minWidth: "60rem" }}
                >
                  <Column
                    field="DOCUMENT_NO"
                    header="Document Number"
                    filterMatchMode="contains"
                    filter
                    filterPlaceholder="Search Here..."
                  ></Column>
                  <Column
                    field="DOCUMENT_DATE"
                    header="Document Date"
                    filterMatchMode="contains"
                    filter
                    filterPlaceholder="Search Here..."
                  ></Column>
                  <Column
                    field="MASK_DOCUMENT_AMT"
                    header="Document Amount"
                    filterMatchMode="contains"
                    filter
                    filterPlaceholder="Search Here..."
                  ></Column>
                  <Column
                    field="PAYABLE_AMOUNT"
                    header="Outstanding Payables"
                    filterMatchMode="contains"
                    filter
                    filterPlaceholder="Search Here..."
                  ></Column>
                </DataTable>
              )}
            </div>
            </div>
            <div className="col-lg-12 col-md-12 col-sm-12">
              <h3>Receivable Records</h3>
            <div className="DataTable pb-4 pt-1">
              {receiveLoading ? (
                <Loader />
              ) : (
                <DataTable
                  value={receiveableDocumentGridData}
                  paginator
                  rows={10}
                  showGridlines
                  stripedRows
                  filterDisplay="row"
                  emptyMessage="No Records found to display"
                  tableStyle={{ minWidth: "60rem" }}
                >
                  <Column
                    field="DOCUMENT_NO"
                    header="Document Number"
                    filterMatchMode="contains"
                    filter
                    filterPlaceholder="Search Here..."
                  ></Column>
                  <Column
                    field="DOCUMENT_DATE"
                    header="Document Date"
                    filterMatchMode="contains"
                    filter
                    filterPlaceholder="Search Here..."
                  ></Column>
                  <Column
                    field="MASK_DOCUMENT_AMT"
                    header="Document Amount"
                    filterMatchMode="contains"
                    filter
                    filterPlaceholder="Search Here..."
                  ></Column>
                  <Column
                    field="amount"
                    header="Adjusted Amount"
                    body={(rowData) => (
                      <InputText
                        value={rowData.amount}
                        onChange={(e) => {
                          const inputValue = e.target.value;
                          const regex = /^\d*\.?\d{0,2}$/;
                          if (regex.test(inputValue) || inputValue === "") {
                            onAmountChange(inputValue, rowData);
                          }
                        }}
                      />
                    )}
                  />
                  <Column
                    field="PAYABLE_AMOUNT"
                    header="Outstanding Receivables"
                    filterMatchMode="contains"
                    filter
                    filterPlaceholder="Search Here..."
                  ></Column>
                </DataTable>
              )}
            </div>
          </section> */}
                    {viewData ? <section className="">
                        <h3>Payable Records</h3>
                        <div className="DataTable pb-4 pt-1">
                            <DataTable
                                value={viewDtDetailPay}
                                paginator
                                rows={10}
                                showGridlines
                                stripedRows
                                filterDisplay="row"
                                emptyMessage="No Records found to display."
                                tableStyle={{ minWidth: "60rem" }}
                            >
                                <Column
                                    field="PAY_DOC_NO"
                                    header="Document Number"
                                ></Column>
                                <Column
                                    field="PAY_DOC_DATE"
                                    header="Document Date"
                   
                                ></Column>
                                <Column
                                    field="ACPR_TOTAL_VAL"
                                    header="Document Amount"
                    
                                ></Column>
                                <Column
                                    field="ADJ_AMT"
                                    header="Outstanding Payables"
                   
                                ></Column>
                 
                            </DataTable>

                        </div>
                        <h3>Receivable Records</h3>
                        <div className="DataTable pb-4 pt-1">
                            <DataTable
                                value={viewDtDetailRecv}
                                paginator
                                rows={10}
                                showGridlines
                                stripedRows
                                filterDisplay="row"
                                emptyMessage="No Records found to display"
                  
                                tableStyle={{ minWidth: "60rem" }}
                            >
                                <Column
                                    field="RCV_DOC_NO"
                                    header="Document Number"
                    
                                ></Column>
                                <Column
                                    field="RCV_DOC_DATE"
                                    header="Document Date"
                    
                                ></Column>
                                <Column
                                    field="ACPR_TOTAL_VAL"
                                    header="Document Amount"
                    
                                ></Column>
                                {/* <Column
                                    field="CALC_AMT"
                                    header="Adjusted Amount"
                   
                                /> */}
                                <Column
                                    field="ADJ_AMT"
                                    header="Outstanding Receivables"
                    
                                ></Column>
                            </DataTable>
                        </div>
                    </section>:<section className="">
                        <h3>Payable Records</h3>
                        <div className="DataTable pb-4 pt-1">
                            {payableLoading ? (
                                <Loader />
                            ) : (
                                <DataTable
                                    value={payableAllGridData}
                                    paginator
                                    rows={10}
                                    showGridlines
                                    stripedRows
                                    filterDisplay="row"
                                    emptyMessage="No Records found to display."
                                    //   rowsPerPageOptions={[10, 25, 50]}
                                    tableStyle={{ minWidth: "60rem" }}
                                >
                                    <Column
                                        field="DOCUMENT_NO"
                                        header="Document Number"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="DOCUMENT_DATE"
                                        header="Document Date"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="MASK_DOCUMENT_AMT"
                                        header="Document Amount"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="PAYABLE_AMOUNT"
                                        header="Outstanding Payables"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    {/* <Column field={renderAction} header="Action" /> */}
                                </DataTable>
                            )}
                        </div>
                        <h3>Receivable Records</h3>
                        <div className="DataTable pb-4 pt-1">
                            {receiveLoading ? (
                                <Loader />
                            ) : (
                                <DataTable
                                    value={receiveableDocumentGridData}
                                    paginator
                                    rows={10}
                                    showGridlines
                                    stripedRows
                                    filterDisplay="row"
                                    emptyMessage="No Records found to display"
                                    //   rowsPerPageOptions={[10, 25, 50]}
                                    tableStyle={{ minWidth: "60rem" }}
                                >
                                    <Column
                                        field="DOCUMENT_NO"
                                        header="Document Number"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="DOCUMENT_DATE"
                                        header="Document Date"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="MASK_DOCUMENT_AMT"
                                        header="Document Amount"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column
                                        field="amount"
                                        header="Adjusted Amount"
                                        body={(rowData) => (
                                            <InputText
                                                value={rowData.amount}
                                                // onChange={(e) => onAmountChange(e, rowData)}
                                                onChange={(e) => {
                                                    const inputValue = e.target.value;
                                                    const regex = /^\d*\.?\d{0,2}$/;
                                                    if (regex.test(inputValue) || inputValue === "") {
                                                        onAmountChange(inputValue, rowData);
                                                    }
                                                }}
                                            />
                                        )}
                                    />
                                    <Column
                                        field="PAYABLE_AMOUNT"
                                        header="Outstanding Receivables"
                                        filterMatchMode="contains"
                                        filter
                                        filterPlaceholder="Search Here..."
                                    ></Column>
                                    <Column body={renderAction} header="Action "></Column>
                                </DataTable>
                            )}
                        </div>
                    </section>}

                    <div className="">
                        <Dialog
                            header="Payable Records"
                            visible={payablePopupShow}
                            style={{ width: "80vw" }}
                            onHide={() => setPayablePopupShow(false)}
                            closable={false}
                            resizable={false}
                            draggable={false}
                        >
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <div className="DataTable mb-3">
                                    <DataTable
                                        value={payableDocumentData}
                                        paginator
                                        rows={10}
                                        filterDisplay="row"
                                        showGridlines
                                        stripedRows
                                        selectionMode="checkbox"
                                        selection={selectedPayableDocumentData}
                                        rowsPerPageOptions={[10, 25, 50]}
                                        scrollHeight="80vh"
                                        showSelectAll={false}
                                        onSelectionChange={(e) => {
                                            setSelectedPayableDocumentData(e.value);
                                        }}
                                        emptyMessage="No Records found to display"
                                        tableStyle={{ minWidth: "20rem" }}
                                    >
                                        <Column
                                            selectionMode="multiple"
                                            headerStyle={{ width: "3rem" }}
                                        ></Column>
                                        <Column
                                            field="DOCUMENT_NO"
                                            header="Document Number"
                                            style={{ minWidth: "8rem" }}
                                            filter
                                            filterMatchMode="contains"
                                            filterPlaceholder="Search Here..."
                                        ></Column>
                                        <Column
                                            field="DOCUMENT_DATE"
                                            header="Document Date"
                                            style={{ minWidth: "12rem" }}
                                            filter
                                            filterMatchMode="contains"
                                            filterPlaceholder="Search Here..."
                                        ></Column>
                                        <Column
                                            field="REAS_DESC"
                                            header="Reason"
                                            filter
                                            filterMatchMode="contains"
                                            filterPlaceholder="Search Here..."
                                            style={{ minWidth: "12rem" }}
                                        ></Column>
                                        <Column
                                            field="PAYABLE_AMOUNT"
                                            header="Outstanding Payables"
                                            style={{ minWidth: "12rem" }}
                                            filter
                                            filterMatchMode="contains"
                                            filterPlaceholder="Search Here..."
                                        ></Column>
                                    </DataTable>
                                </div>
                            </div>
                            <div className="float-end">
                                <button
                                    type="button"
                                    className="btn btn-primary me-3"
                                    onClick={handleOkPayable}
                                >
                  OK
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-cancel-red"
                                    onClick={handleClickPayableCancel}
                                >
                  Close
                                </button>
                            </div>
                        </Dialog>
                    </div>
                    <div className="">
                        <Dialog
                            header="Receivable Records"
                            visible={receivblePopupShow}
                            style={{ width: "80vw" }}
                            onHide={() => setReceivblePopupShow(false)}
                            closable={false}
                            resizable={false}
                            draggable={false}
                        >
                            <div className="col-md-12 col-lg-12 col-sm-12">
                                <div className="DataTable mb-3">
                                    <DataTable
                                        value={receivableDocumentData}
                                        paginator
                                        rows={10}
                                        filterDisplay="row"
                                        showGridlines
                                        stripedRows
                                        selectionMode="checkbox"
                                        selection={selectedreceivableDocumentData}
                                        // rowsPerPageOptions={[10, 25, 50]}
                                        scrollHeight="80vh"
                                        showSelectAll={false}
                                        onSelectionChange={(e) => {
                                            setSelectedreceivableDocumentData(e.value);
                                        }}
                                        emptyMessage="No Records found to display"
                                        tableStyle={{ minWidth: "20rem" }}
                                    >
                                        <Column
                                            selectionMode="multiple"
                                            headerStyle={{ width: "3rem" }}
                                        ></Column>
                                        <Column
                                            field="DOCUMENT_NO"
                                            header="Document Number"
                                            style={{ minWidth: "8rem" }}
                                            filter
                                            filterMatchMode="contains"
                                            filterPlaceholder="Search Here..."
                                        ></Column>
                                        <Column
                                            field="DOCUMENT_DATE"
                                            header="Document Date"
                                            style={{ minWidth: "12rem" }}
                                            filter
                                            filterMatchMode="contains"
                                            filterPlaceholder="Search Here..."
                                        ></Column>
                                        {/* <Column
                  field="REAS_DESC"
                  header="Reason"
                  filter
                  filterMatchMode="contains"
                  filterPlaceholder="Search Here..."
                  style={{ minWidth: "12rem" }}
                ></Column> */}
                                        <Column
                                            field="PAYABLE_AMOUNT"
                                            header="Outstanding Payables"
                                            style={{ minWidth: "12rem" }}
                                            filter
                                            filterMatchMode="contains"
                                            filterPlaceholder="Search Here..."
                                        ></Column>
                                    </DataTable>
                                </div>
                            </div>
                            <div className="float-end">
                                <button
                                    type="button"
                                    className="btn btn-primary me-3"
                                    onClick={handleOkReceivable}
                                >
                  OK
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-cancel-red"
                                    onClick={handleClickReceiveCancel}
                                >
                  Close
                                </button>
                            </div>
                        </Dialog>
                    </div>
                </div>
            </>
        );
    }
};

export default Details;
