import React, { useState } from "react";
import * as Fa from "react-icons/fa";
import { format, subDays } from "date-fns";
import XLSX from "xlsx";
import { toast } from "react-toastify";
import storage from "../../../Services/Storage";

import { EndpointCreditMReports, createAPIEndpointCreditMReports } from "../../../Services/ReportsAll/CreditManagementReports";
const ReceiptMadeWithinPeriod = () => {
    const currentDate = format(new Date(), "yyyy-MM-dd");
    const date = subDays(new Date(), 7);
    const sevenDays = format(date, "yyyy-MM-dd");
    const [fromDate, setFromDate] = useState(sevenDays);
    const [toDate, setToDate] = useState(currentDate);
    const [loadingExcel, setLoadingExcel] = useState(false);
    const handleExportExcel=(fromDate,toDate)=>
    {
        const auth = storage.getLocal("user");
        setLoadingExcel(true);
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");
        let date = new Date();
        let day = date.getDate();
        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        let exactmonth = months[date.getMonth()];
        let exactDay = (day).toString().padStart(2, "0");
        let year = date.getFullYear();
        let uniqueNo = `Rcpt_Made_Within_A_Prd${exactDay}${exactmonth}${year}`;
        createAPIEndpointCreditMReports()
            .get(`${EndpointCreditMReports.GetExcelDataRcptInPrd}?strFromDateString=${fromDateRange}&strToDateString=${toDateRange}&CompId=${auth.CompId}`)
            .then((val) => {
                // console.log(val.data);
                if(val.data.length>0){
                    let wb = XLSX.utils.book_new();
                    let MapData = val.data.map((val) => {
                        function transformDate(dateString) {
                            if (!dateString) return "";
                            let updatedData = dateString.replace(/(\d+[/])(\d+[/])/, "$2$1");
                            return new Date(updatedData);
                        }
                        let chequeQR_Transaction_Date = transformDate(val["Cheque/QR Transaction Date"]);
                        let document_Date = transformDate(val["Document Date"]);
                        let relevant_Document_Date = transformDate(val["Relevant Document Date"]);
                        return { ...val, "Cheque/QR Transaction Date": chequeQR_Transaction_Date,"Document Date":document_Date,"Relevant Document Date":relevant_Document_Date };
                    });
                    let ws = XLSX.utils.json_to_sheet(MapData);
                    XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
                    XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
                }
                else{
                    toast.warning(
                        "No Data Found between these dates",
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                }
                setLoadingExcel(false);

            }).catch((err)=>{
                 
                toast.error(
                    `${err.response.data}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    }
    
                );               
                setLoadingExcel(false);
            });


    };
 
    return (
        <div className="col-12 pt-3">
            <h3 className="py-2">Receipt Made Within A Period</h3>
            <section className="sales-box">
                <form>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                            <label className="form-label">From Date</label>
                            <input type="date"
                                className="form-control"
                                defaultValue={sevenDays}
                                max={toDate}
                                min="2010-01-01"
                                name="date-field"
                                onChange={(e) => {
                                    setFromDate(e.target.value);
                                }}/>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                            <label className="form-label">To Date</label>
                            <input type="date"
                                defaultValue={currentDate}
                                max={currentDate}
                                min={fromDate}
                                className="form-control"
                                name="date-field"
                                onChange={(e) => setToDate(e.target.value)}/>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="float-end mt-4">
                                <button disabled={loadingExcel} type="button" className="btn btn-primary" onClick={()=>{handleExportExcel(fromDate,toDate);}}>
                                    {loadingExcel ? "Loading..." : "Save As Excel"} <Fa.FaDownload className="ms-2"/>
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    );
    
};

export default ReceiptMadeWithinPeriod;