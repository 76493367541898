import { format, subDays } from "date-fns";
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import storage from "../Services/Storage";
import { Endpoint, createAPIEndpoint } from "../Services/DebitCreditNote";
import Axios from "../api/Axios";
let from_date;
let to_date;

const getAllTableDataURL = "DebitCreditNote/GetAllDebitCreditNotes?";
const GetDrCrPrintURL = "DebitCreditNote/DebitCreditMultiPrint?";
const DrCrCancelURL = "DebitCreditNote/DebitCreditNoteCancel";

const DebitCreditNoteContext = React.createContext();
const DebitCreditNoteProvider = ({ children }) => {
    const noteTypes = [
        { Name: "Debit Note", Value: "Deb" },
        { Name: "Credit Note", Value: "Cre" },
    ];
    const date1 = subDays(new Date(), 365);
    const currentDay = format(date1, "yyyy-MM-dd");
    const auth = storage.getLocal("user");
    const [selectedNote, setSelectedNote] = useState("Cre");
    const [partyName, setPartyName] = useState("");
    const [partyNameList, setPartyNameList] = useState([]);
    const [division, setDivision] = useState("");
    const [reason, setReason] = useState("");
    const [reasonList, setReasonList] = useState([]);
    const [invoiceNo, setInvoiceNo] = useState("");
    const [invoiceDate, setInvoiceDate] = useState("");
    const [salesExecutive, setSalesExecutive] = useState("");
    const [salesExecutiveList, setSalesExecutiveList] = useState([]);
    const [printMessage, setPrintMessage] = useState("");
    const [remarks, setRemarks] = useState("");
    const [loading, setLoading] = useState(false);
    const [accountName, setAccountName] = useState("");
    const [accountNameList, setAccountNameList] = useState([]);
    const [amount, setAmount] = useState("");
    const [type, setType] = useState("");
    const [typeList, setTypeList] = useState([]);
    const [taxCode, setTaxCode] = useState("");
    const [taxCodeList, setTaxCodeList] = useState([]);
    const [schemeCode, setSchemeCode] = useState("");
    const [schemeName, setSchemeName] = useState("");
    const [allDebitCreditNotes, setAllDebitCreditNotes] = useState([]);
    const [schemeNameList, setSchemeNameList] = useState([]);
    const [noteList, setNoteList] = useState([]);
    const [showAllChecked, setShowAllChecked] = useState(false);
    const [partyNameSuggestion, setPartyNameSuggestion] = useState(null);
    const [reasonListSuggestion, setReasonListSuggestion] = useState(null);
    const [salesExecutiveSuggestion, setSalesExecutiveSuggestion] =
    useState(null);
    const [accountNameSuggestion, setAccountNameSuggestion] = useState(null);
    const [taxCodeSuggestion, setTaxCodeSuggestion] = useState(null);
    const [schemeSuggestion, setSchemeSuggestion] = useState(null);
    const [popupVisible, setPopupVisible] = useState(false);
    const [partyCode, setPartyCode] = useState("");
    const [addNewPopupData, setAddNewPopupData] = useState([]);
    const [allDebitCreditTable, setAllDebitCreditTable] = useState([]);
    const [popupFormClear, setPopupFormClear] = useState(false);
    const [rowNo, setRowNo] = useState(0);
    const [schemeNameVisible, setSchemeNameVisible] = useState(false);
    const [reasonListReset, setReasonListReset] = useState(false);
    const [drCrHeaderData, setDrCrHeaderData] = useState("");
    const [headerFlag, setHeaderFlag] = useState(true);
    const [taxCodeVisible, setTaxCodeVisible] = useState(false);
    // const[detailTablePayload,setDetailTablePayload]=useState([]);
    const [divnId, setDivnId] = useState("");
    const [saveDRCRLoader, setSaveDRCRLoader] = useState(false);
    const [selectedRowData, setSelectedRowData] = useState([]);
    const [isRowEdit, setIsRowEdit] = useState(false);
    const [taxcodeReset, setTaxCodeReset] = useState(false);
    const [accountNameDefault, setAccountNameDefault] = useState("");
    const [formResetPartyName, setFormResetPartyName] = useState(false);
    const [reasonReset, setReasonReset] = useState(false);
    const [salesExReset, setSalesExReset] = useState(false);
    const [crdrnote, setCrdrnote] = useState("");
    const [partycd, setPartycd] = useState("");
    const [response, setResponse] = useState("");
    const [divisionsave, setDivisionsave] = useState("");
    const [allDebitCreditTableSave, setAllDebitCreditTableSave] = useState([]);
    const [saveDisablebtn, setSaveDisablebtn] = useState(false);
    const [onChangeSEName, setOnChangeSEName] = useState();
    // const [salesExReset, setSalesExReset] = useState(false);

    const [filters, setFilters] = useState({
        DOCUMENT_NO: "",
        PARTY_NAME: "",
        CREATED_BY: "",
    });
    const [filterDRCRData, setFilterDRCRData] = useState([]);
    // const [filterDRCRDatas, setFilterDRCRDatas] = useState([]);
    const [loader, setLoader] = useState(true);
    const [crDrType, setCrDrType] = useState("Cre");
    const [drCrViewData, setDrCrViewData] = useState("");
    const [mod_array, setMod_array] = useState([]);
    const [printData, setPrintData] = useState();
    const [printPopup, setPrintPopup] = useState(false);
    const [cancelPopup, setCancelPopup] = useState(false);
    const [cancelRowDetails, setCancelRowDetails] = useState();
    const currentDate = format(new Date(), "dd/MM/yyyy");
    const date = subDays(new Date(), 365);
    const sevenDays = format(date, "dd/MM/yyyy");

    useEffect(() => {
        createAPIEndpoint()
            .get(
                `${Endpoint.GetAllPartyList}?CICode=${auth.CICode}&CompId=${auth.CompId}`
            )
            .then((res) => {
                setPartyNameList(res.data);
            })
            .catch((error) => {
                console.log(error);
            });

        createAPIEndpoint()
            .get(
                `${Endpoint.GetAllEmployeesList}?CICode=${auth.CICode}&CompId=${auth.CompId}`
            )
            .then((res) => {
                setSalesExecutiveList(res.data);
            })
            .catch((error) => {
                console.log(error);
            });
        //Debit  credit popup module 18-08-2023-----------------------

        createAPIEndpoint()
            .get(`${Endpoint.GetAllAccountName}?CompId=${auth.CompId}`)
            .then((res) => {
                setAccountNameList(res.data);
                const reasonType = selectedNote == "Cre" ? "CNC" : "DNC";
                const filterAccName = res.data.filter(
                    (val) => val.ACCT_CODE == reasonType
                );

                setAccountNameDefault(filterAccName[0]);
            })
            .catch((error) => {
                console.log(error);
            });

        createAPIEndpoint()
            .getDefaultValue(
                `${Endpoint.GetDefaultValue}?CompId=${auth.CompId}&defId=1`
            )
            .then((res) => {
                setDivision(res.data.Value);
                setDivnId(res.data.Id);
                createAPIEndpoint()
                    .get(
                        `${Endpoint.GetAllMappedSchemeData}?CICode=${auth.CICode}&strDivnId=${res.data.Id}`
                    )
                    .then((res) => {
                        setSchemeNameList(res.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error);
            });

        getAllDRCRPageLoad();
    }, []);

    const getAllDRCRPageLoad = () => {
        setLoader(true);
        createAPIEndpoint()
            .get(
                `${Endpoint.GetAllDebitCreditNotes}?userId=${auth.UserId}&CICode=${
                    auth.CICode
                }&fromDate=${sevenDays}&toDate=${currentDate}&strTag=${
                    showAllChecked ? "Y" : "N"
                }&strDocType=Cre&CompId=${auth.CompId}`
            )
            .then((res) => {
                setAllDebitCreditNotes(res.data);
                setFilterDRCRData(res.data);
                // setFilterDRCRDatas(res.data);

                setLoader(false);
            })
            .catch((error) => {
                console.log(error);
                setLoader(false);
            });
    };

    const findDateCrDr = (e, fromDate, toDate, crdrtype) => {
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");
        from_date = format(fDate, "yyyy/MM/dd");
        to_date = format(tDate, "yyyy/MM/dd");

        if (from_date > to_date) {
            toast.warn("from_date should be equal to or less To_date", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                autoClose: 2000,
            });
            return allDebitCreditNotes;
        } else if (from_date < "2010-01-01") {
            toast.warn("from_date should be greater than or equal to 01-01-2010", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                autoClose: 2000,
            });
            return allDebitCreditNotes;
        }
        GetAllDebitCreditNotes(fromDateRange, toDateRange, crdrtype);

        e.preventDefault();
    };

    const GetAllDebitCreditNotes = async (fromDate, toDate, crdrtype) => {
        setLoader(true);
        try {
            const URL = `${getAllTableDataURL}userId=${auth.UserId}&CICode=${
                auth.CICode
            }&fromDate=${fromDate}&toDate=${toDate}&strTag=${
                showAllChecked ? "Y" : "N"
            }&strDocType=${crdrtype}&CompId=${auth.CompId}`;
            const res = await Axios.get(URL);

            setAllDebitCreditNotes(res.data);
            setFilterDRCRData(res.data);
            // setFilterDRCRDatas(res.data);
            setLoader(false);
        } catch (error) {
            console.log(error);
            setLoader(false);
        }
    };
    const handleChange = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setFilters({ ...filters, [id]: value });
    };
    const PartyNameHandleChange = async (val) => {
        setFormResetPartyName(false);
        setReasonListReset(true);
        if ((val?.MASK_PARTY_ID ?? 0) === 0) {
            setReasonList([]);
            setPartyCode("");
            setOnChangeSEName();
        } else {
      
            createAPIEndpoint()
                .get(
                    `${Endpoint.GetAllReasonsList}?CompId=${auth.CompId}&strReasonTypId=${selectedNote}&strdisttag=${auth.DistTag}&strcustid=${val.MASK_PARTY_ID}`
                )
                .then((res) => {
                    let filtered_reason = res.data.filter((val) => {
                        return val.MASK_REASON_ID !== "REAS/22|C";
                    });
                    setReasonList(filtered_reason);
                    setPartyCode(val.PARTY_CODE);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };
    const schemeNameHandleChange = (val) => {
        if (val?.MASK_SCHM_ID ?? 0) {
            setSchemeCode(val.CODE);
        } else {
            setSchemeCode("");
        }
    };
    useEffect(() => {
        let tempSalesOrder = [...allDebitCreditNotes];

        if (filters.DOCUMENT_NO) {
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.DRCR_NO.toLocaleLowerCase().includes(
                    filters.DOCUMENT_NO.toLocaleLowerCase()
                );
            });
        }
        if (filters.PARTY_NAME) {
            //
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.PARTY_NAME.toLocaleLowerCase().includes(
                    filters.PARTY_NAME.toLocaleLowerCase()
                );
            });
        }
        if (filters.CREATED_BY) {
            //
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.GENERATED_BY.toLocaleLowerCase().includes(
                    filters.CREATED_BY.toLocaleLowerCase()
                );
            });
        }
        setFilterDRCRData(tempSalesOrder);
    // setFilterDRCRatas(tempSalesOrder);
    }, [filters]);

    const PartyNameAutoComplete = (e) => {
        setTimeout(() => {
            let _filteredPartyName;
            if (!e.query.trim().length) {
                _filteredPartyName = [...partyNameList];
            } else {
                _filteredPartyName = partyNameList.filter((partyName) => {
                    return (
                        partyName.PARTY_CODE.toLowerCase().startsWith(
                            e.query.toLowerCase()
                        ) ||
            partyName.PARTY_NAME.toLowerCase().startsWith(e.query.toLowerCase())
                    );
                });
            }
            setPartyNameSuggestion(_filteredPartyName);
        }, 250);
    };
    const ReasonListAutoComplete = (e) => {
        setTimeout(() => {
            let _filteredReason;
            if (!e.query.trim().length) {
                _filteredReason = [...reasonList];
            } else {
                _filteredReason = reasonList.filter((val) => {
                    return (
                        val.REASON_CODE.toLowerCase().startsWith(e.query.toLowerCase()) ||
            val.REASON_DESC.toLowerCase().startsWith(e.query.toLowerCase())
                    );
                });
            }
            setReasonListSuggestion(_filteredReason);
        }, 250);
    };
    const SalesExListAutoComplete = (e) => {
        setTimeout(() => {
            let _filteredSalesEx;
            if (!e.query.trim().length) {
                _filteredSalesEx = [...salesExecutiveList];
            } else {
                _filteredSalesEx = salesExecutiveList.filter((val) => {
                    return (
                        val.CODE.toLowerCase().startsWith(e.query.toLowerCase()) ||
            val.NAME.toLowerCase().startsWith(e.query.toLowerCase())
                    );
                });
            }
            setSalesExecutiveSuggestion(_filteredSalesEx);
        }, 250);
    };
    const AccountNameAutoComplete = (e) => {
        setTimeout(() => {
            let _filtered;
            if (!e.query.trim().length) {
                _filtered = [...accountNameList];
            } else {
                _filtered = accountNameList.filter((val) => {
                    return (
                        val.ACCT_CODE.toLowerCase().startsWith(e.query.toLowerCase()) ||
            val.ACCT_NAME.toLowerCase().startsWith(e.query.toLowerCase())
                    );
                });
            }
            setAccountNameSuggestion(_filtered);
        }, 250);
    };
    const TaxCodeAutoComplete = (e) => {
        setTimeout(() => {
            let _filtered;
            if (!e.query.trim().length) {
                _filtered = [...taxCodeList];
            } else {
                _filtered = taxCodeList.filter((val) => {
                    return (
                        val.TAX_CODE.toLowerCase().startsWith(e.query.toLowerCase()) ||
            val.TAX_DESC.toLowerCase().startsWith(e.query.toLowerCase())
                    );
                });
            }
            setTaxCodeSuggestion(_filtered);
        }, 250);
    };
    const SchemeAutoComplete = (e) => {
        setTimeout(() => {
            let _filtered;
            if (!e.query.trim().length) {
                _filtered = [...schemeNameList];
            } else {
                _filtered = accountNameList.filter((val) => {
                    return (
                        val.ACC_CODE.toLowerCase().startsWith(e.query.toLowerCase()) ||
            val.ACC_NAME.toLowerCase().startsWith(e.query.toLowerCase())
                    );
                });
            }
            setSchemeSuggestion(_filtered);
        }, 250);
    };
    const handleReasonSelected = (e) => {
        setReasonReset(false);
        setSchemeNameVisible(false);
        setReasonListReset(false);
        // if ((e.value?.REASON_CODE ?? 0) === 0||(!e.value.REASON_CODE=="SCHEME")) {
        //     setSchemeNameVisible(false);
        // }
        // if (e.value?.REASON_CODE??0=="SCHEME")
        // {
        //     setSchemeNameVisible(true);
        // }
        // else
        // {
        //     setSchemeNameVisible(false);
        // }
        if (e.value?.REASON_CODE ?? 0 == "SCHEME") {
            if (e.value.REASON_CODE == "SCHEME") {
                setSchemeNameVisible(true);
            }
        }
    };
    const ViewDrCrNote = async (MASK_DRCR_HDR_ID, drcrType) => {
        setLoader(true);
        createAPIEndpoint()
            .get(
                `${Endpoint.ViewDrCr}?CICode=${auth.CICode}&DRCR_TAG=${drcrType}&HdrID=${MASK_DRCR_HDR_ID}&CompId=${auth.CompId}`
            )
            .then((res) => {
                setDrCrViewData(res.data);
                setLoader(false);
            })
            .catch((error) => {
                console.log(error);
                setLoader(false);
            });
    };

    const printDrCrCall = async (headerIdArray) => {
        setLoader(true);
        const payload = {
            ciCode: auth.CICode,
            compId: auth.CompId,
            creditDebitIdArr: headerIdArray,
        };

        // Create Invoice
        try {
            await Axios.post(GetDrCrPrintURL, JSON.stringify(payload)).then(
                (response) => {
                    let modified = [];
                    let res = response.data.ListModelDebitCreditPDFHeaderDetailsMain;
                    for (let i = 0; i < res.length; i++) {
                        const print = res[i];
                        let mod = 0;
                        mod = Math.ceil(print.listModelDebitCreditPDFDetails.length / 15);
                        let mod_arrays = Array.from(Array(mod + 1).keys()).slice(1);
                        modified.push(mod_arrays);
                    }
                    setMod_array(modified);
                    setPrintData(res);
                    setPrintPopup(true);
                    setLoader(false);
                }
            );
        } catch (error) {
            setLoading(false);
            toast.error(error.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
            setLoader(false);
        }
    };

    const DrCrNoteCancel = async (row) => {
        setLoader(true);
        const payload = {
            ciCode: auth.CICode,
            userId: auth.UserId,
            compId: auth.CompId,
            masK_DRCR_HDR_NO: row.MASK_DRCR_HDR_ID,
            masK_STATUS: row.DIS_STATUS,
            masK_GENERATED_BY: row.GENERATED_BY,
            masK_DRCR_NO: row.DRCR_NO,
            masK_DRCR_DATE: row.DRCR_DATE,
            masK_DocType: row.MASK_DRCR_TAG == "CV" ? "Cre" : "Deb",
            masK_TOT_DRCR_AMT: row.MASK_TOT_DRCR_AMT.toString(),
        };
        if (cancelPopup) {
            try {
                Axios.post(DrCrCancelURL, JSON.stringify(payload)).then((res) => {
                    setLoader(false);
                    toast.success(res.data.Message, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });
                    getAllDRCRPageLoad();
                });
            } catch (error) {
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,

                    pauseOnFocusLoss: false,

                    closeButton: false,

                    autoClose: 3000,
                });

                console.log(error);
            }
        }
    };

    const handleDrCrSave = async () => {
        setSaveDRCRLoader(true);

        //
        const creditnoteDate = format(drCrHeaderData.creditnotedate, "dd/MM/yyyy");
        const invoicedate = format(drCrHeaderData.invoicedate, "dd/MM/yyyy");
        const totalAmt = allDebitCreditTable.reduce((a, b) => {
            return Number(a) + Number(b.amount);
        }, 0);

        //created payload for header---------------------
        let objDrCrSAVE = "";
        const objDebCreHdr = {
            comp_Id: auth.CompId,
            divn_Id: divnId,
            drcR_NO: "",
            notE_DATE: creditnoteDate,
            toT_AMOUNT: totalAmt.toString(),
            adJ_AMOUNT: "",
            emP_ID: drCrHeaderData.salesexecutive.MASK_EMP_ID,
            cusT_ID: drCrHeaderData.partyname.MASK_PARTY_ID,
            prnT_MSG: drCrHeaderData.printmessage,
            remarks: drCrHeaderData.remarks,
            partY_NAME: drCrHeaderData.partyname.PARTY_NAME,
            invoicE_NO: drCrHeaderData.invoiceno,
            invoicE_DATE: invoicedate,
            reaS_ID: drCrHeaderData.reason.MASK_REASON_ID,
            reaS_DESC: drCrHeaderData.reason.REASON_DESC,
            useR_ID: auth.UserId,
            brancH_ID: "",
        };
        // created payload for detail--------------------------------
        const objDebCreDtl = allDebitCreditTable.map((val) => {
            return {
                MASK_DRCRD_SRL_NO: val.slno.toString(),
                DIVISION_NAME: "",
                COST_CENTER_NAME: "",
                ACCT_NAME: val.accountname?.ACCT_NAME ?? "",
                DRCRD_DRCR_AMT: val.amount.toString(),
                MASK_DRCRD_ADJ_AMT: "",
                MASK_DRCRD_DIVN_ID: divnId,
                MASK_DRCRD_COST_CENTRE_ID: "",
                MASK_DRCRD_ACCT_ID: val.accountname?.MASK_ACCT_ID ?? "",
                MASK_TAX_ID: val.taxcode?.MASK_TAX_ID ?? "",
                MASK_TAX_DESC: val.taxcode?.TAX_DESC ?? "",
                MASK_SCHM_ID: val.schemename?.MASK_SCHM_ID ?? "",
                SCHM_CODE: val.schemename?.CODE ?? "",
                SCHM_NAME: val.schemename?.DESCRIPTION ?? "",
            };
        });
        objDrCrSAVE = {
            CICode: auth.CICode,
            UserId: auth.UserId,
            CompId: auth.CompId,
            party_Code: partyCode,
            reason: drCrHeaderData.reason.REASON_DESC,
            sales_Executive: drCrHeaderData.salesexecutive.NAME,
            note_type: selectedNote,
            objDebCreHdr,
            objDebCreDtl,
        };
        // console.log("objDrCrSAVE",objDrCrSAVE);
        // return;

        createAPIEndpoint()
            .post(`${Endpoint.SAVEDECR}`, objDrCrSAVE)
            .then((res) => {
                if (res.data.Status == "Success") {
                    toast.success(res.data.Message, {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    });

                    setResponse(res.data);
                    setCrdrnote(res.data.DRCR_NO);
                    setPartycd(res.data.clsSDebCre.Party_Code);
                    setAllDebitCreditTableSave(res.data.clsSDebCre.objDebCreDtl);

                    setDivisionsave(division);
                    setSaveDisablebtn(true);
                    // setDivision();
                    // drCrHeaderData.partyname.PARTY_NAME = res.data.clsSDebCre.Party_Code;
                    //
                    // PartyNameHandleChange(drCrHeaderData.partyname);
                    // setDrCrHeaderData();
                }
                setSaveDRCRLoader(false);
                setFormResetPartyName(true);
                setSalesExReset(true);
                setReasonReset(true);
                // setAllDebitCreditTable([]);
            })
            .catch((error) => {
                toast.error(`${error.response.data.Message}`, {
                    position: toast.POSITION.TOP_CENTER,

                    pauseOnFocusLoss: false,

                    closeButton: false,

                    autoClose: 3000,
                });
                setSaveDRCRLoader(false);
                console.log(error);
            });
    };
    const handleRowSelected = (data) => {
        setIsRowEdit(true);
        setSelectedRowData(data);
        setPopupVisible(true);
    };
    const handleTypeChange = (val) => {
        if (val.name == "Tax") {
            createAPIEndpoint()
                .get(`${Endpoint.GetAllTaxCode}?CICode=${auth.CICode}`)
                .then((res) => {
                    setTaxCodeList(res.data);
                })
                .catch((error) => {
                    console.log(error);
                });
            setTaxCodeVisible(true);
            setTaxCodeReset(false);
        } else {
            setTaxCodeVisible(false);
            setTaxCodeReset(true);
            setTaxCodeList([]);
        }
    };

    const divisionchange = () => {
        createAPIEndpoint()
            .getDefaultValue(
                `${Endpoint.GetDefaultValue}?CompId=${auth.CompId}&defId=1`
            )
            .then((res) => {
                setDivision(res.data.Value);
            });
    };
    const handleDrCrChange = (val) => {
        divisionchange();
        createAPIEndpoint()
            .get(`${Endpoint.GetAllAccountName}?CompId=${auth.CompId}`)
            .then((res) => {
                setAccountNameList(res.data);
                const reasonType = val == "Cre" ? "CNC" : "DNC";
                const filterAccName = res.data.filter(
                    (val) => val.ACCT_CODE == reasonType
                );

                setAccountNameDefault(filterAccName[0]);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    return (
        <DebitCreditNoteContext.Provider
            value={{
                currentDay,
                noteTypes,
                selectedNote,
                setSelectedNote,
                partyName,
                setPartyName,
                partyNameList,
                setPartyNameList,
                division,
                setDivision,
                reason,
                setReason,
                reasonList,
                setReasonList,
                invoiceNo,
                setInvoiceNo,
                invoiceDate,
                setInvoiceDate,
                salesExecutive,
                setSalesExecutive,
                salesExecutiveList,
                setSalesExecutiveList,
                printMessage,
                setPrintMessage,
                remarks,
                setRemarks,
                loading,
                setLoading,
                rowNo,
                setRowNo,
                accountName,
                setAccountName,
                accountNameList,
                setAccountNameList,
                amount,
                setAmount,
                type,
                setType,
                typeList,
                setTypeList,
                taxCode,
                setTaxCode,
                taxCodeList,
                setTaxCodeList,
                schemeCode,
                setSchemeCode,
                schemeName,
                setSchemeName,
                schemeNameList,
                setSchemeNameList,
                noteList,
                setNoteList,
                findDateCrDr,
                setShowAllChecked,
                handleChange,
                allDebitCreditNotes,
                loader,
                setLoader,
                filterDRCRData,
                getAllDRCRPageLoad,
                ViewDrCrNote,
                crDrType,
                setCrDrType,
                drCrViewData,
                printDrCrCall,
                printPopup,
                setPrintPopup,
                mod_array,
                setMod_array,
                printData,
                DrCrNoteCancel,
                setCancelPopup,
                cancelPopup,
                cancelRowDetails,
                setCancelRowDetails,
                partyNameSuggestion,
                PartyNameAutoComplete,
                PartyNameHandleChange,
                reasonListSuggestion,
                ReasonListAutoComplete,
                SalesExListAutoComplete,
                salesExecutiveSuggestion,
                accountNameSuggestion,
                AccountNameAutoComplete,
                taxCodeSuggestion,
                TaxCodeAutoComplete,
                schemeSuggestion,
                SchemeAutoComplete,
                popupVisible,
                setPopupVisible,
                partyCode,
                schemeNameHandleChange,
                addNewPopupData,
                setAddNewPopupData,
                allDebitCreditTable,
                setAllDebitCreditTable,
                popupFormClear,
                setPopupFormClear,
                schemeNameVisible,
                setSchemeNameVisible,
                handleReasonSelected,
                reasonListReset,
                drCrHeaderData,
                setDrCrHeaderData,
                handleDrCrSave,
                headerFlag,
                setHeaderFlag,
                taxCodeVisible,
                setTaxCodeVisible,
                saveDRCRLoader,
                handleRowSelected,
                isRowEdit,
                selectedRowData,
                taxcodeReset,
                setTaxCodeReset,
                handleTypeChange,
                setIsRowEdit,
                accountNameDefault,
                handleDrCrChange,
                formResetPartyName,
                reasonReset,
                setSalesExReset,
                salesExReset,
                setPartyCode,
                crdrnote,
                partycd,
                setPartycd,
                setCrdrnote,
                response,
                allDebitCreditTableSave,
                setAllDebitCreditTableSave,
                divisionsave,
                setDivisionsave,
                setSaveDisablebtn,
                saveDisablebtn,
                divisionchange,
                onChangeSEName,
                setOnChangeSEName
            }}
        >
            {children}
        </DebitCreditNoteContext.Provider>
    );
};
// };

export const useDebitCreditNoteContext = () => {
    return useContext(DebitCreditNoteContext);
};

export { DebitCreditNoteProvider, DebitCreditNoteContext };
