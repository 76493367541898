// import { Dropdown } from "bootstrap";
import { format  } from "date-fns";
import { Dropdown } from "primereact/dropdown";
// import { RadioButton } from "primereact/radiobutton";
import React, { useEffect, useState } from "react";
import * as Fa from "react-icons/fa";
import {
    createAPIEndpointInvoiceExcel,
    Endpoint,
} from "../../../Services/ReportsAll/InvoiceDataToExcel";
import storage from "../../../Services/Storage";
import { toast } from "react-toastify";
import XLSX from "xlsx";


const InvoiceDataExcel = () => {
    // const categories = [
    //     { name: "Till Yesterday", key: "Y" },
    //     { name: "As of Today", key: "T" },
    // ];

    // const currentDate = format(new Date(), "yyyy-MM-dd");
    // const date = subDays(new Date(), 7);
    // const sevenDays = format(date, "yyyy-MM-dd");

    // const [selectedCategory, setSelectedCategory] = useState(categories[0]);
    const [selectedQuarter, setSelectedQuarter] = useState();
    const [quarters, setQuarters] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    // const [todayDisable, setTodayDisable] = useState(false);
    const auth = storage.getLocal("user");
    const [loadingExcel, setLoadingExcel] = useState(false);
    const [maxQuarterDate, setMaxQuarterDate] = useState("");
    const [minQuarterDate, setMinQuarterDate] = useState("");
    // const [firstQuarterData, setfirstQuarterData] = useState("");
    // useEffect(() => {
    //     console.log("selectedCategory", selectedCategory);
    //     if (selectedCategory.key === "T") {
    //         setTodayDisable(true);
    //         let today = new Date();
    //         let quarter = Math.floor((today.getMonth() + 3) / 3);
    //         let getcurrentquarter = getQuarterDates(quarter, today.getFullYear());
    //         console.log("getcurrentquarter", getcurrentquarter);
    //         setFromDate(format(new Date(), "yyyy-MM-dd"));
    //         setToDate(format(new Date(), "yyyy-MM-dd"));
    //         setSelectedQuarter(firstQuarterData);
    //     } else {
    //         setTodayDisable(false);
    //         getQuarterDataFirst();
    //     }
    // }, [selectedCategory]);

    useEffect(() => {
        getQuarterDataFirst();
    // getquarterApi();
    }, []);

    const getQuarterDataFirst = () => {
        getquarterApi();
    };


    const getquarterApi = ()=>{
        createAPIEndpointInvoiceExcel()
            .get(`${Endpoint.GetQtrData}`)
            .then((response) => {
                // console.log("data000", response.data.Data);
                setQuarters(response.data.Data);
                setSelectedQuarter(response.data.Data[0]);
                handlechangeDateQuarter(response.data.Data[0]);
                // setfirstQuarterData(response.data.Data[0]);
            })
            .catch(() => {
                //   toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                //     position: toast.POSITION.TOP_CENTER,
                //     pauseOnFocusLoss: false,
                //     closeButton: false,
                //     autoClose: 8000,
                //   });
                //   setLoadingExcel(false);
            });
    };

    const getQuarterDates = (quarter, year) => {
        let startDate, endDate;
        const today = new Date();
        switch (quarter) {
        case 1:
            startDate = format(new Date(year, 0, 1), "yyyy-MM-dd");
            endDate = format(new Date(year, 2, 31), "yyyy-MM-dd");
            break;
        case 2:
            startDate = format(new Date(year, 3, 1), "yyyy-MM-dd");
            endDate = format(new Date(year, 5, 30), "yyyy-MM-dd");
            break;
        case 3:
            startDate = format(new Date(year, 6, 1), "yyyy-MM-dd");
            endDate = format(new Date(year, 8, 30), "yyyy-MM-dd");
            break;
        case 4:
            startDate = format(new Date(year, 9, 1), "yyyy-MM-dd");
            endDate = format(new Date(year, 11, 31), "yyyy-MM-dd");
            break;
        default:
            return null;
        }

        if (new Date(endDate).getTime() > new Date(today).getTime()) {
            endDate = format(new Date(today), "yyyy-MM-dd");
        }

        // console.log("endDate", endDate);

        return { fromDate: startDate, toDate: endDate };
    };
    let uniqueNo = "InvoiceDataExcel";
    const downloadExcelData = () => {


        if (new Date(fromDate).getTime() > new Date(toDate).getTime()) {
            toast.warn("FromDate should be less or equal to ToDate", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 8000,
            });
        }
        else{
            setLoadingExcel(true);
            createAPIEndpointInvoiceExcel()
                .get(
                    `${Endpoint.InvoiceDataExcel}?FromDate=${formatteddate(
                        fromDate
                    )}&ToDate=${formatteddate(toDate)}&CompId=${auth.CompId}`
                )
                .then((response) => {
                    if(response?.data?.Status=="Warning"){
                        toast.warning(`${response?.data?.Message}`, {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                        setLoadingExcel(false);
                    }
                    else{
                        if (response.data.Data.length > 0) {
                            let wb = XLSX.utils.book_new();
                            let ws = XLSX.utils.json_to_sheet(response.data.Data);
                            XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
                            XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
                        } else {
                            toast.warning("No Data Found for this Quarter", {
                                position: toast.POSITION.TOP_CENTER,
                                pauseOnFocusLoss: false,
                                closeButton: false,
                                autoClose: 2000,
                            });
                        }
                        setLoadingExcel(false);
                    }   
                })
                .catch((error) => {
                    console.log("error",error);
                    toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 3000,
                    });
                    setLoadingExcel(false);
                });
        }

       
    };

    const formatteddate = (datestring) => {
        let parts = datestring.split("-");
        let date = new Date(parts[0], parts[1] - 1, parts[2]);
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        day = day < 10 ? "0" + day : day;
        month = month < 10 ? "0" + month : month;
        let formattedDate = `${day}/${month}/${year}`;
        return formattedDate;
    };

    const handlechangeDateQuarter = (value) => {
        // console.log("value", value);
        let value1 = value.QTR_DESC;
        setSelectedQuarter(value);
        let splitdata = value1.split(" ");
        let getquarter = splitdata[1].replace(",", "");
        let getyear = splitdata[2];
        let getquaterdata = getQuarterDates(parseInt(getquarter), getyear);
        setFromDate(getquaterdata.fromDate);
        setToDate(getquaterdata.toDate);
        setMinQuarterDate(getquaterdata.fromDate);
        setMaxQuarterDate(getquaterdata.toDate);
    };

    const QuarterTemplate = (quarter)=>{
        // console.log("quarter",quarter);
        return (
            <>
                {quarter.QTR_DESC}
            </>
        );
    };

    //   console.log("getQuarterDates", getQuarterDates(1, 2024));

    return (
        <div className="col-12 pt-3">
            <h3 className="py-2">Invoice Data to Excel</h3>
            {/* <div className="col-lg-12 col-md-12 col-sm-12 my-3 d-flex justify-content-center">
                {categories.map((category) => {
                    return (
                        <div key={category.key} className="flex align-items-center mx-2">
                            <RadioButton
                                inputId={category.key}
                                name="category"
                                value={category}
                                onChange={(e) => setSelectedCategory(e.value)}
                                checked={selectedCategory.key === category.key}
                            />
                            <label htmlFor={category.key} className="mx-2">
                                {category.name}
                            </label>
                        </div>
                    );
                })}
            </div> */}
            <section className="sales-box">
                <form>
                    <div className="row">
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
                            <label className="form-label">Quarter</label>
                            <Dropdown
                                inputId="queter"
                                name="queter"
                                value={selectedQuarter}
                                onChange={(e) => handlechangeDateQuarter(e.value)}
                                options={quarters}
                                optionLabel = "QTR_DESC"
                                itemTemplate={QuarterTemplate}
                                // disabled={todayDisable}
                            />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
                            <label className="form-label">From Date</label>
                            <input
                                type="date"
                                value={fromDate}
                                max={maxQuarterDate}
                                min={minQuarterDate}
                                className="form-control"
                                name="date-field"
                                onChange={(e) => setFromDate(e.target.value)}
                                // disabled={todayDisable}
                            />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 mb-3">
                            <label className="form-label">To Date</label>
                            <input
                                value={toDate}
                                type="date"
                                max={maxQuarterDate}
                                min={minQuarterDate}
                                className="form-control"
                                name="date-field"
                                onChange={(e) => setToDate(e.target.value)}
                                // disabled={todayDisable}
                            />
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12">
                            <div className="float-end mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={downloadExcelData}
                                    disabled={loadingExcel}
                                >
                                    {loadingExcel ? "Loading..." : "Save As Excel"}{" "}
                                    {loadingExcel ? (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <Fa.FaDownload className="ms-2" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    );
};

export default InvoiceDataExcel;
