import { format, subDays } from "date-fns";
import React, { useState, useContext, useEffect } from "react";
import { toast } from "react-toastify";
import Axios from "../api/Axios";
import storage from "../Services/Storage";
//import { useNavigate } from "react-router-dom";

let from_date;
let to_date;
//let mod = 0;

const GetGRNURL = "GRN/GetGRN?";
const GetGRNViewURL = "GRN/GetGRNView?";
const GetGRNProductURL = "GRN/GRNNetVol?";
const GetGRNPrintURL = "GRN/PrintMultiGRN?";
const DefaultVal_URL1 = "GRN/SuppInvTolarenceDefaultVal?";
const DefaultVal_URL2 = "GRN/GetEnvironmentalTag?";

const GRNSaveURL = "GRN/GRNSave";

const GRNContext = React.createContext();
const GRNProvider = ({ children }) => {
    const auth = storage.getLocal("user");
    const [GRNStatus, setGRNStatus] = useState("All");
    const [GRNData, setGRNData] = useState([]);
    const [filterGRNData, setFilterGRNData] = useState([]);
    const [filterGRNDatas, setFilterGRNDatas] = useState([]);
    const [productGRNData, setProductGRNData] = useState([]);
    const [netVolume, setNetVolume] = useState();
    const [printPopup, setPrintPopup] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [printInvoiceModalData, setPrintInvoiceModalData] = useState();
    const [defaultValue1, setDefaultValue1] = useState();
    const [defaultValue2, setDefaultValue2] = useState();
    const [printData, setPrintData] = useState();
    const [mod_array, setMod_array] = useState([]);
    const date = new Date();
    const GRNCurrentDate = format(date,"dd/MM/yyyy");
    // const navigate = useNavigate();

    const [filters, setFilters] = useState({
        GRN_NO: "",
        SUPP_NAME: "",
    });
    // const getGRN = async () => {
    //     const GRNURL = `${GetGRNURL}fromDate=${fromDate}&toDate=${toDate}&userId=${auth.UserId}&CICode=${auth.CICode}`;
    //     try{
    //         const res = await Axios.get(GRNURL);
    //          
    //     }
    //     catch(error){
    //          
    //     }
    // };

    const productListGRN = async (headerId) => {
        setLoading(true);
        try {
            const GRN_urls = `${GetGRNViewURL}CICode=${auth.CICode}&GRN_Hdr_Id=${headerId}`;
            const response = await Axios.get(GRN_urls);
            setProductGRNData(response.data);
            let netVolume = 0;

            for (let i = 0; i < response.data.length; i++) {
                let res = response.data[i];
                if (res.MASK_GRND_STK_TYPE_ID == "1") {
                    const GRN_urlProd = `${GetGRNProductURL}CICode=${auth.CICode}&PdtCode=${res.PROD_CODE}`;
                    const response = await Axios.get(GRN_urlProd);
                    netVolume += response.data[0].MASK_NET_VOL;
                }
            }
            setNetVolume(netVolume.toFixed(2));
            setLoading(false);
            getDefaultVal1();
            getDefaultVal2();


        } catch (error) {
            setLoading(false);

            toast.error(error, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        }
    };
    const getDefaultVal1 = async () => {
        const defaultVal_api1 = `${DefaultVal_URL1}CICode=${auth.CICode}&defId=${23}`;
        const defaultVal_Res1 = await Axios.get(defaultVal_api1);

        setDefaultValue1(defaultVal_Res1.data);
    };
    const getDefaultVal2 = async () => {
        const defaultVal_api2 = `${DefaultVal_URL2}CICode=${auth.CICode}&defId=${13}`;
        const defaultVal_Res2 = await Axios.get(defaultVal_api2);

        setDefaultValue2(defaultVal_Res2.data);
    };
    const saveGRNCall = async (grnDatas, productGRNData) => {
        setLoading(true);

        const clsSGRNHDtlData = {
            "MASK_SuppInvTolerance": defaultValue1,
            "MASK_GRN_ID": grnDatas.MASK_GRN_ID,
            "GRN_NO": "",
            "GRN_DATE": GRNCurrentDate,
            "COMP_ID": auth.CompId,
            "MASK_GRN_TYPE": "N",
            "IS_DIRECT_GRN": "Y",
            "MASK_GRN_SUPP_INV_NO": grnDatas.MASK_GRN_SUPP_INV_NO,
            "MASK_GRN_SUPP_INV_DATE": grnDatas.MASK_GRN_SUPP_INV_DATE,
            "MASK_PAYTERM_ID": grnDatas.MASK_PAY_TERM_ID,
            "MASK_TAXFORM_ID": grnDatas.MASK_TAX_FORM_ID,
            "WAREHOUSE_ID": grnDatas.MASK_WH_ID,
            "MASK_RECV_EMPID": grnDatas.MASK_GRN_RECV_EMP_ID==null?"":grnDatas.MASK_GRN_RECV_EMP_ID,
            "MASK_BASE_CURRENT_AMOUNT": String(grnDatas.MASK_GRN_TOT_AMT * grnDatas.MASK_EXCH_RATE),
            "TOT_AMOUNT": grnDatas.MASK_GRN_TOT_AMT,
            "MASK_SUPP_INV_AMOUNT": grnDatas.MASK_GRN_TOT_SUPP_INV_AMT,
            "MASK_SUPP_ID": grnDatas.MASK_SUPP_ID,
            "ADD1": grnDatas.MASK_ADD1==null?"":grnDatas.MASK_ADD1,
            "ADD2": grnDatas.MASK_ADD2==null?"":grnDatas.MASK_ADD2,
            "ADD3": grnDatas.MASK_ADD3==null?"":grnDatas.MASK_ADD3,
            "ADD4": grnDatas.MASK_ADD4==null?"":grnDatas.MASK_ADD4,
            "PIN_CODE": grnDatas.MASK_PIN,
            "RECV_EMP_ID": grnDatas.MASK_GRN_RECV_EMP_ID==null?"":grnDatas.MASK_GRN_RECV_EMP_ID,
            "RECV_DATE": grnDatas.MASK_GRN_RECV_DATE,
            "INSP_EMP_ID": grnDatas.MASK_GRN_INSP_EMP_ID==null?"":grnDatas.MASK_GRN_INSP_EMP_ID,
            "INSP_DATE": grnDatas.MASK_GRN_INSP_DATE,
            "GL_TAG": "N",
            "Status": grnDatas.MASK_STATUS,
            "USER_ID": auth.UserId,
            "PASS_NO": grnDatas.MASK_GRN_GATE_PASS_NO==null?"":grnDatas.MASK_GRN_GATE_PASS_NO,
            "CURRENCY_ID": grnDatas.MASK_CURRENCY_ID,
            "EXCH_RATE": String(grnDatas.MASK_EXCH_RATE),
            "ROUNDOFF_AMOUNT": grnDatas.MASK_GRN_ROUND_OFF_AMT,
            "GEOG_ID": grnDatas.MASK_GEOG_ID,
            "DIVN_ID": grnDatas.MASK_DIVN_ID,
            "PAYMENT_DATE": grnDatas.MASK_PAY_TERM_DATE,
            "SUMMERY_TAG": grnDatas.MASK_SUMMARY_PROC_TAG,
            "LOC_ID": "N",
            "ORIGINAL_LOC_ID": grnDatas.MASK_LOC_ID,
            "SUPP_NAME": grnDatas.SUPP_NAME,
            "TRADE_DISC_PERC": grnDatas.MASK_GRN_TRADE_DISC_PERC,
            "MOP_ID": grnDatas.MASK_MOP_ID,
            "TAX_AREA_ID": grnDatas.MASK_TAX_AREA_ID,
            "SUPP_INV_AMOUNT": grnDatas.MASK_GRN_TOT_SUPP_INV_AMT,
            "REF_DOC_TYPE": "",
            "ORI_GRN_NO": grnDatas.GRN_NO,
            "DIV_BATCH_TAG": grnDatas.MASK_EVALUATE_TAG,
            "PUR_ACC_ID": grnDatas.MASK_GRN_PUR_ACCT,
            "PUR_DISC_ACC_ID": grnDatas.MASK_GRN_PUR_DISCOUNT_ACCT,
            "ROUNDOFF_ACC_ID": grnDatas.MASK_GRN_ROUND_OFF_ACCT,
            "CFOP_ID": "",
            "MOP_DISC": grnDatas.MASK_PUR_DISC_PERC,
            "BRANCH_ID": "",
            "PAYTERM_TYPE": "N",
            "ESPECIE": grnDatas.MASK_ESPECIE,
            "SERIE": grnDatas.MASK_SERIE==null?"":grnDatas.MASK_SERIE,
            "BRANCHID": "",
            "INV_AUTO_GEN": "N",
            "IUKEY": "2",
            "EnvAutoAdvance": defaultValue2
        };
        const GRNBodyDetail = [];

        for (let i = 0; i < productGRNData.length; i++) {
            let prod = productGRNData[i];
            const GRNbodyDetl = {
                "POHD_NO": prod.POHD_NO==null?"":prod.POHD_NO,
                "POHD_DATE": prod.POHD_DATE==null?"":prod.POHD_DATE,
                "PROD_CODE": prod.PROD_CODE,
                "PROD_SMALL_DESC": prod.PROD_SMALL_DESC,
                "PROD_BATCH_NO": prod.PROD_BATCH_NO,
                "PRICE_LIST_NO": prod.PRICE_LIST_NO,
                "CDR_DISC": prod.CDR_DISC,
                "GRND_CASE_UOM_CODE": prod.GRND_CASE_UOM_CODE==null?"":prod.GRND_CASE_UOM_CODE,
                "CASE_FACTOR": prod.CASE_FACTOR.toString(),
                "GRND_CASE_RECV_QTY": prod.GRND_CASE_RECV_QTY,
                "GRND_PACK_RECV_QTY": prod.GRND_PACK_RECV_QTY,
                "GRND_PROD_PRICE": prod.GRND_PROD_PRICE,
                "GRND_PROD_BASE_PRICE": prod.GRND_PROD_PRICE,
                "TOT_ORD_QTY": prod.TOT_ORD_QTY,
                "TOT_RECV_QTY": prod.TOT_RECV_QTY,
                "GRND_SPLCON_TAX": prod.GRND_SPLCON_TAX,
                "EXTENTED_PRICE": prod.EXTENTED_PRICE,
                "MASK_GRND_PROD_VAL": prod.MASK_GRND_PROD_VAL,
                "MASK_GRND_PODT_ID": prod.MASK_GRND_PODT_ID==null?"":prod.MASK_GRND_PODT_ID,
                "MASK_PROD_ID": prod.MASK_PROD_ID,
                "MASK_PROD_DESC": prod.MASK_PROD_DESC,
                "MASK_PACK_UOM_CODE": prod.MASK_PACK_UOM_CODE,
                "MASK_GRND_TOT_TAX": prod.MASK_GRND_TOT_TAX,
                "MASK_GRND_TRADE_DISC": prod.MASK_GRND_TRADE_DISC,
                "MASK_GRND_VAL_BASE_CRNC": prod.MASK_GRND_VAL_BASE_CRNC,
                "MASK_SEQUENCE": prod.MASK_SEQUENCE,
                "MASK_DROP_QTY": prod.MASK_DROP_QTY,
                "MASK_TO_RECV_QTY": prod.MASK_TO_RECV_QTY,
                "MASK_OTHER_CHARGES": prod.MASK_OTHER_CHARGES,
                "MASK_GROSS_WEIGHT": prod.MASK_GROSS_WEIGHT,
                "MASK_TOT_RECV_QTY": prod.MASK_TOT_RECV_QTY,
                "MASK_FREIGHT_CHARGES": prod.MASK_FREIGHT_CHARGES,
                "MASK_STCK_OTHER_CHARGE": prod.MASK_STCK_OTHER_CHARGE,
                "MASK_STCK_FREIGHT_CHARGE": prod.MASK_STCK_FREIGHT_CHARGE,
                "MASK_PRICE_CALCULATED": prod.MASK_PRICE_CALCULATED,
                "MASK_SUBSTITUTE_PROD": prod.MASK_SUBSTITUTE_PROD,
                "MASK_GRND_ID": prod.MASK_GRND_ID,
                "MASK_GRND_TOT_VAT": prod.MASK_GRND_TOT_VAT,
                "MASK_GRND_MOP_DISC": prod.MASK_GRND_MOP_DISC,
                "MASK_GRND_SCHM_PROCESS_TAG": prod.MASK_GRND_SCHM_PROCESS_TAG,
                "MASK_GRND_CDR_DISC_PERC": prod.MASK_GRND_CDR_DISC_PERC,
                "MASK_GRND_CDR_DISC_TAG": prod.MASK_GRND_CDR_DISC_TAG,
                "MASK_PODT_PRICE_OR_TAG": prod.MASK_PODT_PRICE_OR_TAG,
                "MASK_CDR_DISC_APP_TO": prod.MASK_CDR_DISC_APP_TO,
                "MASK_CFOP_ID": prod.MASK_CFOP_ID==null?"":prod.MASK_CFOP_ID,
                "CFOP_CODE": prod.CFOP_CODE==null?"":prod.CFOP_CODE,
                "MASK_GRND_STK_TYPE_ID": prod.MASK_GRND_STK_TYPE_ID,
                "CFOP_DESCRIPTION": prod.CFOP_DESCRIPTION==null?"":prod.CFOP_DESCRIPTION,
                "MASK_CONVERSION_FACTOR": prod.MASK_CONVERSION_FACTOR,
                "MASK_MAPPED_PROD_BULK_TAG": prod.MASK_MAPPED_PROD_BULK_TAG==null?"":prod.MASK_MAPPED_PROD_BULK_TAG,
                "MASK_MAPPED_PROD_BULK": prod.MASK_MAPPED_PROD_BULK==null?"":prod.MASK_MAPPED_PROD_BULK,
                "MASK_PRICE_LIST_ID": prod.PRICE_LIST_NO,
                "PROD_ID": prod.MASK_PROD_ID,
                "QTY": prod.TOT_RECV_QTY

            };
            GRNBodyDetail.push(GRNbodyDetl);
        }


        const payload = {
            "CICode": auth.CICode,
            "UserId": auth.UserId,
            "MASK_GRN_ID": grnDatas.MASK_GRN_ID,
            "MASK_GRN_SUPP_INV_DATE": grnDatas.MASK_GRN_SUPP_INV_DATE,
            "GRN_DATE":GRNCurrentDate,
            "MASK_GRN_SUPP_INV_NO": grnDatas.MASK_GRN_SUPP_INV_NO,
            "MASK_GRN_TYPE": "N",
            "MASK_IUKey": "2",
            "MASK_STATUS": grnDatas.MASK_STATUS,
            "MASK_SUPP_ID": grnDatas.MASK_SUPP_ID,
            "MASK_TAX_FORM_ID": grnDatas.MASK_TAX_FORM_ID,
            "clsSGRNHDtl": clsSGRNHDtlData,
            "GRNbodyDetl": GRNBodyDetail,

        };
        //   return
        Axios.post(GRNSaveURL, JSON.stringify(payload)).then((response) => {
            setLoading(false);
            if (response.data.Status == "Success") {
                toast.success(response.data.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
            }
            else {
                toast.error(response.data.Message, {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 2000,
                });
            }

            grnDatas.MASK_GRN_TYPE = "N";
            grnDatas.GRN_NO = response.data.GRN_NO;
            grnDatas.GRN_DATE = response.data.clsSGRN.GRN_DATE;
            // navigate(-1);
        }).catch((error)=>{
            setLoading(false);
            toast.error(error.response.data.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        });
        

    };



    // headerId
    const printGRNCall = async (headerIdArray) => {
        setLoading(true);
        const payload = {
            "CICode": auth.CICode,
            "UserId": auth.UserId,
            "grnIdLArray": headerIdArray
        };
        // Create Invoice
        try {
            await Axios.post(GetGRNPrintURL, JSON.stringify(payload)).then((response) => {
                let modified = [];
                let res = response.data.ListModelGRNPrintMain;
                for (let i = 0; i < res.length; i++) {
                    const print = res[i];
                    let mod = 0;
                    mod = Math.ceil(print.ListModelGRNPrintProdDetails.length / 10);
                    let mod_arrays = Array.from(Array(mod + 1).keys()).slice(1);
                    modified.push(mod_arrays);
                }
                setMod_array(modified);
                setPrintData(res);
                setPrintPopup(true);
                setLoading(false);
            });
        }
        catch (error) {
            setLoading(false);
            toast.error(error.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        }
    };





    const findDateGRN = (e, fromDate, toDate, GRNType) => {
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");

        from_date = format(fDate, "yyyy/MM/dd");
        to_date = format(tDate, "yyyy/MM/dd");
        if (from_date > to_date) {
            toast.warn("From Date should be equal to or less than To Date", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
            return GRNData;
        } else if (from_date < "2010-01-01") {
            toast.warn("From Date should be equal to or greater than 01-01-2010", {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });

            return GRNData;
        }
        getGRN(fromDateRange, toDateRange, GRNType);

        e.preventDefault();
    };

    const getGRN = async (fromDate1, toDate1, GRNType) => {

        setLoading(true);
        // setLoaderStatus(true);
        // if (salesStatus == "Open") {
        //     salesStatus = "open";
        //     setSalesOrderStatus("Open");
        // } else if (salesStatus == "Closed") {
        //     salesStatus = "closed";
        //     setSalesOrderStatus("Closed");
        // } else if (salesStatus == "Cancelled") {
        //     salesStatus = "cancelled";
        //     setSalesOrderStatus("Cancelled");
        // }

        try {
            const GRN_url = `${GetGRNURL}CICode=${auth.CICode}&CompId=${auth.CompId}&fromDate=${fromDate1}&toDate=${toDate1}&userId=${auth.UserId}&GRNType=${GRNType}`;
            const response = await Axios.get(GRN_url);

            setGRNData(response.data);
            setFilterGRNData(response.data);
            setFilterGRNDatas(response.data);
            setLoading(false);

        } catch (error) {
            toast.error(error.response.data.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        }
    };


    const getGRNPageLoad = async () => {
        setLoading(true);
        try {
            const currentDate = format(new Date(), "dd/MM/yyyy");
            const date = subDays(new Date(), 7);
            const sevenDays = format(date, "dd/MM/yyyy");
            const GRN_url = `${GetGRNURL}CICode=${auth.CICode}&CompId=${auth.CompId}&fromDate=${sevenDays}&toDate=${currentDate}&userId=${auth.UserId}&GRNType=All`;
            const response = await Axios.get(GRN_url);
            // response.data = response.data
            //     .map((a) => ({
            //         ...a,
            //         checked: false
            //     }));

            setGRNData(response.data);
            setFilterGRNData(response.data);
            setFilterGRNDatas(response.data);
            setLoading(false);

        } catch (error) {
            toast.error(error.response.data.Message, {
                position: toast.POSITION.TOP_CENTER,
                pauseOnFocusLoss: false,
                closeButton: false,
                autoClose: 2000,
            });
        }
    };

    const handleChange = (e) => {
        const id = e.target.id;
        const value = e.target.value;
        setFilters({ ...filters, [id]: value });
    };
    useEffect(() => {
        let tempSalesOrder = [...GRNData];

        if (filters.GRN_NO) {
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.GRN_NO.toLocaleLowerCase().includes(
                    filters.GRN_NO.toLocaleLowerCase()
                );
            });
        }
        if (filters.SUPP_NAME) {
            //
            tempSalesOrder = tempSalesOrder.filter((orders) => {
                return orders.SUPP_NAME.toLocaleLowerCase().includes(
                    filters.SUPP_NAME.toLocaleLowerCase()
                );
            });
        }
        setFilterGRNData(tempSalesOrder);
        setFilterGRNDatas(tempSalesOrder);

    }, [filters]);

    return (
        <GRNContext.Provider
            value={{
                getGRN,
                getGRNPageLoad,
                findDateGRN,
                setGRNStatus,
                GRNStatus,
                filterGRNData,
                filterGRNDatas,
                handleChange, 
                setFilterGRNData,
                setFilterGRNDatas,
                productListGRN,
                productGRNData,
                netVolume,
                printGRNCall,
                printPopup,
                setPrintPopup,
                loading,
                setLoading,
                saveGRNCall,
                printData,
                mod_array,
                setMod_array
            }}
        >
            {children}
        </GRNContext.Provider>
    );
};
export const useGRNContext = () => {
    return useContext(GRNContext);
};

export { GRNProvider, GRNContext };
