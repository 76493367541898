import React, { useState } from "react";
import * as Fa from "react-icons/fa";
import { EndpointReports, createAPIEndpointReports } from "../../Services/ReportsAll/GrnDataExcelService";
import { format, subDays } from "date-fns";
import storage from "../../Services/Storage";
import XLSX from "xlsx";
import { toast } from "react-toastify";

const GrnDataExcel = () => {

    
    const currentDate = format(new Date(), "yyyy-MM-dd");
    const date = subDays(new Date(), 7);
    const sevenDays = format(date, "yyyy-MM-dd");
    const [fromDate, setFromDate] = useState(sevenDays);
    const [toDate, setToDate] = useState(currentDate);
    const [loadingExcel, setLoadingExcel] = useState(false);

    const handleExportExcel=(fromDate,toDate)=>
    {
        const auth = storage.getLocal("user");
        setLoadingExcel(true);
        const fDate = new Date(fromDate);
        const fromDateRange = format(fDate, "dd/MM/yyyy");
        const tDate = new Date(toDate);
        const toDateRange = format(tDate, "dd/MM/yyyy");
        // let date = new Date();
        // let day = date.getDate();
        // let month = date.getMonth() + 1;
        // let year = date.getFullYear();
        // let dateunique = date.valueOf();
        let uniqueNo = "GRNExcelData";
        createAPIEndpointReports()
            .get(`${EndpointReports.GetGRNExcelData}?strFromDateString=${fromDateRange}&strToDateString=${toDateRange}&CompId=${auth.CompId}`)
            .then((val) => {
                if(val.data.length>0){
                    let wb = XLSX.utils.book_new();
                    let MapData = val.data.map((val) => {
                        let dateString = val["Document Date"];
                        let newData = dateString.replace(/(\d+[/])(\d+[/])/, "$2$1");
                        let data = new Date(newData);
                        let dateString1 = val["Supplier Invoice Date"];
                        let newData1 = dateString1.replace(/(\d+[/])(\d+[/])/, "$2$1");
                        let data1 = new Date(newData1);
                        return { ...val, "Document Date": data,"Supplier Invoice Date":data1 };
                    });
                    let ws = XLSX.utils.json_to_sheet(MapData);
                    XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
                    XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
                }
                else{
                    toast.warning(
                        "No Data Found between these dates",
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                }
                setLoadingExcel(false);

            }).catch((err)=>{
                 
                toast.error(
                    `${err.data}`,
                    {
                        position: toast.POSITION.TOP_CENTER,
                        pauseOnFocusLoss: false,
                        closeButton: false,
                        autoClose: 2000,
                    }
    
                );               
                setLoadingExcel(false);
            });


    };
    

    return (
        <div className="col-12 pt-3">
            <h3 className="py-2">GRN Data to Excel</h3>
            <section className="sales-box">
                <form>
                    <div className="row">
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                            <label className="form-label">From Date</label>
                            <input
                                type="date"
                                className="form-control"
                                defaultValue={sevenDays}
                                max={toDate}
                                min="2010-01-01"
                                name="date-field"
                                onChange={(e) => {
                                    setFromDate(e.target.value);
                                }}
                            />
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-12 mb-3">
                            <label className="form-label">To Date</label>
                            <input
                                type="date"
                                defaultValue={currentDate}
                                max={currentDate}
                                min={fromDate}
                                className="form-control"
                                name="date-field"
                                onChange={(e) => setToDate(e.target.value)}
                            />
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <div className="float-end mt-4">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={()=>{handleExportExcel(fromDate,toDate);}}
                                    disabled={loadingExcel}
                                >
                                    {loadingExcel ? "Loading..." : "Save As Excel"}{" "}
                                    {loadingExcel ? (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <Fa.FaDownload className="ms-2" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    );

    
    
};

export default GrnDataExcel;