import React, { useState } from "react";
import * as Fa from "react-icons/fa";
import { Dropdown } from "primereact/dropdown";
import { EndpointCreditMReports, createAPIEndpointCreditMReports } from "../../../Services/ReportsAll/CreditManagementReports";
import storage from "../../../Services/Storage";
import { toast } from "react-toastify";
import XLSX from "xlsx";


const CustomerOutstandingStatus = () => {
    const options = ["Summary","Details"];
    const [reportType,setReportType] = useState("Summary");
    const [loadingExcel,setLoadingExcel] = useState(false);

    const handleSave = () =>{
        const auth = storage.getLocal("user");
        setLoadingExcel(true);
        let date = new Date();
        let day = date.getDate();
        let months = [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "May",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec",
        ];
        let exactmonth = months[date.getMonth()];
        let exactDay = (day).toString().padStart(2, "0");
        let year = date.getFullYear();
        let uniqueNo = `Cust_Outstanding_Rpt${exactDay}${exactmonth}${year}`;
        createAPIEndpointCreditMReports()
            .get(`${EndpointCreditMReports.GetCustOutExcelData}?strRptType=${reportType=="Summary"?"S":"D"}&CompId=${auth.CompId}`)
            .then((val)=>{
                if(val.data?.length>0){
                    let wb = XLSX.utils.book_new();
                    let ws = XLSX.utils.json_to_sheet(val?.data);
                    XLSX.utils.book_append_sheet(wb, ws, uniqueNo);
                    XLSX.writeFile(wb, `${uniqueNo}.xlsx`);
                }
                else{
                    toast.warning(
                        "No Data Found between these dates",
                        {
                            position: toast.POSITION.TOP_CENTER,
                            pauseOnFocusLoss: false,
                            closeButton: false,
                            autoClose: 2000,
                        });
                }
                setLoadingExcel(false);
            })
            .catch((error) => {
             
                setLoadingExcel(false);
                toast.error("Something Went Wrong. Please Contact Helpdesk.", {
                    position: toast.POSITION.TOP_CENTER,
                    pauseOnFocusLoss: false,
                    closeButton: false,
                    autoClose: 3000,    
                });

                console.log(error);
            });
    };
    return (
        <div className="col-12 pt-3">
            <h3 className="py-2">Customer Outstanding Status Report</h3>
            <section className="sales-box">
                <form>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12">
                            <label className="form-label">Report Type</label>
                            <Dropdown 
                                value={reportType}
                                onChange={(e)=>{setReportType(e.target.value);}}
                                options={options}
                            />
                        </div>
                        <div className="col-lg-8 col-md-6 col-sm-12">
                            <div className="float-end mt-4">
                                <button type="button" disabled={loadingExcel} className="btn btn-primary" onClick={()=>handleSave()}>
                                    {loadingExcel ? "Loading..." : "Save As Excel"} 
                                    {loadingExcel ? (
                                        <span
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    ) : (
                                        <Fa.FaDownload className="ms-2" />
                                    )}
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </section>
        </div>
    );
};

export default CustomerOutstandingStatus;