import React, { useRef } from "react";
// import { useEffect } from "react";
// import { useGRNContext } from "../../context/GRNContext";
import Modal from "react-bootstrap/Modal";
import { MdCurrencyRupee } from "react-icons/md";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useReceiptsContext } from "../../context/ReceiptsContext";
import { useGlobalContext } from "../../context/SalesOrderContext";
import PrintInvoicePopUp from "../../Components/PrintInvoicePopUp";
// import { MdCurrencyRupee} from "react-icons/md";
// printPopupCash,setPrintPopupCash,setPrintPopup
const PrintReceiptCash = () => {
    const { printPopupCash, setPrintPopupCash, printResponseData } = useReceiptsContext();
    const pdfExportComponent = useRef(null);
    const {orientation} = useGlobalContext();

    const printRef = useRef();
    function getUniqueNo() {
        let date = new Date();
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
        // let dateunique = date.valueOf();
        let uniqueNo = `Receipt-${day}-${month}-${year}`;
        return uniqueNo;
    }

     
    const handleClose = () => {
        setPrintPopupCash(false);
    };

    function ConvertNumberToWords(number1) {
        let words = new Array();
        words[0] = "";
        words[1] = "One";
        words[2] = "Two";
        words[3] = "Three";
        words[4] = "Four";
        words[5] = "Five";
        words[6] = "Six";
        words[7] = "Seven";
        words[8] = "Eight";
        words[9] = "Nine";
        words[10] = "Ten";
        words[11] = "Eleven";
        words[12] = "Twelve";
        words[13] = "Thirteen";
        words[14] = "Fourteen";
        words[15] = "Fifteen";
        words[16] = "Sixteen";
        words[17] = "Seventeen";
        words[18] = "Eighteen";
        words[19] = "Nineteen";
        words[20] = "Twenty";
        words[30] = "Thirty";
        words[40] = "Forty";
        words[50] = "Fifty";
        words[60] = "Sixty";
        words[70] = "Seventy";
        words[80] = "Eighty";
        words[90] = "Ninety";
        number1 = number1.toString();
        let atemp = number1.split(".");
        let number = atemp[0].split(",").join("");
        let n_length = number.length;
        let words_string = "";
        if (n_length <= 9) {
            let n_array = new Array(0, 0, 0, 0, 0, 0, 0, 0, 0);
            let received_n_array = new Array();
            for (let i1 = 0; i1 < n_length; i1++) {
                received_n_array[i1] = number.substr(i1, 1);
            }
            for (let i2 = 9 - n_length, j2 = 0; i2 < 9; i2++, j2++) {
                n_array[i2] = received_n_array[j2];
            }
            for (let i3 = 0, j3 = 1; i3 < 9; i3++, j3++) {
                if (i3 == 0 || i3 == 2 || i3 == 4 || i3 == 7) {
                    if (n_array[i3] == 1) {
                        n_array[j3] = 10 + parseInt(n_array[j3]);
                        n_array[i3] = 0;
                    }
                }
            }
            let value = "";
            for (let i4 = 0; i4 < 9; i4++) {
                if (i4 == 0 || i4 == 2 || i4 == 4 || i4 == 7) {
                    value = n_array[i4] * 10;
                } else {
                    value = n_array[i4];
                }
                if (value != 0) {
                    words_string += words[value] + " ";
                }
                if (
                    (i4 == 1 && value != 0) ||
          (i4 == 0 && value != 0 && n_array[i4 + 1] == 0)
                ) {
                    words_string += "Crores ";
                }
                if (
                    (i4 == 3 && value != 0) ||
          (i4 == 2 && value != 0 && n_array[i4 + 1] == 0)
                ) {
                    words_string += "Lakhs ";
                }
                if (
                    (i4 == 5 && value != 0) ||
          (i4 == 4 && value != 0 && n_array[i4 + 1] == 0)
                ) {
                    words_string += "Thousand ";
                }
                if (
                    i4 == 6 &&
          value != 0 &&
          n_array[i4 + 1] != 0 &&
          n_array[i4 + 2] != 0
                ) {
                    words_string += "Hundred and ";
                } else if (i4 == 6 && value != 0) {
                    words_string += "Hundred ";
                }
            }
            words_string = words_string.split("  ").join(" ");
        }
        return words_string + "Only";
    }

    if(orientation!=="portrait-primary"){
        return (
            <Modal
                className="print-popup"
                show={printPopupCash}
                size="lg"
                centered
                onHide={() => handleClose()}
            >
                <Modal.Body>
                    <div id="printablediv">
                        <PDFExport
                            fileName={getUniqueNo()}
                            paperSize="A4"
                            forcePageBreak=".page-break"
                            ref={pdfExportComponent}
                        >
                            <div>
                                <div className="py-5 mt-4">
                                    <div>
                                        <div className="">
                                            <div className="">
                                                <div>
                                                    <div className="parent parent-invoice-logo-type">
                                                        <span className="invoice-type child">
                                                            {printResponseData.objReceiptPrintHeader.Comp}
                                                        </span>
                                                    </div>

                                                    <div className="parent  parent-invoice-terms-one">
                                                        <div className="childOne  invoice-terms">
                                                            <p>
                                                                {
                                                                    printResponseData.objReceiptPrintHeader
                                                                        .Add1
                                                                }
                                ,{" "}
                                                                {
                                                                    printResponseData.objReceiptPrintHeader
                                                                        .Add2
                                                                }
                                ,{" "}
                                                                {
                                                                    printResponseData.objReceiptPrintHeader
                                                                        .Add3
                                                                }
                                ,{" "}
                                                                {
                                                                    printResponseData.objReceiptPrintHeader
                                                                        .Add4
                                                                }
                                ,
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="parent  parent-invoice-terms">
                                                    <div className="childOne  invoice-terms">
                                                        <p>
                                                            <span className="firstPara">Pin Code</span> :{" "}
                                                            {printResponseData.objReceiptPrintHeader.Pin}
                                                        </p>
                                                        <p>
                                                            <span className="firstPara">Phone No.</span> :
                                                            {printResponseData.objReceiptPrintHeader.Phone}
                                                        </p>
                                                        <p>
                                                            <span className="firstPara">Receipt No.</span> :
                                                            {
                                                                printResponseData.objReceiptPrintHeader
                                                                    .ReceiptNo
                                                            }
                                                        </p>
                                                        <p>
                                                            <span className="firstPara">Receipt Date</span> :
                                                            {
                                                                printResponseData.objReceiptPrintHeader
                                                                    .ReceiptDate
                                                            }
                                                        </p>
                                                        <p>
                                                            <span className="firstPara">Receipt Mode</span> :
                                                            {
                                                                printResponseData.objReceiptPrintHeader
                                                                    .ReceiptMode
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                <div
                                                    className="title"
                                                    style={{
                                                        textAlign: "center",
                                                        borderTop: "1px solid #000",
                                                        borderBottom: "1px solid #000",
                                                        padding: "4px 0px",
                                                        margin: "18px",
                                                    }}
                                                >
                                                    <p style={{ fontSize: "10px", marginBottom: "0px" }}>
                            Receipt
                                                    </p>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-sm-12">
                                                    <div className="parent  parent-invoice-terms-6">
                                                        <div className="childOne  invoice-terms">
                                                            <p>
                                                                <span className="firstPara">Received With Thanks From</span>
                                                                <span className="blockSpan" style={{ width: "75%" , letterSpacing: "0.5px"}}>
                                                                    {
                                                                        printResponseData.objReceiptPrintHeader
                                                                            .Cust
                                                                    }
                                                                    {" , "}
                                                                    {
                                                                        printResponseData.objReceiptPrintHeader
                                                                            .BillTown
                                                                    }
                                                                    {" "}
                                                                    {
                                                                        printResponseData.objReceiptPrintHeader
                                                                            .EX1
                                                                    }
                                                                    {" "}
                                                                    {
                                                                        printResponseData.objReceiptPrintHeader
                                                                            .EX2
                                                                    }
                                                                    {" "}{" "}
                                                                    {
                                                                        printResponseData.objReceiptPrintHeader
                                                                            .EX3
                                                                    }
                                                                    {" "}
                                                                </span>
                                                            </p>
                                                            <p>
                                                                <span className="firstPara">A Sum Of Rs.</span>
                                                                <span className="blockSpan" style={{ width: "75%" , letterSpacing: "0.5px"}}>
                                                                    {
                                                                        printResponseData.objReceiptPrintHeader
                                                                            .GrandTotal
                                                                    }{" "}
                                  (
                                                                    {ConvertNumberToWords(
                                                                        printResponseData.objReceiptPrintHeader.GrandTotal.toString()
                                                                    )}
                                  )
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <div className="row my-2">
                                                <div className="col-lg-8 col-md-8 col-sm-12">
                                                    <div className="parent  parent-invoice-terms-6">
                                                        {printResponseData.objReceiptPrintHeaderCHQ
                                                            .ReceiptMode == "Cheque" ? (
                                                                <div className="childOne  invoice-terms">
                                                                    <p>
                                                                        <span className="firstPara">
                                                                            {
                                                                                printResponseData.objReceiptPrintDetHdr
                                                                                    .Dated
                                                                            }
                                                                        </span>
                                                                        <span className="blockSpan">
                                                                            {
                                                                                printResponseData.objReceiptPrintDetHdr
                                                                                    .PO_DATE
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span className="firstPara">
                                                                            {
                                                                                printResponseData.objReceiptPrintDetHdr
                                                                                    .MOP
                                                                            }
                                                                        </span>
                                                                        <span className="blockSpan">
                                                                            {
                                                                                printResponseData.objReceiptPrintDetHdr
                                                                                    .SUPPLIER
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span className="firstPara">
                                                                            {
                                                                                printResponseData.objReceiptPrintDetHdr
                                                                                    .MOP2
                                                                            }
                                                                        </span>
                                                                        <span className="blockSpan">
                                                                            {
                                                                                printResponseData.objReceiptPrintDetHdr
                                                                                    .PO_NO
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span className="firstPara">
                                                                            {
                                                                                printResponseData.objReceiptPrintDetHdr
                                                                                    .MOP3
                                                                            }
                                                                        </span>
                                                                        <span className="blockSpan">
                                                                            {
                                                                                printResponseData.objReceiptPrintDetHdr
                                                                                    .VAL_AFTER_T_CHRGS
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            ) : (
                                                                <div className="childOne  invoice-terms">
                                                                    <p>
                                                                        <span className="firstPara">
                                                                            {
                                                                                printResponseData.objReceiptPrintDetHdr
                                                                                    .Dated
                                                                            }
                                                                        </span>
                                                                        <span className="blockSpan">
                                                                            {
                                                                                printResponseData.objReceiptPrintDetHdr
                                                                                    .PO_DATE
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                    <p>
                                                                        <span className="firstPara">
                                                                            {
                                                                                printResponseData.objReceiptPrintDetHdr
                                                                                    .MOP
                                                                            }
                                                                        </span>
                                                                        <span className="blockSpan">
                                                                            {
                                                                                printResponseData.objReceiptPrintDetHdr
                                                                                    .SUPPLIER
                                                                            }
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            )}
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 col-md-4 col-sm-12">
                                                    <div className="parent  parent-invoice-terms-6"></div>
                                                </div>
                                            </div> */}
                                                <div className="parent parent-invoice-table">
                                                    <table
                                                        className="invoice-table-details"
                                                        style={{ height: "auto", borderBottom: "none" }}
                                                    >
                                                        <thead>
                                                            <tr className="table-row-border">
                                                                <th>Srl. No.</th>
                                                                <th>Document No</th>
                                                                <th>Document Date</th>
                                                                <th className="RightAlignTD">Document Amount</th>
                                                                <th className="RightAlignTD">Received /<br></br>Adjusted Amount</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody style={{ height: "400px" }}>
                                                            <div>
                                                                {
                                                                    <>
                                                                        <tr className="bankDetails">
                                                                            <td>
                                                                                <b>
                                        Drawn on
                                                                                </b>
                                                                            </td>
                                                                            <td>
                                                                                <b>
                                                                                    {
                                                                                        printResponseData
                                                                                            .objReceiptPrintHeader.CashDrawn
                                                                                    }
                                                                                </b>
                                                                            </td>
                                                                            <td>
                                                                                <b>
                                        Amount
                                                                                </b>
                                                                            </td>
                                                                            <td className="RightAlignTD">
                                                                                <b>
                                                                                    {
                                                                                        printResponseData
                                                                                            .objReceiptPrintHeader
                                                                                            .CashAmt
                                                                                    }
                                                                                </b>
                                                                            </td>
                                                                        </tr>
                                                                        <div>
                                    
                                                                            {printResponseData.objReceiptPrintDetails.map((item,index)=>(
                                                                                <tr key={index}>
                                                                                    <td>
                                                                                        {
                                                                                            item.Sr_No
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item
                                                                                                .SUPP_INV_NO
                                                                                        }
                                                                                    </td>
                                                                                    <td>
                                                                                        {
                                                                                            item
                                                                                                .SUPP_INV_DATE
                                                                                        }{" "}
                                                                                    </td>
                                                                                    <td className="RightAlignTD">
                                                                                        {
                                                                                            item
                                                                                                .OTHER_CHARGES
                                                                                        }{" "}
                                                                                    </td>
                                                                                    <td className="RightAlignTD">
                                                                                        {
                                                                                            item.AMOUNT
                                                                                        }
                                                                                    </td>
                                                                                </tr>
                                                                            ))
                                        
                                                                            }
                                                                        </div>
                                                                        <div>
                                                                            {printResponseData
                                                                                .objReceiptPrintHeader.AdvAmt==null?"":<tr>
                                                                                    <td>Advance Amount</td>
                                                                                    <td className="RightAlignTD">{printResponseData
                                                                                        .objReceiptPrintHeader.AdvAmt}</td>
                                                                                </tr>}
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div>
                                                    <div className="row m-auto">
                                                        <div className="column"></div>
                                                        <div className="column">
                                                            <div className="childOne  invoice-terms-one">
                                                                <p style={{ textAlign: "right" }}>
                                                                    <span className="me-2">Total Amount:</span>
                                                                    <MdCurrencyRupee />
                                                                    {
                                                                        printResponseData.objReceiptPrintHeader
                                                                            .GrandTotal
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </PDFExport>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    <button className="btn btn-cancel-red me-3" onClick={handleClose}>
          Close
                    </button>
                    <button
                        className="btn btn-primary"
                        ref={printRef}
                        onClick={() => {
                            if (pdfExportComponent.current) {
                                pdfExportComponent.current.save();
                            }
                        }}
                    >
          Download
                    </button>
                </Modal.Footer>
            </Modal>
        );}else{
        return(<PrintInvoicePopUp show={printPopupCash} hideShow = {setPrintPopupCash} name = "Receipt Print View (cash)"/>);
    }
};

export default PrintReceiptCash;
