import React from "react";
import { useStockAdjustmentContext } from "../../context/StockAdjustmentContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import Loader from "../../Components/Loader";



const DataTableModal = () => {
    const {
        productCodePopup,
        setProductCodePopup,
        populateProductData,
        selectedProductCode,
        setSelectedProductCode,
        productDisc,
        productLotselect, 
        setProductLotselect,
        populateProductLotData, 
        setSelectedLot,
        isloading,
    } = useStockAdjustmentContext();

    
     
    const handleOkProd = () => {
        productDisc();
        setProductCodePopup(false);
        setProductLotselect(false);
    };

    
     
    if(isloading){
        return (
            <Loader />
        );
    }
    else{
        return (
            <div className="col-12 pt-3 pb-3">
                <Dialog
                    header="All Records"
                    visible={productCodePopup}
                    style={{ width: "60vw" }}
                    onHide={() => setProductCodePopup(false)}
                    closable={false}
                    draggable={false} 
                    resizable={false}
                >
                    <div className="col-md-12 col-lg-12 col-sm-12">
                        <div className="DataTable pt-0 pb-4">
                            <DataTable
                                value={productLotselect ? populateProductLotData:populateProductData}
                                dataKey='MASK_PROD_ID'
                                selectionMode='single'
                                selection={selectedProductCode}
                                onSelectionChange={(e) => { productLotselect ? setSelectedLot(e.value) : setSelectedProductCode(e.value); }}
                                paginator
                                showGridlines
                                stripedRows
                                rows={10}
                                filterDisplay="row"
                                rowsPerPageOptions={[10, 25, 50]}
                                scrollHeight="80vh"
                                scrollable
                                globalFilterFields={["PROD_CODE", "DESCRIPTION"]}  emptyMessage="No products found."
                                tableStyle={{ minWidth: "20rem" }}>
                                <Column field={productLotselect?"BATCH_NO":"PROD_CODE"} header={productLotselect?"PRODUCT LOT":"Code"} filterMatchMode="contains" filter filterPlaceholder="Search Here..." style={{ minWidth: "12rem" }} />
                                <Column field={productLotselect?"AVAILABLE_STOCK":"DESCRIPTION"} header={productLotselect?"AVAILABLE STOCK":"Name"} style={{ minWidth: "12rem" }} filterMatchMode="contains" filter filterPlaceholder="Search Here..." />
                            </DataTable>
                        </div>
                    </div>
                    <div className="float-end">
                        <button type="button" className="btn btn-primary me-3" onClick={handleOkProd}>OK</button>
                        <button type="button" className="btn btn-cancel-red" onClick={() => { setProductCodePopup(false);setProductLotselect(false);}}>Close</button>
                    </div>
                </Dialog>
            </div>
        );}
};

export default DataTableModal;