import React, { useContext, useEffect } from "react";
import { useState } from "react";
import {
    createAPIEndpoint,
    Endpoint,
} from "../Services/WareHouse/WarehouseService";
import storage from "../Services/Storage";
// import { toast } from "react-toastify";
// import { useNavigate } from "react-router";
// import Axios from "../api/Axios";
// import storage from "../Services/Storage";
// import format from "date-fns/format";
// import { toast } from "react-toastify";

const WareHouseMasterContext = React.createContext();

const WareHouseMasterProvider = ({ children }) => {
    const [wareHouseDataAll, setwareHouseDataAll] = useState([]);

    const [townpopup, setTownPopup] = useState(false);
    const [townValue, setTownValue] = useState([]);
    const [stateValue, setstateValue] = useState([]);
    const [stateSuggestion, setStateSuggestion] = useState([]);
    const [districtSuggestion, setDistrictSuggestion] = useState([]);
    const [townSuggestion, setTownSuggestion] = useState([]);
    const [stateGEOG_ID, setstateGEOG_ID] = useState("");
    const [districtGEOG_ID, setDistrictGEOG_ID] = useState("");
    const [zipCode, setZipCode] = useState("");
    const [townGeogId, setTownGeogId] = useState("");
    const [districtValue, setDistrictValue] = useState([]);
    const [confirmTown, setConfirmTown] = useState(false);
    const auth = storage.getLocal("user");

    const handleState = async () => {
        let response = [];
        createAPIEndpoint()
            .get(`${Endpoint.PopulateStateData}?CompId=${auth.CompId}`)
            .then((res) => {
                response = res.data;

                setStateSuggestion(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleDistrict = async () => {
        let response = [];
        createAPIEndpoint()
            .get(`${Endpoint.PopulateDistrictData}?strState=${stateGEOG_ID}`)
            .then((res) => {
                response = res.data;
                setDistrictSuggestion(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };
    const handleTown = async () => {
        let response = [];
        createAPIEndpoint()
            .get(`${Endpoint.PopulateTownData}?strDistrict=${districtGEOG_ID}`)
            .then((res) => {
                response = res.data;
                setTownSuggestion(response);
            })
            .catch((error) => {
                console.log(error);
            });
    };

    useEffect(() => {
        handleDistrict();
    }, [stateGEOG_ID]);

    useEffect(() => {
        handleTown();
    }, [districtGEOG_ID]);

    return (
        <WareHouseMasterContext.Provider
            value={{
                setwareHouseDataAll,
                wareHouseDataAll,
                townpopup,
                setTownPopup,
                townValue,
                setTownValue,
                stateValue,
                setstateValue,
                districtValue,
                setDistrictValue,
                handleState,
                handleDistrict,
                stateSuggestion,
                setStateSuggestion,
                districtSuggestion,
                setDistrictSuggestion,
                stateGEOG_ID,
                setstateGEOG_ID,
                townSuggestion,
                setTownSuggestion,
                districtGEOG_ID,
                setDistrictGEOG_ID,
                townGeogId,
                setTownGeogId,
                setZipCode,
                confirmTown,
                setConfirmTown,
                zipCode,
            }}
        >
            {children}
        </WareHouseMasterContext.Provider>
    );
};

export const useWareHouseMasterContext = () => {
    return useContext(WareHouseMasterContext);
};

export { WareHouseMasterProvider, WareHouseMasterContext };
