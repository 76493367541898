import React, { useState } from "react";
// import { useStockAdjustmentContext } from "../../context/StockAdjustmentContext";
import { useReceiptsContext } from "../../context/ReceiptsContext";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
// import Loader from "../../Components/Loader";

const InvoiceesData = () => {
    const {
        invoicePopupShow,
        setInvoicePopupShow,
        allInvoiceData,
        setselectedInvoicesData,
    } = useReceiptsContext();

    const [selectedDataInPopupTable,setSelectedDataInPopupTable] = useState([]);

     
    const handleOkProd = () => {
         
        //  

        let updatedSelectedinvoices = [];
        updatedSelectedinvoices = selectedDataInPopupTable.map((obj) => ({
            ...obj,
            amount: 0,
        }));  
         
        setselectedInvoicesData(updatedSelectedinvoices);
        setInvoicePopupShow(false);
    };
    const handleClickCancel = () => {
        setselectedInvoicesData([]);
        setInvoicePopupShow(false);
    };

    return (
        <div className="col-12 pt-3 pb-3">
            <Dialog
                header="All Records"
                visible={invoicePopupShow}
                style={{ width: "80vw" }}
                onHide={() => setInvoicePopupShow(false)}
                closable={false}
                draggable={false} 
                resizable={false}
            >
                <div className="col-md-12 col-lg-12 col-sm-12">
                    <div className="DataTable pb-4">
                        <DataTable
                            value={allInvoiceData}
                            paginator
                            rows={10}
                            filterDisplay="row"
                            showGridlines
                            stripedRows
                            selectionMode="checkbox"
                            selection={selectedDataInPopupTable}
                            rowsPerPageOptions={[10, 25, 50]}
                            scrollHeight="80vh"
                            showSelectAll={false}
                            onSelectionChange={(e) => {
                                setSelectedDataInPopupTable(e.value);
                            }}
                            emptyMessage="No Invoice found."
                            tableStyle={{ minWidth: "60rem" }}
                        >
                            <Column
                                selectionMode="multiple"
                                headerStyle={{ width: "3rem" }}
                            ></Column>
                            <Column
                                field="DOCUMENT_NO"
                                header="Document Number"
                                style={{ minWidth: "8rem" }}
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Search Here..."
                            ></Column>
                            <Column
                                field="DOCUMENT_DATE"
                                header="Document Date"
                                style={{ minWidth: "8rem" }}
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Search Here..."
                            ></Column>
                            <Column
                                field="PAYABLE_AMOUNT"
                                header="Outstanding Receivable"
                                style={{ minWidth: "8rem" }}
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Search Here..."
                            ></Column>
                            <Column
                                field="PAYTERM_SERIAL_NUMBAR"
                                header="SI. NO."
                                style={{ minWidth: "6rem" }}
                            ></Column>
                            <Column
                                field="DUE_DATE"
                                header="Due Date"
                                style={{ minWidth: "8rem" }}
                                filter
                                filterMatchMode="contains"
                                filterPlaceholder="Search Here..."
                            ></Column>
                            <Column
                                field="EMP_NAME"
                                header="Employee Name"
                                style={{ minWidth: "6rem" }}
                                filterMatchMode="contains"
                                filter
                                filterPlaceholder="Search Here..."
                            ></Column>
                        </DataTable>
                    </div>
                </div>
                <div className="float-end">
                    <button
                        type="button"
                        className="btn btn-primary me-3"
                        onClick={handleOkProd}
                    >
            OK
                    </button>
                    <button
                        type="button"
                        className="btn btn-cancel-red"
                        onClick={handleClickCancel}
                    >
            Close
                    </button>
                </div>
            </Dialog>
        </div>
    );
};

export default InvoiceesData;
