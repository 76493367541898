import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Accordion from "react-bootstrap/Accordion";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Link, useSearchParams } from "react-router-dom";
import storage from "../../Services/Storage";
import Loader from "../../Components/Loader";
import * as Fa from "react-icons/fa";
import {
    createAPIEndpointproduct,
    Endpoint,
} from "../../Services/productmaster/ProductMastService";
// import { toast } from "react-toastify";


const ProductPages = () => {
    // const nav = useNavigate();
    const [viewproduct, setViewproduct] = useState([]);
    const [classification, setClassification] = useState([]);
    const [taxData, setTaxData] = useState([]);
    const [productMasterLoader, setProductMasterLoader] = useState(false);
    const auth = storage.getLocal("user");
    const [searchParams] = useSearchParams();
    let maskproid = searchParams.get("mask_pro_id");

    //API will be called here
    const ViewProductMast = async (maskproid) => {
        setProductMasterLoader(false);
        createAPIEndpointproduct()
            .get(
                `${Endpoint.ViewProductMaster}?CICode=${auth.CICode}&MASK_PROD_ID=${maskproid}`
            )
            .then((response) => {
                setViewproduct(response.data.Data);
                 
                setProductMasterLoader(true);
            })
            .catch(() => {
                setProductMasterLoader(true);
            });
    };

    const fetchClassification = async () => {
    // setProductMasterLoader(false);
        createAPIEndpointproduct()
            .get(`${Endpoint.GetAllClassificationData}?CICode=${auth.CICode}`)
            .then((response) => {
                setClassification(response.data.Data);
                // setProductMasterLoader(true);
                 
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fetchTaxdata = async () => {
    // setProductMasterLoader(false);
        createAPIEndpointproduct()
            .get(`${Endpoint.GetAllTaxData}?CICode=${auth.CICode}`)
            .then((response) => {
                setTaxData(response.data.Data);
                // setProductMasterLoader(true);
                 
            })
            .catch((error) => {
    
                console.log(error);
                // setProductMasterLoader(true);
            });
    };

    useEffect(() => {
        if (maskproid !== "null") {
            ViewProductMast(maskproid);
            fetchClassification();
            fetchTaxdata();
        }
    }, []);

    const initialValues = {
    //Product Definition
        master_brand: "",
        product_brand: "",
        product_brand_variant: "",
        product_type: "",
        product_sub_type: "",
        product_variant: "",
        product_code: "",
        long_description: "",
        short_description: "",
        origin_date: "",
        global_product_code: "",
        expiry_date: "",
        jde_product_code: "",
        excise_chapter_no: "",
        base_pack_code: "",
        base_pack_name: "",
        active: false,
        //Physical Dimension
        gross_weight: "",
        net_weight: "",
        gross_volume: "",
        net_volume: "",
        //UOM
        case: "",
        case_factor: "",
        pack: "",
        net_volume_uom:"",
        base_uom: "",
        //Other Details
        tax_group_buy: "",
        tax_group_sell: "",
        pda_compatible: false,
        //Additional Classification
        product_class_1: "",
        product_class_2: "",
        product_class_3: "",
        product_class_4: "",
        product_class_5: "",
    };

    const formik = useFormik({
        initialValues: initialValues,
    });
    function formatDateToDDMMYYYY(date) {
        let day = date.getDate();
        let month = date.getMonth() + 1;
        let year = date.getFullYear();
    
        day = day < 10 ? "0" + day : day;
        month = month < 10 ? "0" + month : month;
    
        return day + "/" + month + "/" + year;
    }

    if (viewproduct) {
    //Product Definition
        formik.initialValues.master_brand = viewproduct.MasterBrand;
        formik.initialValues.product_brand = viewproduct.ProductBrand;
        formik.initialValues.product_brand_variant =
      viewproduct.ProductBrandVarient;
        formik.initialValues.product_type = viewproduct.ProductType;
        formik.initialValues.product_sub_type = viewproduct.ProductSubType;
        formik.initialValues.product_variant = viewproduct.ProductVarient;
        formik.initialValues.product_code = viewproduct.ProductCode;
        formik.initialValues.long_description = viewproduct.LongDesc;
        formik.initialValues.short_description = viewproduct.ShortDesc;
        formik.initialValues.origin_date = formatDateToDDMMYYYY( new Date(viewproduct.OriginDate));
        formik.initialValues.global_product_code = viewproduct.GProductCode;
        formik.initialValues.expiry_date = viewproduct.ExpiryDate;
        formik.initialValues.jde_product_code = viewproduct.JDEProductCode;
        formik.initialValues.excise_chapter_no = viewproduct.ExciseChapterNo;
        formik.initialValues.base_pack_code = viewproduct.BasePackCode;
        formik.initialValues.base_pack_name = viewproduct.BasePackName;
        formik.initialValues.active = viewproduct.Active === "Y" ? true : false;

        //Physical Dimension
        formik.initialValues.gross_weight = Number(viewproduct.GrossWeight).toFixed(2);
        formik.initialValues.net_weight = Number(viewproduct.NetWeight).toFixed(2);    
        formik.initialValues.gross_volume = Number(viewproduct.GrossVolume).toFixed(2);
        formik.initialValues.net_volume = Number(viewproduct.NetVolume).toFixed(2);

        //UOM
        formik.initialValues.case = viewproduct.Case;
        formik.initialValues.case_factor = viewproduct.CaseFactor;
        formik.initialValues.pack = viewproduct.Pack;
        // formik.initialValues.net_volume = viewproduct.NetVolume_uom;
        formik.initialValues.net_volume_uom=Number(viewproduct.NetVolume_uom).toFixed(2);
        formik.initialValues.base_uom = viewproduct.BaseUom;

        //Other Details

        // let taxGroupB = classification.filter((val)=>val.MASK_TAX_COMM_GR_ID === viewproduct.TaxGroupB);
        // let taxGroupS = classification.filter((val)=>val.MASK_TAX_COMM_GR_ID === viewproduct.TaxGroupS);
    
        // formik.initialValues.tax_group_buy = viewproduct.TaxGroupB;
        // formik.initialValues.tax_group_sell = viewproduct.TaxGroupS;


        formik.initialValues.tax_group_buy = taxData.filter((val)=>val.MASK_TAX_COMM_GR_ID === viewproduct.TaxGroupB)[0]?.DESCRIPTION;
        formik.initialValues.tax_group_sell = taxData.filter((val)=>val.MASK_TAX_COMM_GR_ID === viewproduct.TaxGroupS)[0]?.DESCRIPTION;
        formik.initialValues.pda_compatible = viewproduct.PDACompatible === "Y" ? true : false;

        //Additional Classification 
        // let productclass1 = taxData.filter((val)=>val.abc === viewproduct.ProductClass1);
        // let productclass2 = taxData.filter((val)=>val.abc === viewproduct.ProductClass2);
        // let productclass3 = taxData.filter((val)=>val.abc === viewproduct.ProductClass3);
        // let productclass4 = taxData.filter((val)=>val.abc === viewproduct.ProductClass4);
        // let productclass5 = taxData.filter((val)=>val.abc === viewproduct.ProductClass5);

        // formik.initialValues.product_class_1 = viewproduct.ProductClass1;
        // formik.initialValues.product_class_2 = viewproduct.ProductClass2;
        // formik.initialValues.product_class_3 = viewproduct.ProductClass3;
        // formik.initialValues.product_class_4 = viewproduct.ProductClass4;
        // formik.initialValues.product_class_5 = viewproduct.ProductClass5;

        formik.initialValues.product_class_1 = classification.filter((val)=>val.MASK_PRODUDC_ID === viewproduct.ProductClass1)[0]?.MASK_PRODUDC_ID;
        formik.initialValues.product_class_2 = classification.filter((val)=>val.MASK_PRODUDC_ID === viewproduct.ProductClass2)[0]?.MASK_PRODUDC_ID;
        formik.initialValues.product_class_3 = classification.filter((val)=>val.MASK_PRODUDC_ID === viewproduct.ProductClass3)[0]?.MASK_PRODUDC_ID;
        formik.initialValues.product_class_4 = classification.filter((val)=>val.MASK_PRODUDC_ID === viewproduct.ProductClass4)[0]?.MASK_PRODUDC_ID;
        formik.initialValues.product_class_5 = classification.filter((val)=>val.MASK_PRODUDC_ID === viewproduct.ProductClass5)[0]?.MASK_PRODUDC_ID;

    } else {
        return initialValues;
    }

    // const backProductMaster = () => {
    //     nav("/productmaster");
    // };

    if (!productMasterLoader) {
        return <Loader />;
    } else {
        return (
            <main>
                <div className="col-12 pt-3 mb-5">
                    {/* <div className="float-end">
                        <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={backProductMaster}
                        >
              Back
                        </button>
                    </div> */}
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mx-1">
                                    <li className="breadcrumb-item">
                                        <Link to="/masters/productmaster">Product Master</Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">Details</li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12">
                            <div className="float-end">
                                <Link to="/masters/productmaster">
                                    <button type="submit" className="btn btn-primary">
                                        <Fa.FaLongArrowAltLeft className="backIcon" /> Back to All Records
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <form>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header>Product Definition</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Master Brand</label>
                                                <InputText
                                                    id="master_brand"
                                                    name="master_brand"
                                                    value={formik.values.master_brand}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Product Brand</label>
                                                <InputText
                                                    id="product_brand"
                                                    name="product_brand"
                                                    value={formik.values.product_brand}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">
                          Product Brand Variant
                                                </label>
                                                <InputText
                                                    id="product_brand_variant"
                                                    name="product_brand_variant"
                                                    value={formik.values.product_brand_variant}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Product Type</label>
                                                <InputText
                                                    id="product_type"
                                                    name="product_type"
                                                    value={formik.values.product_type}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Product Sub Type</label>
                                                <InputText
                                                    id="product_sub_type"
                                                    name="product_sub_type"
                                                    value={formik.values.product_sub_type}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Product Variant</label>
                                                <InputText
                                                    id="product_variant"
                                                    name="product_variant"
                                                    value={formik.values.product_variant}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Product Code</label>
                                                <InputText
                                                    id="product_code"
                                                    name="product_code"
                                                    value={formik.values.product_code}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Long Description</label>
                                                <InputText
                                                    id="long_description"
                                                    name="long_description"
                                                    value={formik.values.long_description}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Short Description</label>
                                                <InputText
                                                    id="short_description"
                                                    name="short_description"
                                                    value={formik.values.short_description}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Origin Date</label>
                                                <InputText
                                                    id="origin_date"
                                                    name="origin_date"
                                                    value={formik.values.origin_date}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">
                          Global Product Code
                                                </label>
                                                <InputText
                                                    id="global_product_code"
                                                    name="global_product_code"
                                                    value={formik.values.global_product_code}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Expiry Date</label>
                                                <InputText
                                                    id="expiry_date"
                                                    name="expiry_date"
                                                    value={formik.values.expiry_date}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            {/* <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">JDE Product Code</label>
                                                <InputText
                                                    id="jde_product_code"
                                                    name="jde_product_code"
                                                    value={formik.values.jde_product_code}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div> */}
                                            {/* <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Excise Chapter No</label>
                                                <InputText
                                                    id="excise_chapter_no"
                                                    name="excise_chapter_no"
                                                    value={formik.values.excise_chapter_no}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div> */}
                                            {/* <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Base Pack Code</label>
                                                <InputText
                                                    id="base_pack_code"
                                                    name="base_pack_code"
                                                    value={formik.values.base_pack_code}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div> */}
                                            {/* <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Base Pack Name</label>
                                                <InputText
                                                    id="base_pack_name"
                                                    name="base_pack_name"
                                                    value={formik.values.base_pack_name}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div> */}
                                            <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
                                                <label className="form-label">Active</label>
                                                <label className="d-inline ms-2">
                                                    <InputSwitch
                                                        id="active"
                                                        name="active"
                                                        checked={formik.values.active}
                                                        className="switch-pos"
                                                        disabled
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                    <Accordion.Header>Physical Dimension</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Gross Weight(Kg)</label>
                                                <InputText
                                                    id="gross_weight"
                                                    name="gross_weight"
                                                    value={formik.values.gross_weight}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Net Weight(Kg)</label>
                                                <InputText
                                                    id="net_weight"
                                                    name="net_weight"
                                                    value={formik.values.net_weight}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Gross Volume(Ltr)</label>
                                                <InputText
                                                    id="gross_volume"
                                                    name="gross_volume"
                                                    value={formik.values.gross_volume}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-3 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Net Volume(Ltr)</label>
                                                <InputText
                                                    id="net_volume"
                                                    name="net_volume"
                                                    value={formik.values.net_volume}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                    <Accordion.Header>SKU Pack Details</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Case</label>
                                                <InputText
                                                    id="case"
                                                    name="case"
                                                    value={formik.values.case}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Case Factor</label>
                                                <InputText
                                                    id="case_factor"
                                                    name="case_factor"
                                                    value={formik.values.case_factor}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Pack</label>
                                                <InputText
                                                    id="pack"
                                                    name="pack"
                                                    value={formik.values.pack}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Net Volume(Ltr)</label>
                                                <InputText
                                                    id="net_volume_uom"
                                                    name="net_volume_uom"
                                                    value={formik.values.net_volume_uom}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Base UOM</label>
                                                <InputText
                                                    id="base_uom"
                                                    name="base_uom"
                                                    value={formik.values.base_uom}
                                                    class="form-control"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>
                                {/* <Accordion.Item eventKey="3">
                                    <Accordion.Header>Other Details</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Tax Group(Buying)</label>
                                                <InputText
                                                    id="tax_group"
                                                    name="tax_group"
                                                    value={formik.values.tax_group_buy}
                                                    class="form-select"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Tax Group(Selling)</label>
                                                <InputText
                                                    id="tax_group"
                                                    name="tax_group"
                                                    value={formik.values.tax_group_sell}
                                                    class="form-select"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
                                                <label className="form-label">PDA Compatible</label>
                                                <label className="d-inline ms-2">
                                                    <InputSwitch
                                                        id="pda_compatible"
                                                        name="pda_compatible"
                                                        checked={formik.values.pda_compatible}
                                                        className="switch-pos"
                                                        disabled
                                                    />
                                                </label>
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}
                                {/* <Accordion.Item eventKey="4">
                                    <Accordion.Header>Additional Classification</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="row">
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Product Class 1</label>
                                                <InputText
                                                    id="product_class_1"
                                                    name="product_class_1"
                                                    value={formik.values.product_class_1}
                                                    class="form-select"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Product Class 2</label>
                                                <InputText
                                                    id="product_class_2"
                                                    name="product_class_2"
                                                    value={formik.values.product_class_2}
                                                    class="form-select"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Product Class 3</label>
                                                <InputText
                                                    id="product_class_3"
                                                    name="product_class_3"
                                                    value={formik.values.product_class_3}
                                                    class="form-select"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Product Class 4</label>
                                                <InputText
                                                    id="product_class_4"
                                                    name="product_class_4"
                                                    value={formik.values.product_class_4}
                                                    class="form-select"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                            <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                                                <label className="form-label">Product Class 5</label>
                                                <InputText
                                                    id="product_class_5"
                                                    name="product_class_5"
                                                    value={formik.values.product_class_5}
                                                    class="form-select"
                                                    placeholder=""
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item> */}
                            </Accordion>
                        </form>
                    </div>
                </div>
            </main>
        );
    }
};

export default ProductPages;
